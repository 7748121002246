import { Inject, Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { ManageStorageService } from "./manage-storage.service";
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from "src/app/auth-config";
import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public isAuthenticated: boolean;
  public isUserProfileLoaded: boolean;
  public currentUserEmail: string = "";
  clearTimeout: any;



  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    private localStorage: ManageStorageService,
    private constants: ConstantsService
  ) {
    this.isAuthenticated = false;
    this.isUserProfileLoaded = false;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    //this.localStorage.setStorage(this.constants.isAuth, "true");
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }

  }

  logout() {
    this.localStorage.setStorage(this.constants.isAuth, "false");
    this.RemoveFromLocalStorage();
    this.authService.logout();
  }

  autoLogout(expirationDate: number) {
    this.clearTimeout = setTimeout(() => {
      this.logout();
    }, (expirationDate / 60));
  }


  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }


  getClaims(claims: any): string {
    if (claims) {
      this.currentUserEmail = claims ? claims['emails'][0] : null;
      this.localStorage.setStorage(this.constants.userEmail, this.currentUserEmail);
    }
    return this.currentUserEmail;
  }

  RemoveFromLocalStorage(): any {
    localStorage.clear();
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"]
      //,authority: b2cPolicies.authorities.editProfile.authority,
    };

    this.login(editProfileFlowRequest);
  }
}