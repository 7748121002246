import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { News } from 'src/app/models/news';
import { UserRequest,VersapayUserRequest } from 'src/app/models/user-request';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';

import { VersapayUser } from 'src/app/models/user';

@Component({
  selector: 'app-versapay-users',
  templateUrl: './versapay-users.component.html',
  styleUrls: ['./versapay-users.component.scss']
})
export class VersapayUsersComponent implements OnInit {


  displayedColumns: string[] = ['email', 'role', 'companyName', 'Action'];
  private dsData: any;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource;
  newsList !: News[];

  get allVersapayUsers(): VersapayUserRequest[] {
    return this.usersService.allVersapayUsers;
  }
  set allVersapayUsers(newsList: VersapayUserRequest[]) {
    this.usersService.allVersapayUsers = newsList;
  }

  get impersonatedUser(): VersapayUser {
    return this.usersService.impersonatedUser;
  }
  set impersonatedUser(user: VersapayUser) {
    this.usersService.impersonatedUser = user;
  }

  constructor(private route: Router,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private usersService: UsersService,
  ) {
    this.usersService.allVersapayUsersSubject.subscribe((data: any) => {
      this.allVersapayUsers = data;
      this.LoadData();
    });
  }

  ngOnInit() {
    this.LoadData();
  }

  LoadData() {
    if (this.usersService.allVersapayUsers) {
      //get get All users
      this.dataSource.data = this.usersService.allVersapayUsers;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else {
      //get All users with service call
      this.getAllVersapayUsers();
    }
  }

  getAllVersapayUsers(): void {
    this.usersService.getVersapayUsersByAdminEmail()
      .subscribe(data => {
        this.allVersapayUsers = data;
        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  deleteUser(id: number) {
    this.usersService.deleteUser(id)
      .subscribe(data => {

        // Refresh DataTable to remove row.
        this.deleteRowDataTable(id);

        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = "Article de nouvelles supprimé avec succès!!";
        }else{
          this.statusMessage = "News Deleted Successfully!!";
        }

        this.isError = false;
        this.showMessage = true;
        this.FadeOutMessage();

      },
        (err) => {
          this.statusMessage = err;
          this.isError = true;
          this.showMessage = true;
          this.FadeOutMessage();

          console.log(err.error);
          console.log(err.message);
        });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private deleteRowDataTable(recordId: number) {
    this.dsData = this.dataSource.data;
    const itemIndex = this.dsData.findIndex((obj: any) => obj["id"] === recordId);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;

    //update news list
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  navigateToNewsArticle() {
    this.route.navigate([this.constants.addNewsUrl])
  }

  previewNews(newsId: string) {
    this.route.navigate([this.constants.previewsUrl, newsId])
  }

  editNews(newsId: string) {
    this.route.navigate([this.constants.editNewsUrl, newsId])
  }

  clickImpersonateUser(userEmail: any) {
    console.log(userEmail);
    this.localStorage.setStorage(this.constants.isImpersonate, true);
    this.localStorage.setStorage(this.constants.impersonateAs, userEmail);

    //change color and text
    this.usersService.changeIsImpersonated(true);

    this.getImpersanatedUser(userEmail);
    this.route.navigate([this.constants.dashboardUrl]);
  }

  getImpersanatedUser(email: any): void {
    this.usersService.getImpersonatedUser(email)
      .subscribe((data: VersapayUser) => {
        this.usersService.impersonatedUser = data;
        this.impersonatedUser = data;
        this.localStorage.setStorage(this.constants.impersonateUserOrgId, data.prismCompanyId);
      });
  }
}
