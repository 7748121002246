import { PropertySuite } from './../../../../models/propertySuite';
import { IssueType, IssueTypeCollection } from './../../../../models/issueType';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyDialogComponent } from 'src/app/components/shared/property-dialog/property-dialog.component';
import { ServiceRequest } from 'src/app/models/service-request';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { Building } from 'src/app/models/building';

export interface Comments {
  timestamp: string;
  comments: string;
  technician: string;
  status: string;
}

@Component({
  selector: 'app-new-service-request',
  templateUrl: './new-service-request.component.html',
  styleUrls: ['./new-service-request.component.scss']
})

export class NewServiceRequestComponent implements OnInit {

  isUpdate: boolean = false;
  propertyText: string = "";
  submitBtnText: string = "";
  public defaultBuilding = "";
  public defaultBuildingName = "";
  issueTypes: IssueType[] = [];
  email: string = "";
  phone: string = "";
  propertySuite: PropertySuite[] = [];
  public selectedProperty: any;
  public selectedIssueTypes: IssueTypeCollection[] = [];
  requestId: string = "";
  identifier: string = "";
  requestDescrip: string = "";
  requestStatus: string = "";
  requestTS: string = "";
  issueTypeColl: IssueTypeCollection[] = []

  action_history: any[] = [];
  attachments !: any[];
  currentServiceRequest !: ServiceRequest;
  showPropertyBtn: boolean = false;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  disableSelect: boolean = false;
  disableSubmit: boolean = true;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }
  set allServiceRequests(serviceRequests: any) {
    this.buildingEngineService.serviceRequests = serviceRequests;
  }

  constructor(private route: Router,
    private aRoute: ActivatedRoute,
    private buildingEngineService: BuildingEngineService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    public dialog: MatDialog,
    private usersService: UsersService, private fb: FormBuilder
  ) {
    this.propertyText = this.constants.propertyText_Select;
    this.submitBtnText = this.constants.propertyText_Select;

    this.buildingEngineService.building.subscribe((data: Building) => {
      this.getProperties();
      this.getIssuetypes();
    });

  }

  serviceRequestForm: FormGroup = new FormGroup({
    RequestId: new FormControl(null),
    Building: new FormControl({ value: '', disabled: true }, Validators.required),
    CompanyName: new FormControl({ value: '', disabled: true }),
    Floor: new FormControl({ value: '', disabled: true }),
    Suite: new FormControl({ value: '', disabled: true }),
    Phone: new FormControl({ value: '', disabled: true }),
    IssueType: new FormControl('', Validators.required),
    KeepMeInformed: new FormControl(false),
    Details: new FormControl('', [Validators.required])
    // Details: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)])
  })

  ngOnInit(): void {
    this.email = this.localStorage.getStorage(this.constants.userEmail) || '{}';
    if (this.usersService.loggedInUser.email) {
      this.phone = this.usersService.loggedInUser.phone || '{}';
    }
    else {
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        this.getLoggedInUser(this.email);
      }
    }

    this.getBuildingID();
    this.getIssuetypes();

    this.aRoute.paramMap.subscribe(param => {
      const requestId = param.get('id');
      if (requestId) {
        this.propertyText = this.constants.propertyText_Update;
        this.submitBtnText = this.constants.submitBtnText_Update;
        this.isUpdate = true;
        this.getServiceRequestById(requestId);
      }
      else {
        this.propertyText = this.constants.propertyText_Select;
        this.submitBtnText = this.constants.submitBtnText_Select;
        this.isUpdate = false;
        this.getProperties();
      }
    });
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.phone = this.usersService.loggedInUser.phone || '{}';
      });
  }

  getBuildingID() {
    // get default building
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      var result = JSON.parse(building || '{}');
      this.defaultBuilding = result.id;
      this.defaultBuildingName = result.name;
    }
  }

  getIssuetypes() {
    this.buildingEngineService.getIssueTypes(this.defaultBuilding).subscribe(
      data => {
        this.issueTypes = data;

        for (var i in data) {
          for (var j in data[i].children) {
            this.issueTypeColl.push({
              id: data[i].children[j].id,
              name: data[i].children[j].name
            })
          }
        }
      },
      (err: any) => console.log(err)
    );
  }

  getProperties() {
    this.getBuildingID();
    this.buildingEngineService.getBuildingByUser().subscribe(
      data => {
        const result = Object.entries(data);
        this.propertySuite.length = 0;

        var properties = result[3][1]

        var property = properties.filter((building: any) => building.id == this.defaultBuilding)[0];

        for (var i in property.spaces) {
          for (var j in property.spaces[i].suites) {
            this.propertySuite.push({
              buildingName: property.name,
              floorId: property.spaces[i].id,
              floorName: property.spaces[i].name,
              suiteId: property.spaces[i].suites[j].id,
              suiteName: property.spaces[i].suites[j].name,
              company: property.spaces[i].suites[j].tenant.name
            })
          }
        }
        // if the user has only 1building, 1flr, 1 suite do not show the set property button
        if (this.propertySuite.length > 1) {
          this.showPropertyBtn = true;
          this.serviceRequestForm.reset();
        }
        else {
          this.disableSubmit = false;
          this.showPropertyBtn = false;
          this.selectedProperty = this.propertySuite[0];
          this.serviceRequestForm.patchValue({
            Building: this.defaultBuildingName,
            Floor: this.propertySuite[0]['floorName'],
            Suite: this.propertySuite[0]['suiteName'],
            CompanyName: this.propertySuite[0]['company'],
            Phone: this.phone
          });
        }

      },
      (err: any) => console.log(err)
    );
  }

  getServiceRequestById(requestId: string) {
    this.buildingEngineService.getServiceRequestByID(requestId).subscribe(
      (serviceRequest: ServiceRequest) => {
        const result = Object.entries(serviceRequest);
        let serviceRequests = result[3][1][0];
        this.currentServiceRequest = serviceRequests;
        console.log(serviceRequests);
        this.editServiceRequest(serviceRequests);
      },
      (err: any) => console.log(err)
    );
  }

  editServiceRequest(serviceRequest: any) {
    if (serviceRequest) {
      this.requestId = serviceRequest.id
      this.identifier = serviceRequest.identifier
      this.requestStatus = serviceRequest.status
      this.requestDescrip = serviceRequest.description
      this.requestTS = serviceRequest.daysOld
      this.action_history = serviceRequest.action_history
      this.disableSelect = true;

      this.serviceRequestForm.patchValue({
        Building: this.defaultBuildingName,
        CompanyName: serviceRequest.organization.name,
        Suite: serviceRequest['space'].name,
        Floor: serviceRequest.floor,
        Phone: this.usersService.loggedInUser.phone,
        IssueType: serviceRequest['issue_type'].id,
        Details: serviceRequest.description
      });

      this.serviceRequestForm = this.fb.group({
        Details: [{ value: serviceRequest.description, disabled: this.isUpdate==true? true : false }]
      });
    }
  }

  CancelButtonClick() {
    this.route.navigate([this.constants.serviceRequestUrl]);
  }

  insertServiceRequest(serviceRequest: any) {
    let requetUser: string = "";
    this.email = this.localStorage.getStorage(this.constants.userEmail) || '{}';
    if (this.usersService.loggedInUser.email) {
      requetUser = this.usersService.loggedInUser.prismUserId!;
    }
    else {
      this.getLoggedInUser(this.email);
    }


    let suitId = this.selectedProperty.suiteId
    let issueTypeId = this.selectedIssueTypes[0]['id']
    let requestDetails = serviceRequest.Details;
    let buildingId = this.defaultBuilding;
    let body = {
      "building_id": buildingId ,
      "issue_type_id":issueTypeId,
      "space_id": suitId,
      "description":requestDetails,
      "location_details": "",
      "requester_id": requetUser ,
      "default_assignment": "true"
  };

    console.log(body)

    this.buildingEngineService.createServiceRequests(body).subscribe(
      res => {
        if (res) {
          //update news list with new created news article
          if (this.buildingEngineService.serviceRequests) {
            //this.buildingEngineService.serviceRequests.push(res);
            this.getServiceRequests();

          }
          if(this.localStorage.getStorage('userLang')=='fr'){
            this.statusMessage = "Demande de service créée avec succès!!";
          }else{
            this.statusMessage = "Service Request Created Successfully!!";
          }
          this.isError = false;
          this.showMessage = true;
          this.FadeOutMessage();

          this.RegirectUserToEditPage(res.id);
        }
      },
      (error: HttpErrorResponse) => {
        this.statusMessage = error.statusText;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    )
  }

  getServiceRequests(): void {
    this.buildingEngineService.getServiceRequests(this.defaultBuilding)
      .subscribe(data => {
        const result = Object.entries(data);
        this.allServiceRequests = result[3][1];;
      });
  }


  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  RegirectUserToEditPage(requestId: string) {
    // redirect the user to edit page
    setTimeout(() => {
      // redirect the user to edit page
      this.disableSubmit = true;
      this.route.navigate([this.constants.editServiceRequestUrl, requestId])
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  updateServiceRequest(serviceRequest: ServiceRequest) {
  }

  onSubmit() {
    if (this.serviceRequestForm.valid) {

      this.disableSubmit = true;

      if (this.isUpdate) {
        this.updateServiceRequest(this.serviceRequestForm.value);
      }
      else {
        this.insertServiceRequest(this.serviceRequestForm.value);
      }
    }
  }

  changeClient(value: string) {
    this.selectedIssueTypes = this.issueTypeColl.filter(a => a.id == value)
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      propertySuite: this.propertySuite,
      defaultBuildingName: this.defaultBuildingName,
      selectedProperty: this.selectedProperty
    };

    const dialogRef = this.dialog.open(PropertyDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.selectedProperty = result[0]
      if(this.selectedProperty){
        this.disableSubmit = false;
      }
      else{
        this.disableSubmit = true;
      }

      this.serviceRequestForm.patchValue({
        Building: this.defaultBuildingName,
        Floor: result[0]['floorName'],
        Suite: result[0]['suiteName'],
        CompanyName: result[0]['company'],
        Phone: this.phone
      });
    });
  }
}
