import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Building } from 'src/app/models/building';
import { News } from 'src/app/models/news';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss']
})
export class HomeNewsComponent implements OnInit {
  newsItems !: News[];
  star: string = 'favourite-news';
  blobImagePath: string = environment.blobImagePath;

  isAdmin: boolean = false;
  AdminView:boolean = false;

  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  constructor(private route: Router,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private newsService: NewsService,
    private usersService: UsersService,
    private buildingEngineService: BuildingEngineService,) {
    this.buildingEngineService.building.subscribe((data: Building) => {
      this.getAllTenantNews();
    });
  }

  ngOnInit(): void {
    let userRoles:any = this.usersService.loggedInUser.roles;

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        this.rework(this.usersService.loggedInUser);
        this.loadNews();
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);
        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }
  }

  loadNews(){
    if(this.isAdmin){
      this.LoadData();
    }else if(this.hasPrismAccess){
      this.LoadTenantData();
    }else{
      this.getAllNationalAndRegionalNews();
    }
  }

  LoadData() {

    if (this.newsService.allNews) {
      //get get All News
      this.newsItems = this.newsService.allNews.slice(0, 7);

    }
    else {
      //get All News with service call
      this.getAllNews();
    }
  }

  LoadTenantData() {
    if (this.newsService.allNews) {
      //get get All News
      this.newsItems = this.newsService.allNews.slice(0, 7);
    }
    else {
      //get All News with service call
      this.getAllTenantNews();
    }
  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.rework(data);
        this.loadNews();
      });
  }

  getAllNews(): void {
    this.newsService.getAllNews()
      .subscribe(data => {
        //get news from item 1 to 7
        this.newsItems = data.slice(0, 7);
        this.newsService.allNews = data;
      });
  }

  getAllNationalAndRegionalNews():void{
    this.newsService.getAllNationalAndRegionalNews()
      .subscribe(data => {
        //get news from item 1 to 7
        this.newsItems = data.slice(0, 7);
        this.newsService.allNews = data;
      });
  }

  getAllTenantNews(): void {
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      this.newsService.GetAllTenantNewsByTenatuserBEBuldingId()
        .subscribe(data => {
          this.newsItems = data.slice(0, 7);
          this.newsService.allNews = data;
        });
    } else {
      this.getAllNationalAndRegionalNews();
    }
  }

  navigateToNews() {
    if (this.isAdmin) {
      this.route.navigate([this.constants.newsUrl])
    }
    else {
      this.route.navigate([this.constants.tenantnewsUrl])
    }
  }

  navigateToNewsArticlePage(id: number) {
    this.route.navigate([this.constants.previewsUrl, id])
  }
}
