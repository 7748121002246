import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Building } from 'src/app/models/building';
import { News } from 'src/app/models/news';
import { Status } from 'src/app/models/status';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-news-archive',
  templateUrl: './news-archive.component.html',
  styleUrls: ['./news-archive.component.scss']
})
export class NewsArchiveComponent implements OnInit {
  isAdmin: boolean = false;
  AdminView:boolean = false;
  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  displayedColumns: string[] = ['title'];

  blobImagePath: string = environment.blobImagePath;
  star: string = 'favourite-news';
  dataSource = new MatTableDataSource;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private route: Router,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private newsService: NewsService,
    private buildingEngineService: BuildingEngineService,
    private usersService: UsersService,
  ) {
    this.buildingEngineService.building.subscribe((data: Building) => {
      this.getAllNews();
    });
  }

  ngOnInit(): void {
   let userRoles:any = this.usersService.loggedInUser.roles;

   if(userRoles){
     if(userRoles.length>0){
       this.loggedInUser = this.usersService.loggedInUser;
       this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
       this.rework(this.usersService.loggedInUser);
       this.LoadData();
     }else{
       if (this.localStorage.getStorage(this.constants.userEmail)) {
         let email = this.localStorage.getStorage(this.constants.userEmail);
         this.getLoggedInUser(email);
       }
     }
   }else{
     if (this.localStorage.getStorage(this.constants.userEmail)) {
       let email = this.localStorage.getStorage(this.constants.userEmail);
       this.getLoggedInUser(email);
     }
   }
  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.rework(data);
        this.LoadData();
      });

  }

  LoadData() {
    if(this.hasVersapayAccess && !this.hasPrismAccess){
      this.getAllHomeNews();
    }else{
      if (this.newsService.allNews) {
        //get get All News
        //this.newsItems = this.newsService.allNews.filter(a=> a.status != Status.delete);
        this.dataSource.data = this.newsService.allNews.filter(a => a.status != Status.delete);
        this.dataSource.paginator = this.paginator;
      }else if(this.newsService.allNews==undefined){
        this.getAllNationalAndRegionalNews();
      }
      else {
        //get All News with service call
        this.getAllNews();
      }
    }
  }

  getAllHomeNews(): void {
    let date = new Date(Date.now()).getTime();
    const datepipe: DatePipe = new DatePipe('en-US')
    let currentDate = datepipe.transform(date, 'dd-MMM-YYYY HH:mm:ss');

    this.newsService.getAllNationalAndRegionalNews()
      .pipe(
        map(data => data.filter(p => (p.status == Status.published)))
      )
      .subscribe(data => {
        this.dataSource.data = data.filter(a => a.status != Status.delete);
        this.dataSource.paginator = this.paginator;
      });
  }

  getAllNationalAndRegionalNews():void{
    this.newsService.getAllNationalAndRegionalNews()
      .subscribe(data => {
        //get news from item 1 to 7
        this.dataSource.data = data.filter(a => a.status != Status.delete);
        this.dataSource.paginator = this.paginator;
      });
  }

  getAllNews(): void {
    let newsService = this.isAdmin ? this.newsService.getAllNews() : this.newsService.GetAllTenantNewsByTenatuserBEBuldingId()
    newsService
      .pipe(
        map(data => data.filter(p => (p.status == Status.published)))
      )
      .subscribe(data => {
        this.dataSource.data = data.filter(a => a.status != Status.delete);
        this.dataSource.paginator = this.paginator;
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  navigateToNewsArticlePage(id: number) {
    this.route.navigate([this.constants.tenantNewsPreviewsUrl, id])
  }

}
