<!-- Building Selector -->
<div class="container">
  <div class="row justify-content-end">
    <div class="col-md-7 col-lg-5 text-right">
      <button mat-raised-button class="btn-white" (click)="getBuildings()">
        {{selectedBuilding}}
        <mat-icon class="material-icons-outlined dashboard-icons expand-icon"> expand_more </mat-icon>
      </button>
    </div>
  </div>
</div>
<!-- Building Selector -->
