import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { VersapayService } from 'src/app/services/versapay.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { ServiceRequestStatus } from 'src/app/models/service-request-status';
import { UsersService } from 'src/app/services/users.service';
import { vCompany } from 'src/app/models/company';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CompanyDialogComponent } from 'src/app/components/shared/company-dialog/company-dialog.component';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { Subscription} from "rxjs";
@Component({
  selector: 'app-home-open-invoices',
  templateUrl: './home-open-invoices.component.html',
  styleUrls: ['./home-open-invoices.component.scss']
})
export class HomeOpenInvoicesComponent implements OnInit, OnDestroy {

  openInvoiceStatus!: ServiceRequestStatus;
  defaultCompany!: any;
  companyData:boolean = false;
  loading: boolean = false;
  selectedCompanyName:string ="";
  companyChangeSubscription$: Subscription;

  get allSummaryInvoiceRequests(): any {
    return this.versapayService.allSummaryInvoiceRequests;
  }
  set allSummaryInvoiceRequests(openInvoiceRequests: any) {
    this.versapayService.allSummaryInvoiceRequests = openInvoiceRequests;
  }

  //companies
  get companies(): any {
    return this.versapayService.properties;
  }
  set companies(properties: any) {
    this.versapayService.properties = properties;
  }

  //default company
  get defaultCompanies(): vCompany {
    return this.versapayService.defaultCompany;
  }
  set defaultCompanies(company: vCompany) {
    this.versapayService.defaultCompany = company;
  }

  //Check is Admin
  get isAdmin(): any {
    return this.usersService.isAdminUser;
  }
  set isAdmin(isAdmin: boolean) {
    this.usersService.isAdminUser = isAdmin;
  }
  constructor(private route: Router,
    private versapayService: VersapayService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    public dialog: MatDialog,
    private usersService: UsersService
  ) {

    this.openInvoiceStatus = { newCount: 0, inProgressCount: 0, completedCount: 0 };
    //subscribe to default building
    this.companyChangeSubscription$ = this.versapayService.vcompany
      .subscribe((data: vCompany) => {
      this.defaultCompany = data.name;
      let userEmail:any = "";
      let ImpEmail = this.localStorage.getStorage(this.constants.impersonateAs);
      if(ImpEmail){
        userEmail= ImpEmail;
      }else{
         userEmail = this.localStorage.getStorage(this.constants.userEmail);
      }
      this.getOpenInvoiceRequests(userEmail);
    })
  }

  ngOnInit(): void {

    let userEmail:any = "";
    let ImpEmail = this.localStorage.getStorage(this.constants.impersonateAs);
    if(ImpEmail){
      userEmail= ImpEmail;
    }else{
       userEmail = this.localStorage.getStorage(this.constants.userEmail);
    }

    let company:any = this.localStorage.getStorage(this.constants.defaultCompany);
    if(company){
      this.LoadData(userEmail);
    }else{
      this.getCompanies(userEmail);
    }
  }


  getCompanies(email:any){

    this.versapayService.getCompanyByUser(email)
      .pipe(first())
      .subscribe((data)=>{

        //const result = Object.entries(data);
        const result:any = data;
        this.loading = false;
        this.companies = result;
        if(result.length>1){
          const lg = result.length;

          this.openVDialog(email);
          this.localStorage.setStorage(this.constants.companyCnt, this.companies.length);
        }else{
          const tg = result.length;

          this.selectedCompanyName = result[0].name;
          this.localStorage.setStorage(this.constants.companyCnt, 1);
          //set default building
          this.defaultCompanies = this.companies[0];
          this.versapayService.changeCompany(this.companies[0]);
          this.localStorage.setStorage(this.constants.defaultCompany, JSON.stringify(this.companies[0]));

          //get service requests for the default building
          this.getSummaryInvoices(email);

        }
    });
  }

  openVDialog(email:any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      companies: this.companies
    };

    const dialogRef = this.dialog.open(CompanyDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.defaultCompanies = result[0];
      this.versapayService.changeCompany(result[0]);
      //set default building
      this.localStorage.setStorage(this.constants.defaultCompany, JSON.stringify(result[0]));

      //get invoices for the default company
      this.getSummaryInvoices(email);
    });
  }

  getSummaryInvoices(email:any): void {
    this.versapayService.getSummaryInvoices(this.defaultCompanies.externalID!)
      .pipe(first())
      .subscribe(data => {
        //const result = Object.entries(data);
        let result:any = data;
        if(result){
          this.allSummaryInvoiceRequests = result;
          this.versapayService.changeOpenInvoiceRequests(result);
        }

       this.LoadData(email);
      });
  }

  LoadData(email:any) {
    if (this.versapayService.summaryInvoices) {
      this.companyData = true;
      //get service requests
      this.allSummaryInvoiceRequests = this.versapayService.summaryInvoices;
    }
    else {
      this.getOpenInvoiceRequests(email);
    }
  }

  getOpenInvoiceRequests(email:any): void {
    let defaultCompany = "";
    let company = this.localStorage.getStorage(this.constants.defaultCompany);

    if (company) {

      var result = JSON.parse(company || '{}');
      this.selectedCompanyName = result.name;
      defaultCompany = result.externalID;
      if (this.defaultCompany == null) {
        this.defaultCompany = result.name;
      }

      this.versapayService.getSummaryInvoices(defaultCompany)
        .pipe(first())
        .subscribe(data => {
          const result:any = data;
          if (result) {
            this.companyData = true;
            this.allSummaryInvoiceRequests = result;
          }
        });
    }else{
      this.allSummaryInvoiceRequests = {};
    }
  }

  makePayment(){
    var paymentLink = environment.paymentUrl + "&RelayState=https";
    window.open(paymentLink);
  }

  ngOnDestroy(): void {
    this.companyChangeSubscription$.unsubscribe();
  }
}
