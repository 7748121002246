import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { loginRequest } from 'src/app/auth-config';
import { Building } from 'src/app/models/building';
import { User } from 'src/app/models/user';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { AuthService } from 'src/app/services/rc-auth.service';
import { UsersService } from 'src/app/services/users.service';
import { BuildingDialogComponent } from '../../building-dialog/building-dialog.component';
import { TranslocoService } from '@ngneat/transloco';
import {VersapayService} from "src/app/services/versapay.service";
import { Company, vCompany } from 'src/app/models/company';
import { CompanyDialogComponent } from '../../company-dialog/company-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;
  selectedBtnColorEn:string = "basic";
  selectedBtnColorFr:string = "basic";
  disableden: boolean = true;
  disabledfr: boolean = true;
  //this is to close the side nav in
  loading: boolean = false;
  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;
  //user
  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }
  set loggedInUserEmail(email: string) {
    this.buildingEngineService.p_email = email;
  }

  //buildings
  get buildings(): any {
    return this.buildingEngineService.properties;
  }
  set buildings(properties: any) {
    this.buildingEngineService.properties = properties;
  }

  //default building
  get defaultBuilding(): Building {
    return this.buildingEngineService.defaultBuilding;
  }
  set defaultBuilding(building: Building) {
    this.buildingEngineService.defaultBuilding = building;
  }
  set allServiceRequests(serviceRequests: any) {
    this.buildingEngineService.serviceRequests = serviceRequests;
  }

  //Check is Admin
  get isAdmin(): any {
    return this.usersService.isAdminUser;
  }
  set isAdmin(isAdmin: boolean) {
    this.usersService.isAdminUser = isAdmin;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    private route: Router,
    private msalBroadcastService: MsalBroadcastService,
    private rcAuthService: AuthService,
    private usersService: UsersService,
    private localStorage: ManageStorageService,
    private constants: ConstantsService,
    private buildingEngineService: BuildingEngineService,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    private langService: TranslocoService,
  ) {
    let user = this.authService.instance.getActiveAccount();
    (this.localStorage.getStorage(this.constants.isAuth) == "false" || this.localStorage.getStorage(this.constants.isAuth) == null) ? this.rcAuthService.isAuthenticated = false : this.rcAuthService.isAuthenticated = true;
    if (user) {
      this.setLoginDisplay();
    }
    else {
      this.rcAuthService.login(loginRequest);
    }
  }

  ngOnDestroy(): void {
    localStorage.clear();
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.rcAuthService.logout();
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    // get user is authenticated or not
    //var userLocalLang = this.localStorage.getStorage("userLang");
    this.onResize(window.innerWidth);

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.rcAuthService.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;

          var currDate = new Date();
          var expDate = new Date(payload.expiresOn!);
          var Difference_In_Time = expDate.getTime() - currDate.getTime();
          if (payload) {
            this.localStorage.setStorage(this.constants.isAuth, "true");
          }
          //console.log(payload);
          this.authService.instance.setActiveAccount(payload.account);
          let loggedInUserEmail = this.rcAuthService.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);

          this.loggedInUserEmail = loggedInUserEmail;
          // if Tenant login to Building engine
          this.usersService.getLoggedInUser(loggedInUserEmail).subscribe(
            (data) => {
              // if loggedin uer is inactive user send user to the login page
              if(!data.isActive){
                this.rcAuthService.logout();
              }
              else{
                this.loggedInUser = data;
                this.isAdmin = this.usersService.isAdmin(data);
                this.userRole = data.roles;

                const prismAccess = this.userRole.some(function(roles:any){
                  return roles.roleName==='tenant'
                })
                const versapayAccess = this.userRole.some(function(roles:any){
                  return roles.roleName==='versapay'
                })
                if(prismAccess){
                  this.hasPrismAccess = true;
                }
                if(versapayAccess){
                  this.hasVersapayAccess = true;
                }

                var lang = this.usersService.getDefaultLang(data);
                if(lang==null || lang==''){
                  lang='en';
                  this.usersService.putUserDefaultLang(this.loggedInUser,lang).subscribe(
                    res=>{
                      if(res){
                        this.langService.setActiveLang(lang);
                        this.langService.setDefaultLang(lang);
                        this.localStorage.setStorage("userLang",lang);
                        //window.location.reload();
                      }
                    },
                    (error) => {
                      this.statusMessage = error;
                      this.isError = true;
                      this.showMessage = true;
                      this.FadeOutMessage();

                    }
                  );
                }else{
                  this.langService.setActiveLang(lang);
                  this.langService.setDefaultLang(lang);
                  this.localStorage.setStorage("userLang",lang);
                }
                if (!this.isAdmin) {


                  if(this.hasPrismAccess){
                      this.getBuildings();
                  }

                }
              }

            }
          );
        },
        error: (error) => {
          console.log(error)
        }
      });

      var userLocalLang = this.localStorage.getStorage("userLang");
      //var lang = this.usersService.getDefaultLang(this.usersService.loggedInUser);
      if(userLocalLang=='fr'){
        this.selectedBtnColorEn = "basic";
        this.selectedBtnColorFr = "primary";
        this.disableden = false;
        this.disabledfr=true;

      }else{
        this.selectedBtnColorEn = "primary";
        this.selectedBtnColorFr = "basic";
        this.disableden = true;
        this.disabledfr=false;
      }
  }

  change(lang: string) {
    this.loggedInUser = this.usersService.loggedInUser;

    this.usersService.putUserDefaultLang(this.loggedInUser,lang).subscribe(
      res=>{
        if(res){
          this.langService.setActiveLang(lang);
          this.langService.setDefaultLang(lang);
          this.localStorage.setStorage("userLang",lang);
        }
      },
      (error) => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();

      }
    );

    if(lang=='fr'){
      this.selectedBtnColorEn = "basic";
      this.selectedBtnColorFr = "primary";
      this.disableden = false;
      this.disabledfr=true;

    }else{
      this.selectedBtnColorEn = "primary";
      this.selectedBtnColorFr = "basic";
      this.disableden = true;
      this.disabledfr=false;
    }
  }

  //close the mobile left navigation in bigger browsers
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.drawer.close();
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 5000);
  }
  CloseMessage() {
    this.showMessage = false;
  }

  getBuildings() {
    this.buildingEngineService.getBuildingByUser()
      .subscribe((data) => {
        const result = Object.entries(data);
        //this.buildings = result[0][1]['buildings'];
        this.buildings = result[3][1];
        this.loading = false;

        this.localStorage.setStorage(this.constants.buildingCnt, this.buildings.length);
        //set default building
        this.defaultBuilding = this.buildings[0];
        this.buildingEngineService.changeBuilding(this.buildings[0]);
        this.localStorage.setStorage(this.constants.defaultBuilding, JSON.stringify(this.buildings[0]));

        //get service requests for the default building
        this.getServiceRequests();
      });
  }

  setNavigation(): void {
    //check user is first time user and redirect to my profile page
    if (this.loggedInUser.isFirstLogin) {
      this.navigateToMyProfile();
    }
    else {
      this.navigateToHome();
    }
  }

  getServiceRequests(): void {
    this.buildingEngineService.getServiceRequests(this.defaultBuilding.id!)
      .subscribe(data => {
        const result = Object.entries(data);

        if(result[3][1].length>0){
          this.allServiceRequests = result[0][1]['requests'];
          this.buildingEngineService.changeServiceRequests(result[0][1]['requests']);
        }

        this.setNavigation();
      });
  }

  logout() {
    localStorage.clear();
    this.authService.logout();
  }

  setLoginDisplay() {

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
    else {
      this.authService.loginRedirect(loginRequest);
    }

  }

  navigateToHome() {
    this.route.navigate([this.constants.homeUrl])
  }
  navigateToDashboard() {
    this.route.navigate([this.constants.dashboardUrl])
  }
  navigateToNews() {
    this.route.navigate([this.constants.newsUrl])
  }
  navigateToMyProfile() {
    this.route.navigate([this.constants.myProfileUrl])
  }


  // mobile view menu items
  sideNavigation: string[] = ['Home', 'News', 'My Account', 'Service Request', 'Invoices'];

  // Cleck event of mobile menu items
  clickMenuItem(menuItem: string) {
    if (menuItem == "Home") {
      this.navigateToHome();
    }
    if (menuItem == "News") {
      this.navigateToNews();
    }
    if (menuItem == "My Account") {
      this.navigateToDashboard();
    }
    this.drawer.close();
  }

}
