import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { News } from 'src/app/models/news';
import { UserRequest } from 'src/app/models/user-request';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';

interface UserTypes {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-allusers',
  templateUrl: './allusers.component.html',
  styleUrls: ['./allusers.component.scss']
})
export class AllusersComponent implements OnInit {
  userTypes: UserTypes[] = [
    {value: 'prism', viewValue: 'Service Request'},
    {value: 'versapay', viewValue: 'Invoice'},
  ];

  selectedUserType!:string;

  displayedColumns: string[] = ['email', 'role', 'regionName', 'companyName', 'Action'];
  private dsData: any;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource;
  get allUsers(): UserRequest[] {
    return this.usersService.allUsers;
  }
  set allUsers(newsList: UserRequest[]) {
    this.usersService.allUsers = newsList;
  }

  get impersonatedUser(): User {
    return this.usersService.impersonatedUser;
  }
  set impersonatedUser(user: User) {
    this.usersService.impersonatedUser = user;
  }

  constructor(private route: Router,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private usersService: UsersService,
  ) {
    this.usersService.allUsersSubject.subscribe((data: any) => {
      this.allUsers = data;
      this.LoadData();
    });

    this.selectedUserType = 'prism';
  }

  ngOnInit() {
    this.LoadData();
  }

  moduleChange(event:any){
    let result = event.value;
    if(result=='versapay'){
      this.getAllVersapayUsers();
    }else{
      this.getAllUsers();
    }
  }
  LoadData() {
    if (this.usersService.allUsers) {
      //get get All users
      this.dataSource.data = this.usersService.allUsers;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else {
      //get All users with service call
      this.getAllUsers();
    }
  }

  getAllUsers(): void {
    this.usersService.getUsersByAdminEmail()
      .subscribe(data => {
        this.allUsers = data;
        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  getAllVersapayUsers(){

    this.usersService.getVersapayUsersByAdminEmail()
    .subscribe(data => {
      this.allUsers = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  deleteUser(id: number) {
    this.usersService.deleteUser(id)
      .subscribe(data => {

        // Refresh DataTable to remove row.
        this.deleteRowDataTable(id);

        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = "Article de nouvelles supprimé avec succès!!";
        }else{
          this.statusMessage = "News Deleted Successfully!!";
        }

        this.isError = false;
        this.showMessage = true;
        this.FadeOutMessage();

      },
        (err) => {
          this.statusMessage = err;
          this.isError = true;
          this.showMessage = true;
          this.FadeOutMessage();

          console.log(err.error);
          console.log(err.message);
        });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private deleteRowDataTable(recordId: number) {
    this.dsData = this.dataSource.data;
    const itemIndex = this.dsData.findIndex((obj: any) => obj["id"] === recordId);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;

    //update news list
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  previewNews(newsId: string) {
    this.route.navigate([this.constants.previewsUrl, newsId])
  }

  clickImpersonateUser(userEmail: any) {
    this.usersService.onImpersonateUser(userEmail);
    window.location.reload();
    // setTimeout(() => {
    //   this.route.navigate([this.constants.homeUrl]);
    // }, 100);
    
    // window.location.reload();
   
  }
}
