import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isAdmin: boolean = false;
  AdminView:boolean = false;

  showServiceRequest: boolean = false;

  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  constructor(private usersService: UsersService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private buildingEngineService: BuildingEngineService) {

    this.buildingEngineService.showServiceRequest.subscribe((data: boolean) => {
      this.showServiceRequest = data;
    });

    //when impersonate button clicked show the service requests
    this.usersService.isImpersonated.subscribe((data: boolean) => {
      this.showServiceRequest = data;
    });
  }

  ngOnInit(): void {
    let userRoles:any = this.usersService.loggedInUser.roles;

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        this.rework(this.usersService.loggedInUser);
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);
        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.rework(data);
      });

  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
  }
}
