<!-- Home New -->
<div *ngIf="newsItems">
<div class="home-news-section mat-elevation-z1 mt-5">
  <div class="container">

    <!-- News -->
    <div class="row">

      <div class="col-md-6">
        <div class="news-wrapper pt-4">
            <h2 class="news-lead">{{'news' | transloco}}</h2>
            <h2 class="news-lead-title">{{newsItems[0]?.title}} </h2>
            <p class="news-lead-description" *ngIf="newsItems[0]?.description" [innerHTML]="(newsItems[0].description && newsItems[0].description.length>500)? (newsItems[0].description | slice:0:500)+'..':(newsItems[0].description)"></p>            
            <button mat-button color="primary" class="news-lead-read-more" (click)="navigateToNewsArticlePage(newsItems[0]?.id!)">{{'readmore' | transloco}}</button>
        </div>
      </div>
      <div class="col-md-6">
          <img [src]="blobImagePath + newsItems[0]?.imageFileName" alt="" class="news-side-image" />
      </div>

    </div>
    <!-- News -->

  </div>
</div>
<!-- Home New -->

<!-- More News-->
<div class="more-news-wrapper mt-5">
  <div class="container">

    <div class="news-title-wrapper mb-4">
      <h2 class="more-news">{{'morenews' | transloco}} </h2>
      <button mat-button class="view-all-link" (click)="navigateToNews()">{{'viewall' | transloco}}</button>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 mt-3 mb-3" *ngFor="let card of newsItems.slice(1, 7)">
        <mat-card class="rounded-0 shadow-sm">
          <a *ngIf="card.imageFileName" class="news-imag-link" (click)="navigateToNewsArticlePage(card.id!)">
            <img mat-card-image [src]="blobImagePath + card.imageFileName" alt="card.imageFileName" class="news-item-image">
          </a>         
          <span *ngIf="card.featured" [class]="star"></span>
          <mat-card-content class="mat-card-content-home">
            <p class="news-date mb-0">{{card.dateCreated | date: 'd MMM y'}}</p>
            <h3 class="news-title">{{card.title}}</h3>
            <p class="news-description" [innerHTML]= "(card.description && card.description.length>125)? (card.description | slice:0:125)+'..':(card.description)">
              
            </p>           
          </mat-card-content>
          <mat-card-actions class="mb-2">
            <!-- <button mat-button class="view-all-news" (click)="navigateToNewsArticlePage(card.id!)"> {{buttonText}} </button> -->
            <button mat-button class="view-all-news" (click)="navigateToNewsArticlePage(card.id!)"> {{'readmore' | transloco}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

  </div>
</div>
<!-- More News-->

</div>
