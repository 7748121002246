import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { News } from 'src/app/models/news';
import { NewsTypes } from 'src/app/models/newsTypes';
import { Status } from 'src/app/models/status';
import { ConstantsService } from 'src/app/services/constants.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { ManageStorageService } from 'src/app/services/manage-storage.service';


@Component({
  selector: 'app-admin-news-article',
  templateUrl: './admin-news-article.component.html',
  styleUrls: ['./admin-news-article.component.scss']
})

export class AdminNewsArticleComponent implements OnInit {

  isUpdate: boolean = false;
  submitBtnText: string = "";
  newsId?: string = "";
  isSaved: boolean = false;
  isNational: boolean = false;
  isRegional: boolean = true;
  isProperty: boolean = true;
  createdNews !: News;
  regionsList !: any;
  buildingList !: any;
  selectedImage !: File;
  imageName: string = "";
  imageURL: string;
  uploadImageBtnText: string = "";
  currentNews: News = new News;
  videoUrl !: any;
  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  urlPattern: string ="^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$";
  savedraft_btn:string ="";

  @ViewChild('matRegion') matRegion!: MatSelect;
  @ViewChild('matProperty') matProperty!: MatSelect;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      ['link', 'image'],
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                        // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      ['clean']
    ]
  };


  newsForm: FormGroup = this.formBuilder.group({
    id: new FormControl(null),
    title: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    region: new FormControl(''),
    property: new FormControl(''),
    featured: new FormControl(false),
    status: new FormControl(''),
    details: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    image: new FormControl(null, Validators.required),
    imagePath: new FormControl(''),
    videoLink: new FormControl('', [Validators.pattern(this.urlPattern)])
  });


  constructor(private route: Router,
    private activatedRoute: ActivatedRoute,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private newsService: NewsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer) {
    this.submitBtnText = this.constants.propertyText_Select;
    this.imageURL = "";
    this.isSaved = false;
  }

  ngOnInit(): void {
    this.getRegions();
    this.getAllBuildings();

    if(this.localStorage.getStorage('userLang')=='fr'){
      this.savedraft_btn = "savedraft_btn"
    }else{
      this.savedraft_btn = "";
    }

    this.activatedRoute.paramMap.subscribe(param => {
      const newsId = param.get('id');
      if (newsId) {
        this.submitBtnText = this.constants.submitBtnText_Update;
        this.uploadImageBtnText = this.constants.uploadImageBtnText_Update;
        this.isUpdate = true;
        this.newsId = newsId;
        //this.isSaved = true;
        this.getNewsById(newsId);
      }
      else {

        this.submitBtnText = this.constants.submitBtnText_Select;
        this.uploadImageBtnText = this.constants.uploadImageBtnText_New;
        this.isUpdate = false;
      }
    });
  }

  getRegions() {
    this.newsService.getRegions().subscribe(
      (res) => {
        this.regionsList = res;
      },
      (err: any) => console.log(err)
    );
  }

  getAllBuildings() {
    this.newsService.getAllBuildings().subscribe(
      (res) => {
        this.buildingList = res;
      },
      (err: any) => console.log(err)
    );
  }

  clearRegion() {
    this.matRegion.options.forEach((data: MatOption) => data.deselect());
  }

  clearProperty() {
    this.matProperty.options.forEach((data: MatOption) => data.deselect());
  }

  setDefaultRegion() {
    this.currentNews.regionId?.forEach((regionId: number) => {
      this.matRegion.options.forEach((data: MatOption) => {
        if (regionId == data.value) {
          data.select()
        }
      });
    });
  }

  setDefaultProperty() {
    this.matProperty.options.forEach((data: MatOption) => data.deselect());
  }

  getNewsById(newsId: string) {
    this.newsService.getNewsById(newsId).subscribe(
      (news: News) => {
        this.currentNews = news;
        console.log(news);
        this.editNewsArticle(news)
      },
      (err: any) => console.log(err)
    );
  }

  editNewsArticle(newsArticle: News) {
    if (newsArticle.imageFileName) {
      let url = environment.blobImagePath + newsArticle.imageFileName;
      this.imageURL = url;
    }

    if(newsArticle.videoLink){
      let url = newsArticle.videoLink;//this.sanitizer.bypassSecurityTrustResourceUrl(newsArticle.videoLink);
      this.videoUrl = url;
    }

    if (newsArticle) {

      switch (newsArticle.type) {
        case (NewsTypes.national):
          {
            this.isNational = true;
            this.isRegional = false;
            this.isProperty = false;
            this.newsForm.controls['region'].clearValidators();
          this.newsForm.controls['region'].updateValueAndValidity();
          this.newsForm.controls['property'].clearValidators();
          this.newsForm.controls['property'].updateValueAndValidity();
            break;
          }
        case (NewsTypes.regional): {
          this.isNational = false;
          this.isRegional = true;
          this.isProperty = false;
          this.newsForm.controls["region"].setValidators(Validators.required);
        this.newsForm.controls['region'].updateValueAndValidity()
        this.newsForm.controls['property'].clearValidators()
        this.newsForm.controls['property'].updateValueAndValidity()
          break;
        }
        case (NewsTypes.property): {
          this.isNational = false;
          this.isRegional = false;
          this.isProperty = true;
          this.newsForm.controls["property"].setValidators(Validators.required);
        this.newsForm.controls['property'].updateValueAndValidity()
        this.newsForm.controls['region'].clearValidators()
        this.newsForm.controls['region'].updateValueAndValidity()
          break;
        }
      }

      this.newsForm.controls['image'].clearValidators();
      this.newsForm.controls['image'].updateValueAndValidity();

      this.newsForm.patchValue({
        id: newsArticle.id,
        title: newsArticle.title,
        startDate: newsArticle.startDate,
        type: newsArticle.type,
        endDate: newsArticle.endDate,
        region: newsArticle?.regionId,
        property: newsArticle?.buildingId,
        featured: newsArticle.featured,
        status: newsArticle.status,
        details: newsArticle.description,
        videoLink: newsArticle.videoLink,
        //image: newsArticle.imageFileName
         });
    }
  }

  genatareYoutubeUrl(url:string): string{
    var code = "";
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      code = match[2];
    }
    return code;
  }

  videoURL() {
    return this.sanitizer.bypassSecurityTrustUrl(this.videoUrl);
  }

  compareObjects(o1: any, o2: any): boolean {

    return o1 && o2 && o1 === o2;
    //return o1.name === o2.name && o1.id === o2.id;
  }

  setFormDataToSubmit(newsArticle: any) {

    var loggedInUser = this.usersService.loggedInUser.id;

    let date = new Date();
    const datepipe: DatePipe = new DatePipe('en-US')
    let currentDate = datepipe.transform(date, 'dd-MMM-YYYY HH:mm:ss');

    var formData: any = new FormData();

    formData.append("title", newsArticle.title);
    formData.append("description", newsArticle.details);
    formData.append("type", newsArticle.type);
    formData.append("startDate", datepipe.transform(newsArticle.startDate, 'dd-MMM-YYYY HH:mm:ss'));
    formData.append("endDate", datepipe.transform(newsArticle.endDate, 'dd-MMM-YYYY HH:mm:ss'));
    formData.append("featured", newsArticle.featured);

    //console.log(newsArticle.details);

    if (this.isUpdate) {
      formData.append("id", newsArticle.id);
      formData.append("userModified", loggedInUser);
      formData.append("dateModified", currentDate);
      if (this.currentNews.dateCreated) {
        formData.append("dateCreated", this.currentNews.dateCreated);
      }

      if (this.currentNews.userCreated) {
        formData.append("userCreated", this.currentNews.userCreated);
      }

    }
    else {
      formData.append("id", 0);
      formData.append("userCreated", loggedInUser?.toString());
      formData.append("dateCreated", currentDate);
    }

    formData.append("status", Status.draft);

    // if property selected
    if (newsArticle.type == NewsTypes.property) {
      for (let property of newsArticle.property) {
        formData.append('buildingId[]', property);
      }
      //formData.append("buildingId[]", JSON.stringify(newsArticle.property));
    }
    else {
      formData.append("buildingId[]", [0]);
    }

    // if region selected
    if (newsArticle.type == NewsTypes.regional) {
      for (let region of newsArticle.region) {
        formData.append('regionId[]', region);
      }
    }
    else {
      formData.append("regionId[]", [0]);
    }

    if (this.selectedImage) {
      // if there is a seleted image
      formData.append("file", this.selectedImage, this.selectedImage.name);
    }
    else {
      if (this.isUpdate && this.currentNews.imageFileName) {
        formData.append("imageFileName", this.currentNews.imageFileName);
      }
    }

    if(newsArticle.videoLink){
      var baseYoutubeUrl = "https://www.youtube.com/embed/";
      var code = this.genatareYoutubeUrl(newsArticle.videoLink);
      if (code) {
        let youtubeUrl = baseYoutubeUrl + code;
        let url = youtubeUrl ;
        this.videoUrl = url;
        formData.append("videoLink", this.videoUrl);
      }
      else{
        this.newsForm.controls["youtube"].setValidators(Validators.pattern(this.urlPattern));
        this.newsForm.controls['youtube'].updateValueAndValidity();
      }
    }

    //formData.append("youtube", newsArticle.youtube);

    return formData;
  }

  insertNewsArticle(newsArticle: any) {
    var formData = this.setFormDataToSubmit(newsArticle);

    this.newsService.createNewsArticle(formData).subscribe(
      (res) => {
        if (res) {
          this.createdNews = res;
          this.isSaved = true;
          this.newsForm.markAsPristine();

          //load the news list
          this.getAllNews();


          if(this.localStorage.getStorage('userLang')=='fr'){
            this.statusMessage = "Article de nouvelles créé avec succès!!";
          }else{
            this.statusMessage = "News Created Successfully!!";
          }
          this.isError = false;
          this.showMessage = true;

          this.newsForm.disable();

          this.FadeOutMessage();
        }
      },
      (error: any) => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    )
  }

  getAllNews(): void {
    this.newsService.getAllNews()
      .subscribe(data => {
        this.newsService.allNews = data;
      });
  }


  updateNewsArticle(newsArticle: News) {
    var formData = this.setFormDataToSubmit(newsArticle);

    console.log(formData);

    this.newsService.updateNewsArticle(formData).subscribe(
      (res) => {
        if (res) {
          this.createdNews = res;
          this.isSaved = true;
          this.newsForm.markAsPristine();

          this.getAllNews();

          if(this.localStorage.getStorage('userLang')=='fr'){
            this.statusMessage = "Article de nouvelles mis à jour avec succès!!";
          }else{
            this.statusMessage = "News Updated Successfully!!";
          }
          this.isError = false;
          this.showMessage = true;

          this.newsForm.disable();

          this.FadeOutMessage();
        }
      },
      (error: any) => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    )
  }

  onSubmit() {
    if (this.isUpdate) {
      this.newsForm.updateValueAndValidity()
      if (this.newsForm.valid) {
        this.updateNewsArticle(this.newsForm.value);
      }
    }
    else {
      if (this.newsForm.valid) {
        this.insertNewsArticle(this.newsForm.value);
      }
    }
  }

  changeNewsType(value: string) {
    this.clearRegion();
    this.clearProperty();
    switch (value) {
      case (NewsTypes.national):
        {
          this.isNational = true;
          this.isRegional = false;
          this.isProperty = false;

          this.newsForm.controls['region'].clearValidators();
          this.newsForm.controls['region'].updateValueAndValidity();
          this.newsForm.controls['property'].clearValidators();
          this.newsForm.controls['property'].updateValueAndValidity();

          break;
        }
      case (NewsTypes.regional): {
        this.isNational = false;
        this.isRegional = true;
        this.isProperty = false;
        //set selected values in regions
        if (this.isUpdate) {
          this.setDefaultRegion();
        }
        this.newsForm.controls["region"].setValidators([Validators.required]);
        //this.newsForm.controls["region"].setErrors(Validators.required);
        this.newsForm.controls["region"].updateValueAndValidity();
        this.newsForm.controls["property"].clearValidators();
        this.newsForm.controls["property"].updateValueAndValidity();

        break;
      }
      case (NewsTypes.property): {
        this.isNational = false;
        this.isRegional = false;
        this.isProperty = true;

        this.newsForm.controls["property"].setValidators(Validators.required);
        this.newsForm.controls['property'].updateValueAndValidity()
        this.newsForm.controls['region'].clearValidators()
        this.newsForm.controls['region'].updateValueAndValidity()

        break;
      }
    }
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 5000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  OnImageClick() {
    this.newsForm.controls.image.markAsTouched();
  }

  onFileSelected(event: Event) {

    let file = <HTMLInputElement>event.target;
    if (file.files && file.files?.length) {
      this.selectedImage = file.files[0];
      this.imageName = this.selectedImage.name;

      if (this.selectedImage.type != 'image/jpeg' && this.selectedImage.type != 'image/gif' && this.selectedImage.type != 'image/png') {
        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = 'Type de fichier à usage restreint';
        }else{
          this.statusMessage = 'Restricted File type';
        }
        this.isError = true;
        this.showMessage = true;
        const imageControl = this.newsForm.get('image');
        if (imageControl) {
          imageControl.setErrors({ invalidFileType: true });
        }
        this.FadeOutMessage();
      }

      // check file size
      var size = this.selectedImage.size / (1024 * 1024);
      if (size > 10) {
        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = 'Taille du fichier supérieure à 10 Mo';
        }else{
          this.statusMessage = 'File is bigger than 10MB';
        }

        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }

      /* this.newsForm.patchValue({
         image: this.selectedImage
       });
       this.newsForm.get('image')?.updateValueAndValidity()*/
    }

  }

  navigateToNewsArticlePreview() {
    //if (this.isSaved) {
    if (this.isUpdate) {
      this.route.navigate([this.constants.previewsUrl, this.newsId])
    }
    else {
      this.route.navigate([this.constants.previewsUrl, this.createdNews.id])
    }

    //}
  }

  navigateToNews() {
    this.route.navigate([this.constants.newsUrl])
  }

}
