import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { ConstantsService } from './constants.service';
import { protectedResources } from 'src/app/auth-config';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { ManageStorageService } from './manage-storage.service';
import { OpenInvoiceStatus } from '../models/open-invoice-status';
import { vCompany } from '../models/company';
import { UsersService } from './users.service';
import { User } from '../models/user';
import { IssueType } from '../models/issueType';

@Injectable({
  providedIn: 'root'
})
export class VersapayService {

  openInvoicesStatus!: OpenInvoiceStatus;
  //companies
  properties !: any[];
  defaultCompany!: vCompany;
  vcompany: Subject<vCompany> = new Subject<vCompany>();

  //show openinvoice Request component
  showOpenInvoiceRequest: Subject<boolean> = new Subject<boolean>();
  showSummaryInvoiceRequest: Subject<boolean> = new Subject<boolean>();

  summaryInvoices!:any;
  // open invoice requests
  openInvoiceRequests!: any;
  allOpenInvoiceRequests: Subject<any[]> = new Subject<any[]>();
  allSummaryInvoiceRequests: Subject<any[]> = new Subject<any[]>();

  readonly versapayURL = protectedResources.versapayApi.endpoint;

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private localStorage: ManageStorageService) { }

    changeCompany(data: vCompany) {
      this.vcompany.next(data);
    }


  changeOpenInvoiceRequests(data: any) {
    this.allOpenInvoiceRequests.next(data);
  }

  changeSummaryInvoiceRequests(data: any) {
    this.allSummaryInvoiceRequests.next(data);
  }

  changeShowOpenInvoiceRequests(data: boolean) {
    this.showOpenInvoiceRequest.next(data);
  }

  changeShowSummaryInvoiceRequests(data: boolean) {
    this.showSummaryInvoiceRequest.next(data);
  }
  getCompanyByUser(email:any) {
    //let email = this.localStorage.getStorage(this.constants.userEmail);
    let serviceRequestsUrl = this.versapayURL + this.constants.getCompaniesById;
    //return this.http.get(`${serviceRequestsUrl}?email=${email}`)
    return this.http.get(serviceRequestsUrl+"/"+email)
      .pipe(catchError(this.handleError));
  }

  getAllInvoices(companyId: string) {
    let email = this.localStorage.getStorage(this.constants.userEmail);
    let organizationId: string = this.localStorage.getStorage(this.constants.userOrgId)!;
    //let serviceRequestsUrl = this.prismURL + this.constants.getWorkOrderByEmail;
    let serviceRequestsUrl = this.versapayURL + this.constants.getCustomerInvoices;
    return this.http.get<any[]>(serviceRequestsUrl+"/"+companyId)
      .pipe(catchError(this.handleError));
  }

  getSummaryInvoices(companyId: string) {
    //let email = this.localStorage.getStorage(this.constants.userEmail);
    let organizationId: string = this.localStorage.getStorage(this.constants.userOrgId)!;
    //let serviceRequestsUrl = this.prismURL + this.constants.getWorkOrderByEmail;
    let serviceRequestsUrl = this.versapayURL + this.constants.getSummaryInvoiceById;
    return this.http.get<any[]>(serviceRequestsUrl+"/"+companyId)
      .pipe(catchError(this.handleError));
  }


  private handleError(errorResponce: HttpErrorResponse) {
    if (errorResponce.error instanceof ErrorEvent) {
      console.error("Client side error: ", errorResponce.error.message);
    }
    else {
      console.error("BE API : Server side error: ", errorResponce);
    }
    return throwError(errorResponce);
  }

}
