<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- =====================================Tenant View==================================================== -->

  <div *ngIf="!isAdmin">
    <div class="title-wrapper">

      <!-- News Date -->
      <p class="news-article-date mb-0">{{currentNews?.dateCreated | date: 'MMMM d, y'}}</p>
      <!-- News Date -->

      <!-- Inside Content -->
      <div class="title-inside-wrapper">

        <!-- Title -->
        <div class="sr-title-wrapper">
          <h1 class="mat-headline mat-display-1 mb-0">{{currentNews?.title}}</h1>
        </div>
        <!-- Title -->

        <!-- Search -->
        <div>
          <button mat-button class="view-all-news" (click)="navigateToNews()">{{'backtonews' | transloco}}</button>
        </div>
        <!-- Search -->

      </div>
      <!-- Inside Content -->
      <!-- news type and star -->
      <div class="rio-news-type">
        <p class="mt-2">{{newsTypeText}}</p>
        <span *ngIf="currentNews?.featured" class="news-star"></span>
      </div>
      <!-- news type and star -->
    </div>

    <!-- News Article -->
    <mat-card class="mt-3 rounded-0 shadow-sm rio-mat-card">
      <div *ngIf="imageURL" class="news-image-wraper">
        <img mat-card-image [src]="imageURL" alt="{{currentNews?.title}}">
      </div>
      <!-- News content -->
      <mat-card-content class="rio-mat-card-content">
        <p [innerHTML]="currentNews?.description"></p>
      </mat-card-content>
      <!-- /News content -->

      <!-- Video Preview -->
      <mat-card-content *ngIf="videoURL" class="rio-mat-card-content">
        <div>
          <iframe width="100%" height="400" [src]='videoURL | videoSafe' frameborder="0" allowfullscreen></iframe>
        </div>
      </mat-card-content>
      <!-- Video Preview -->

      <!-- Submit/Cancel buttons - Tenant -->
      <mat-card-actions *ngIf="!isAdmin" class="mt-3 mb-3 rio-mat-card-action">
        <button mat-button [disabled]="isBackDisabled" class="back-t0-news-btn"
          (click)="backButtonClick()">{{'back' | transloco}}</button>
        <button mat-button [disabled]="isNextDisabled" class="back-btn" (click)="nextButtonClick()">{{'nextarticle' | transloco}}</button>
      </mat-card-actions>
      <!-- Submit/Cancel buttons - Tenant -->
    </mat-card>
    <!-- News Article -->


  </div>
  <!-- Tenant View -->

  <!-- ====================================Admin View===================================================== -->

  <!-- News Preview -->
  <div *ngIf="isAdmin" class="sr-title-section mt-3">
    <div class="title-inside-wrapper">
      <!-- Title -->
      <div class="sr-title-wrapper">
        <h1 class="mat-headline mat-display-1 mb-0">{{'newspreview' | transloco}}</h1>
      </div>
      <!-- Title -->
      <!-- Back to news -->
      <div>
        <button mat-button class="view-all-news" (click)="navigateToNews()">{{'backtonews' | transloco}}</button>
      </div>
    </div>
    <!-- Back to news -->
  </div>
  <!-- News Preview -->

  <!-- Admin View -->
  <div *ngIf="isAdmin" class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-3">

      <!-- Side Navigation -->
      <app-side-nav></app-side-nav>
      <!-- Side Navigation -->

    </div>
    <!-- Left Column -->


    <!-- Right Column -->
    <div class="col-md-9 mt-3 mt-md-0">

      <!-- Alerts -->
      <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
        {{statusMessage}}
        <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Alerts -->

      <mat-card class="rounded-0 pl-4 pr-4 shadow-sm">

        <div class="title-wrapper-admin pb-0">
          <!-- News Date -->
          <p class="news-article-date mb-0">{{currentNews?.dateCreated | date: 'MMMM d, y'}}</p>
          <!-- News Date -->

          <!-- Inside Content -->
          <div class="title-inside-wrapper">

            <!-- Title -->
            <div class="sr-title-wrapper">
              <h1 class="mat-headline mat-display-1 mb-0">{{currentNews?.title}}</h1>
            </div>
            <!-- Title -->
          </div>
          <!-- Inside Content -->

          <!-- news type and star -->
          <div class="rio-news-type">
            <p class="mt-2">{{newsTypeText}}</p>
            <span *ngIf="currentNews?.featured" class="news-star"></span>
          </div>
          <!-- news type and star -->
        </div>

        <!-- News Article -->
        <mat-card class="mt-3 rounded-0 shadow-none pl-0 pr-0">

          <!-- News Image -->
          <div *ngIf="imageURL" class="news-image-wraper">
            <img mat-card-image [src]="imageURL" alt="{{currentNews?.title}}">
          </div>
          <!-- News Image -->

          <!-- News content -->
          <mat-card-content class="">
            <p [innerHTML]="currentNews?.description"></p>
          </mat-card-content>
          <!-- /News content -->

          <!-- Video Preview -->
          <mat-card-content *ngIf="videoURL" class="rio-mat-card-content">
            <div>
              <iframe width="100%" height="400" [src]='videoURL | videoSafe' frameborder="0" allowfullscreen></iframe>
            </div>
          </mat-card-content>
          <!-- Video Preview -->
          
          <!-- Submit/Cancel buttons - Admin -->
          <mat-card-actions *ngIf="isAdmin" class="col-md-12 mt-5 news-action-wrapper">
            <button mat-raised-button class="cancel-button shadow-none" (click)="navigateToNews()">{{'cancel' | transloco}}</button>
            <button mat-raised-button class="cancel-button shadow-none" (click)="editNews()"
              style="margin-right: 20px;">{{'edit' | transloco}}</button>
            <button mat-raised-button class="submit-button shadow-none" [disabled]="isSubmit"
              (click)="submitNews()">{{'submit' | transloco}}</button>
          </mat-card-actions>
          <!-- Submit/Cancel buttons -->

        </mat-card>
        <!-- News Article -->


      </mat-card>
    </div>
    <!-- Right Column -->


  </div>
  <!-- Admin View -->

</div>