<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'news' | transloco}}</h1>
    </div>
    <!-- Title -->

    <!-- Add new and view all button -->
    <div class="">
      <button mat-button class="add-new-button" (click)="navigateToNewsArticle()">{{'addnewsarticle' | transloco}}</button>
    </div>
    <!-- Add new and view all button -->

  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-4 col-lg-3">

      <!-- Side Navigation -->
      <app-side-nav></app-side-nav>
      <!-- Side Navigation -->

    </div>
    <!-- Left Column -->


    <!-- Right Column -->
    <div class="col-md-8 col-lg-9 mt-3 mt-md-0">
      
      <!-- Alerts -->
      <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
        {{statusMessage}}
        <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Alerts -->

      <div class="service-request-content shadow-sm">

        <!-- Button row -->
        <div class="mb-3 filter-wrapper">
          <form class="service-search">
            <input matInput #filter (keyup)="applyFilter(filter.value)" placeholder="{{'search' | transloco}}" class="search-filter">
            <!--<mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
                  {{street}}
                </mat-option>
              </mat-autocomplete>-->
          </form>
        </div>
        <!-- Button row -->


        <!-- Table -->
        <div class="table-wrapper table-scroll-600">
          <table mat-table [dataSource]="dataSource" matSort class="">

            <!-- Title Column -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'title' | transloco}} </th>
              <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">
                <button mat-button class="table-link" style="width: 300px;" (click)="previewNews(element.id)">{{element.title}} </button>
              </td>
            </ng-container>

            <!-- NewsType Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'newstype' | transloco}} </th>
              <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove"> {{element.type}} </td>
            </ng-container>


            <!-- Date Column -->
            <ng-container matColumnDef="dateCreated">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'date' | transloco}} </th>
              <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.dateCreated | date: 'd
                MMM y'}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'status' | transloco}}  </th>
              <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.status}}</td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove"> </th>
              <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pr-0 text-center">
                <button mat-raised-button class="table-red-btn mr-2">
                  <mat-icon class="material-icons-outlined" (click)="deleteNews(element.id)"> delete </mat-icon>
                </button>
                <button mat-raised-button class="table-red-btn" (click)="editNews(element.id)">
                  <mat-icon class="material-icons-outlined"> mode_edit </mat-icon>
                </button>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <!-- Table -->

      </div>

      <!-- Paginator -->
      <mat-paginator aria-label="" [pageSizeOptions]="[10]" class="mt-3"></mat-paginator>
      <!-- Paginator -->

    </div>
    <!-- Right Column -->


  </div>

</div>