import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Building } from 'src/app/models/building';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';

export interface DialogData {
  buildings: Building[];
}

@Component({
  selector: 'app-building-dialog',
  templateUrl: './building-dialog.component.html',
  styleUrls: ['./building-dialog.component.scss']
})
export class BuildingDialogComponent implements OnInit {

  buildings !: Building[];
  defaultBuildingId !: string;
  chosenItem!:string;
  isBuildingSelected : boolean = true;

  constructor(public dialogRef: MatDialogRef<BuildingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,) {   
    this.buildings = data.buildings;
    
    //get default building    
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      var result = JSON.parse(building || '{}');           
      this.chosenItem = result.id;
      this.isBuildingSelected = false;
    }
    
  }

  ngOnInit(): void {
  }

  radioChange(event: string) {    
    this.defaultBuildingId = event;
    this.isBuildingSelected = false;
  }

  save() {    
    this.dialogRef.close(this.buildings.filter(a => a.id == this.defaultBuildingId));
  }

}
