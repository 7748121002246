<!-- Add new and view all button -->
<div class="container">
  <div class="sr-button-row mb-5" *ngIf="!isAdmin && hasVersapayAccess && hasPrismAccess">
    <button mat-raised-button class="service-request-button" [routerLink]="['/Home/Dashboard/ServiceRequest']">{{'servicerequest' | transloco}}</button>
    <button mat-raised-button class="invoice-details-button" [routerLink]="['/Home/Dashboard/Invoices']">{{'invoices' | transloco}}</button>
  </div>
</div>
<!-- Add new and view all button -->

<!-- Home Service Request -->
<app-home-service-request *ngIf="!isAdmin && !hasVersapayAccess && hasPrismAccess"></app-home-service-request>
<!-- Home Service Request -->

<!-- Home Versapay -->
<!-- <app-invoice *ngIf="!isAdmin && hasVersapayAccess && !hasPrismAccess"></app-invoice> -->
<app-home-open-invoices *ngIf="!isAdmin && hasVersapayAccess && !hasPrismAccess"></app-home-open-invoices>
<!-- /Home Versapay-->

<!-- Home News Section -->
<app-home-news></app-home-news>
<!-- Home News Section -->
