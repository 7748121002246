<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->
  <app-building-select></app-building-select>

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'servicerequest' | transloco}}</h1>
    </div>
    <!-- Title -->

    <!-- Add new and view all button -->
    <div class=""  *ngIf="!isImpersonate">
      <button mat-button class="add-new-button" (click)="navigateToNewServiceRequest()">{{'addnewrequest' | transloco}}</button>
    </div>
    <!-- Add new and view all button -->

  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-4 col-lg-3">

      <!-- Side Navigation -->
      <app-side-nav></app-side-nav>
      <!-- Side Navigation -->

    </div>
    <!-- Left Column -->

    <!-- Right Column -->
    <div class="col-md-8 col-lg-9 mt-3 mt-md-0">

      <!-- Stats box -->
      <div class="row pl-3 pr-3">
        <div class="col-sm-4 col-md-4 sr-stats-box sr-red">
          <div class="sr-stats-amount"><span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.newCount || 0) < 10">0</span>{{serviceRequestsStatus?.newCount}}</div>
          <div class="sr-stats-text">{{'newrequests' | transloco}}<span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.newCount || 0) > 1">s</span>  </div>
        </div>
        <div class="col-sm-4 col-md-4 sr-stats-box sr-grey">
          <div class="sr-stats-amount"><span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.inProgressCount || 0) < 10">0</span>{{serviceRequestsStatus?.inProgressCount}}</div>
          <div class="sr-stats-text">{{'inprogress' | transloco}} </div>
        </div>
        <div class="col-sm-4 col-md-4 sr-stats-box sr-black">
          <div class="sr-stats-amount"><span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.completedCount || 0) < 10">0</span>{{serviceRequestsStatus?.completedCount}}</div>
          <div class="sr-stats-text">{{'completed' | transloco}} </div>
        </div>
      </div>
      <!-- Stats box -->

      <div class="mt-4">

        <div class="service-request-content shadow-sm">

          <!-- Button row -->
          <div class="mb-3 filter-wrapper">
            <button mat-button (click)="exporter.exportTable('xlsx')" class="overirde-export-button">
              <mat-icon>exit_to_app</mat-icon>
              <span class="sr-export-button pl-2">{{'exporttoexcel' | transloco}}</span>
            </button>

            <form class="service-search">
              <input matInput #filter (keyup)="applyFilter(filter.value)" placeholder="{{'search' | transloco}}" class="search-filter">
              <!--<mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
                  {{street}}
                </mat-option>
              </mat-autocomplete>-->
            </form>
          </div>
          <!-- Button row -->

          <!-- Table -->
          <div class="table-wrapper table-scroll-600">
            <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" matSort class="">

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove pl-2 pr-2" style="width: 300px;"> {{'description' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                  <a class="table-link"  matTooltip={{element.description}} (click)="editServiceRequest(element.id)">{{(element.description.length>30)? (element.description | slice:0:30)+'..':(element.description)}}</a>
                  <!-- <button mat-button class="table-link"
                    (click)="editServiceRequest(element.requestId)">{{element.description}}
                   </button> -->
                </td>
              </ng-container>

              <!-- RequestId Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove pl-2 pr-2" style="width: 150px;">{{'requestid' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2"> {{element.identifier}} </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove pl-2 pr-2"> {{'status' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                  <span class="status-label" [ngClass]="(element.status=='completed')?'complete-button':'inprogress-button'">{{element.status}}</span>
                  <!-- <button mat-flat-button [ngClass]="(element.status=='1')?'inprogress-button':(element.status=='0')?'inprogress-button':'complete-button'">{{element.status | status}}</button>  -->
                </td>
              </ng-container>

              <!-- Days Old Column -->
              <ng-container matColumnDef="daysOld">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove pl-2 pr-2" style="width: 100px; text-align: center;"> {{'daysold' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2 text-center">{{element.daysOld}}</td>
              </ng-container>

              <!-- Suite Column -->
              <ng-container matColumnDef="space.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove pl-2 pr-2" style="width: 100px;"> {{'suite' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">{{element.space.name}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
          <!-- Table -->

        </div>

        <!-- Paginator -->
        <mat-paginator aria-label="" class="mt-3" [pageSizeOptions]="[10]"></mat-paginator>
        <!-- Paginator -->

      </div>

    </div>
    <!-- Right Column -->

  </div>

</div>
