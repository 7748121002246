<mat-drawer-container class="example-container" autosize>

  <!-- Desktop header -->
  <app-header class="d-none d-xl-block sticky-header" *ngIf="loginDisplay"></app-header>
  <!-- Desktop header -->

  <!-- Mobile Side Navigation -->
  <mat-drawer #drawer class="sideNavigarion" mode="side">
    <mat-selection-list #nav [multiple]="false" class="pt-0">
      <mat-list-option *ngFor="let nav of sideNavigation" #event [value]="nav" (click)="clickMenuItem(event.value)">
        {{nav}}
      </mat-list-option>
    </mat-selection-list>
  </mat-drawer>
  <!--  Mobile Side Navigation -->

  <!-- Mobile header -->
  <div class="d-lg-block d-xl-none sticky-header" *ngIf="loginDisplay">
    <div class="row mr-0 ml-0">
      <div class="col-3">
        <button type="button" mat-icon-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="col-4">
        <span class="appbar-logo-wrapper">
          <a href="#" aria-label="RioCan Logo link">
            <img src="./../../../../../../assets/images/L_RioCan_Logo_Icon_rgb.svg" alt="logo" class="appbar-logo" />
          </a>
        </span>
      </div>
      <div class="col-5">

        <!-- Language buttons-->
        <div class="switch-lang-btn-wrapper switch-lang-btn-wrapper-mb">
          <button mat-mini-fab color="{{selectedBtnColorEn}}"   [ngStyle]="{transform: 'scale(0.8)'}" [disabled]="disableden" (click)="change('en')">EN</button>
          <span>|</span>
          <button mat-mini-fab  color="{{selectedBtnColorFr}}" [ngStyle]="{transform: 'scale(0.8)'}"  [disabled]="disabledfr" (click)="change('fr')">FR</button>
        </div>
        <!-- Language buttons-->

        <button mat-icon-button class="hamburger-button" aria-label="Hambueger menu" [matMenuTriggerFor]="beforeMenu"
          color="">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before" class="mat-menu-override">
          <button mat-menu-item (click)="navigateToMyProfile()">{{'myprofile' | transloco}}</button>
          <button mat-menu-item (click)="navigateToDashboard()">{{'myaccount' | transloco}}</button>
          <button mat-menu-item (click)="logout()">{{'signout' | transloco}}</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <!-- Mobile header -->

  <!-- Loading Till login page -->
  <div style="width:100%;text-align:center;padding: 100px;" *ngIf="!loginDisplay">
    <p> <img width="100px" src="../../../../assets/images/L_RioCan_Logo_Icon_rgb.svg" alt="logo" /></p>
    <p>{{'loading' | transloco}}</p>
  </div>
  <!-- Loading Till login page -->

  <!-- Main Content -->
  <div class="main pt-3 pb-5 sticky-spacer" *ngIf="loginDisplay">
    <!-- Alerts -->
    <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
      {{statusMessage}}
      <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- Alerts -->
    <!-- check if prism access -->
    <!-- <app-company-select></app-company-select> -->
    <!-- Loading spinner -->
    <div *ngIf="loaderService.isLoading | async" class="loader-container">
      <mat-spinner style="top:25%; left:50%" ></mat-spinner>
    </div>
    <!-- Loading spinner -->

    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  <!-- Main Content -->


  <!-- Footer -->
  <app-footer *ngIf="loginDisplay"></app-footer>
  <!-- Footer -->

</mat-drawer-container>
