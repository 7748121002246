import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { protectedResources } from 'src/app/auth-config';
import { Building } from '../models/building';
import { Floor } from '../models/floor';
import { ServiceRequest } from '../models/service-request';
import { Suite } from '../models/suite';
import { User } from '../models/user';
import { UserRequest,VersapayUserRequest } from '../models/user-request';
import { ConstantsService } from './constants.service';
import { ManageStorageService } from './manage-storage.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  readonly usersApiURL = protectedResources.usersApi.endpoint;

  loggedInUser: User = new User();
  impersonatedUser: User = new User();
  updatedUser: Subject<User> = new Subject<User>();
  isAdminUser: boolean = false;

  isImpersonated: Subject<boolean> = new Subject<boolean>();
  onImpersonate: Subject<string> = new Subject<string>();

  allVersapayUsers !: VersapayUserRequest[];
  allVersapayUsersSubject: Subject<VersapayUserRequest[]> = new Subject<VersapayUserRequest[]>();

  allUsers !: UserRequest[];

  allUsersSubject: Subject<UserRequest[]> = new Subject<UserRequest[]>();

  constructor(private http: HttpClient, private constants: ConstantsService,
    private localStorage: ManageStorageService) {
  }

  changeUserDetails(data: User) {
    this.updatedUser.next(data);
  }

  changeIsImpersonated(data: boolean) {
    this.isImpersonated.next(data);
  }

  onImpersonateUser(userEmail: string) {
    this.onImpersonate.next(userEmail);
  }

  private handleError(errorResponce: HttpErrorResponse) {
    if (errorResponce.error instanceof ErrorEvent) {
      console.error("Client side error: ", errorResponce.error.message);
    }
    else {
      console.error("User API: Server side error: ", errorResponce);
    }
    return throwError("There is an error in the User API service");
  }

  getLoggedInUser(email: string | null): Observable<User> {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      email = this.localStorage.getStorage(this.constants.impersonateAs);
    }
    let user = this.usersApiURL + this.constants.getUserByEmail;
    return this.http.get<User>(`${user}/${email}`)
      .pipe(catchError(this.handleError));
  }

  //impersonation
  getImpersonatedUser(email: string | null): Observable<User> {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      email = this.localStorage.getStorage(this.constants.impersonateAs);
    }
    let user = this.usersApiURL + this.constants.getUserByEmail;
    return this.http.get<User>(`${user}/${email}`)
      .pipe(catchError(this.handleError));
  }

  getUsersByAdminEmail(): Observable<UserRequest[]> {
    let user = this.usersApiURL + this.constants.getUsersByAdminEmail;
    let buildingId = "1";
    let adminEmail: string | null;
    adminEmail = this.localStorage.getStorage(this.constants.userEmail);
    return this.http.get<UserRequest[]>(`${user}?adminEmail=${adminEmail}`)
      .pipe(catchError(err => this.handleError(err)));
  }

  getVersapayUsersByAdminEmail(): Observable<VersapayUserRequest[]> {
    let user = this.usersApiURL + this.constants.getVersapayUsersByAdminEmail;
    let adminEmail: string | null;
    adminEmail = this.localStorage.getStorage(this.constants.userEmail);
    return this.http.get<VersapayUserRequest[]>(`${user}?adminEmail=${adminEmail}`)
      .pipe(catchError(err => this.handleError(err)));
  }
  getAllUsersToImpersonate(): Observable<VersapayUserRequest[]> {
    let user = this.usersApiURL + this.constants.getAllUsersToImpersonate;
    return this.http.get<VersapayUserRequest[]>(user)
      .pipe(catchError(err => this.handleError(err)));
  }

  getUserCompaniesByEmail(email:string): Observable<any[]> {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      email = this.localStorage.getStorage(this.constants.impersonateAs) as string;
    }
    let getcompany = this.usersApiURL + this.constants.getUserCompaniesByEmail;
    return this.http.get<any[]>(`${getcompany}?email=${email}`)
      .pipe(catchError(err => this.handleError(err)));
  }


  putUserProfile(form: User) {
    let ServiceRequests = this.usersApiURL + this.constants.updateUserProfile;
    return this.http.post(ServiceRequests, form)
      .pipe(catchError(err => this.handleError(err)));
  }

  putUserDefaultLang(form: User, lang: string):Observable<any> {
    let ServiceRequests = this.usersApiURL + this.constants.updateUserLang+"/"+lang;
    return this.http.post(ServiceRequests, form)
      .pipe(catchError(err => this.handleError(err)));
  }
  //ToDo: need API method
  deleteUser(userId: number | null): Observable<User> {
    let news = this.usersApiURL + this.constants.deleteNews;
    return this.http.delete<User>(news + "/" + userId)
      .pipe(catchError(this.handleError));
  }

  isAdmin(currentUser: User): boolean {
    if(this.localStorage.getStorage(this.constants.isImpersonate)) {
      this.isAdminUser = true;
      return false;
    }
    let isAdmin: boolean = false;
    let tempRole:any = currentUser.roles;
    let admin = tempRole[0].roleName;
    (admin === this.constants.adminUser) ? isAdmin = true : isAdmin = false;
    this.isAdminUser = isAdmin;
    return isAdmin;
  }

  getDefaultLang(currentUser: User): any {
    let itsDefaultLang: any = "en";
    if(currentUser.defaultLang!=null || currentUser.defaultLang!=""){
      itsDefaultLang = currentUser.defaultLang;
    }

    return itsDefaultLang;

  }

  isImpersonate(): boolean{
    this.localStorage.getStorage(this.constants.impersonateAs)
    return true;
  }
}
