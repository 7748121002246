import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ServiceRequest } from 'src/app/models/service-request';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html',
  styleUrls: ['./file-history.component.scss']
})
export class FileHistoryComponent implements OnInit {

  displayedColumns: string[] = ['Date', 'Details', 'AddedBy', 'Status'];
  dataSource !: any[];

  showAddComments: boolean = false;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  isImpersonated: boolean = false;
  frbutton:string = "";

  private _data = new BehaviorSubject<ServiceRequest>(new ServiceRequest);

  // change data to use getter and setter
  @Input()
  set serviceRequest(value: ServiceRequest) {
    this._data.next(value);
  };

  get serviceRequest() {
    return this._data.getValue();
  }

  constructor(private buildingEngineService: BuildingEngineService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private usersService: UsersService,
    private route: Router) {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      this.isImpersonated = true;
    }
    else {
      this.isImpersonated = false;
    }

     //subscribe to service Requests
     this.buildingEngineService.allServiceRequests.subscribe((data: any) => {
      this.dataSource = data.comments!
    })
  }

  commentForm: FormGroup = new FormGroup({
    comment: new FormControl("", Validators.required),
  })

  ngOnInit(): void {
    var userLocalLang = this.localStorage.getStorage("userLang");
    if(userLocalLang=='fr'){
      this.frbutton = 'fr-button';
    }else{
      this.frbutton='';
    }
    this._data
      .subscribe((x: ServiceRequest) => {
        if (x) {
          this.dataSource = x.comments!;
        }
      });

    //remove white space from comment
    /*this.commentForm.get("comment")?.valueChanges
    .subscribe(selectedValue => {
      if(selectedValue.endsWith(' ')){
        this.commentForm.get("comment")?.setValue(selectedValue.trim());
      }
    });*/
  }

  onSubmit() {
    if (this.commentForm.valid) {
      let comment = this.commentForm.value.comment;

      if(this.usersService.loggedInUser.firstName){
        comment = comment + " | " + this.usersService.loggedInUser.firstName + " " + this.usersService.loggedInUser.lastName;
      }

      let requestId = this.serviceRequest.id;
      let commentBody = `{
        "text":"`+ comment + `",
        "is_private":false
        }`

      this.buildingEngineService.postComment(requestId!, commentBody).subscribe(
        res => {
          this.commentForm.reset();
            if(this.localStorage.getStorage('userLang')=='fr'){
              this.statusMessage = "Commentaire ajouté avec succès!!";
            }else{
              this.statusMessage = "Comment added Successfully!!";
            }
            this.isError = false;
            this.showMessage = true;
            this.FadeOutMessage();
        },
        (error: HttpErrorResponse) => {
          console.log(error);

          this.statusMessage = error.statusText;
          this.isError = true;
          this.showMessage = true;
          this.FadeOutMessage();
        }
      )
    }
  }

  clickAddComments() {
    this.toggelCommentsSection();
  }

  clickCancelButton() {
    this.commentForm.reset();
    this.toggelCommentsSection();
  }

  toggelCommentsSection() {
    this.showAddComments = !this.showAddComments;
  }

  FadeOutMessage() {
    setTimeout(() => {
      let requestId = this.serviceRequest.id?.toString()!;
      this.showMessage = false;
      //load service requests
      this.getServiceRequestById(requestId);
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  getServiceRequestById(requestId: string) {
    let building = this.buildingEngineService.getBuildingID();
    this.buildingEngineService.getServiceRequestByID(requestId).subscribe(
      (serviceRequest: ServiceRequest) => {
        const result = Object.entries(serviceRequest);
        let serviceRequests = result[3][1];
        this.dataSource = serviceRequests[0].comments!;
        this.commentForm.reset();
      },
      (err: any) => console.log(err)
    );
  }

}
