import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { SubSink } from 'subsink';

export interface DashboardElement {
  icon: string;
  title: string;
  link: string;
  userType: string;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  isAdmin: boolean = false;
  greeting: string = "GOOD MORNING";

  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  dashboardItems: DashboardElement[] = [];
  dashboardItemsAll: DashboardElement[] = [
    {
      icon: 'account_box',
      title: 'My Profile',
      link: 'myprofile',
      userType: 'all'
    },
    {
      icon: 'medical_services',
      title: 'Service Request',
      link: 'servicerequest',
      userType: 'tenant'
    },
    {
      icon: 'account_balance_wallet',
      title: 'Invoices',
      link: 'Invoices',
      userType: 'versapay'
    },
    {
      icon: 'article',
      title: 'News Articles',
      link: 'newsarticles',
      userType: 'admin'
    },
    {
      icon: 'people',
      title: 'Users',
      link: 'allusers',
      userType: 'admin'
    },

  ]

  constructor(private route: Router,
    private constants: ConstantsService,
    private usersService: UsersService,
    private localStorage: ManageStorageService
  ) {
    this.dashboardItems = this.dashboardItemsAll;
    this.getGreeting();
    this.usersService.isImpersonated.subscribe((data: boolean) => {
      this.ngOnInit();
    });
  }

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  getGreeting() {
    const now = new Date().getHours();
    switch (true) {
      case (now <= 12): this.greeting = "Good Morning"; break;
      case (now > 12 && now < 16): this.greeting = "Good Afternoon"; break;
      case (now >= 16 && now < 20): this.greeting = "Good Evening"; break;
      case (now >= 20 && now <= 24): this.greeting = "Good Night"; break;
    }
  }

  ngOnInit(): void {
    if (this.usersService.loggedInUser.email) {
      this.loggedInUser = this.usersService.loggedInUser;

      this.userRole = this.usersService.loggedInUser.roles;

      const prismAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='tenant'
      })
      const versapayAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='versapay'
      })

      const adminAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='admin'
      })
      if(prismAccess){
        this.hasPrismAccess = true;
      }
      if(versapayAccess){
        this.hasVersapayAccess = true;
      }
      this.isAdmin = !!adminAccess;
      this.dashboardItems = this.loadDashboard(this.usersService.loggedInUser.roles);
    }
    else {
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }
  }

  loadDashboard(userRole:any){

    const result = this.dashboardItemsAll.filter(function(o1:any){
       return userRole.some(function(o2:any){
        return o1.userType === o2.roleName;
      });
    });

    return result;
  }

  getLoggedInUser(email: any): void {
    this.subs.sink = this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        //this.dashboardItems = this.loadDashboardItems();

      this.userRole = data.roles;

      const prismAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='tenant'
      })
      const versapayAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='versapay'
      })
      const adminAccess = this.userRole.some(function(roles:any){
        return roles.roleName==='admin'
      })

      if(prismAccess){
        this.hasPrismAccess = true;
      }
      if(versapayAccess){
        this.hasVersapayAccess = true;
      }
      if(adminAccess){
        this.isAdmin = true;
      }

      //this.dashboardItems = this.loadDashboardItems();
      this.dashboardItems = this.loadDashboard(data.roles);
      });
  }


  navigateToServiceRequest(pageUrl: string) {
    switch (pageUrl) {
      case "servicerequest":
        this.route.navigate([this.constants.serviceRequestUrl]);
        break;
      case "Invoices":
        this.route.navigate([this.constants.invoicesUrl]);
        break;
      case "myprofile":
        this.route.navigate([this.constants.myProfileUrl]);
        break;
      case "newsarticles":
        {

          let userRoles:any = this.usersService.loggedInUser.roles;

          if(userRoles.length>0){
            if (this.localStorage.getStorage(this.constants.userEmail)) {
              let email = this.localStorage.getStorage(this.constants.userEmail);
              this.subs.sink = this.usersService.getLoggedInUser(email)
                .subscribe((data: User) => {
                  this.usersService.loggedInUser = data;
                  this.navigateToNews(this.usersService.loggedInUser);
                });
            }
          }else{
            this.navigateToNews(this.usersService.loggedInUser);
          }
          break;
        }
      case "users":
        this.route.navigate([this.constants.usersUrl]);
        break;
      case "VersapayUsers":
        this.route.navigate([this.constants.versapayUsersUrl]);
        break;
      case "allusers":
          this.route.navigate([this.constants.allusers]);
          break;
      default:
        break;
    }

  }

  navigateToNews(user: User) {
    let userRoles:any = user.roles;
    let admin = userRoles[0].roleName;
    if (admin == this.constants.adminUser) {
      this.route.navigate([this.constants.newsUrl])
    }
    else {
      this.route.navigate([this.constants.tenantnewsUrl])
    }
  }

  navigateToMyProfile() {
    this.route.navigate([this.constants.myProfileUrl])
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
