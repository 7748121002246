import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Form, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ServiceRequest } from 'src/app/models/service-request';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';

@Component({
  selector: 'app-add-files',
  templateUrl: './add-files.component.html',
  styleUrls: ['./add-files.component.scss']
})
export class AddFilesComponent implements OnInit {

  dataSource !: any[];
  showAddFiles: boolean = false;
  selectedImage !: File;
  imageName: string = "";
  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  isImpersonated: boolean = false;
  frbutton:string = "";

  private _data = new BehaviorSubject<ServiceRequest>(new ServiceRequest);

  // change data to use getter and setter
  @Input()
  set serviceRequest(value: ServiceRequest) {
    this._data.next(value);
  };

  get serviceRequest() {
    return this._data.getValue();
  }

  constructor(private buildingEngineService: BuildingEngineService,
    private localStorage: ManageStorageService,
    private constants: ConstantsService) {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      this.isImpersonated = true;
    }
    else {
      this.isImpersonated = false;
    }
  }

  attachmentsForm: FormGroup = new FormGroup({
    title: new FormControl("", Validators.required),
    attachment: new FormControl(null),
  })

  @ViewChild('form') form: NgForm | undefined;

  ngOnInit(): void {

    var userLocalLang = this.localStorage.getStorage("userLang");
    if(userLocalLang=='fr'){
      this.frbutton = 'fr-button';
    }else{
      this.frbutton='';
    }
    this._data
      .subscribe((x: ServiceRequest) => {
        if (x) {
          this.dataSource = x.attachments!;
        }
      });
  }

  onSubmit() {
    if (this.attachmentsForm.valid) {      
      var formData: any = new FormData();
      let title = this.attachmentsForm.value.title;
      formData.append("workOrdersId", this.serviceRequest.id);
      formData.append("title", title);
      formData.append("file", this.selectedImage, this.selectedImage.name);

      this.buildingEngineService.postAttachment(formData).subscribe(
        res => {
          if (res) {
            this.attachmentsForm.reset();
            if(this.localStorage.getStorage('userLang')=='fr'){
              this.statusMessage = "Fichier ajouté avec succès!!";
            }else{
              this.statusMessage = "File added Successfully!!";
            }
            this.isError = false;
            this.showMessage = true;
            this.FadeOutMessage();

          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);

          this.statusMessage = error.statusText;
          this.isError = true;
          this.showMessage = true;
          this.FadeOutMessage();
        }
      )

    }
  }

  getServiceRequestById(requestId: string) {
    let building = this.buildingEngineService.getBuildingID();
    this.buildingEngineService.getServiceRequestByID(requestId).subscribe(
      (serviceRequest: ServiceRequest) => {        
        const result = Object.entries(serviceRequest);
        let serviceRequests = result[3][1];
        this.dataSource = serviceRequests[0].attachments!;
        
        this.buildingEngineService.changeServiceRequests(serviceRequests[0]);

        this.imageName = "";
        this.attachmentsForm.reset();
        this.form?.resetForm()
      },
      (err: any) => console.log(err)
    );
  }

  onFileSelected(event: Event) {
    let file = <HTMLInputElement>event.target;
    if (file.files?.length) {

      this.selectedImage = file.files[0];
      this.imageName = this.selectedImage.name;

      // check file type
      if (this.selectedImage.type != 'image/jpeg' && this.selectedImage.type != 'image/gif' && this.selectedImage.type != 'image/png' && this.selectedImage.type != 'application/pdf' && this.selectedImage.type != 'video/mp4') {
        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = 'Type de fichier à usage restreint';
        }else{
          this.statusMessage = 'Restricted File type';
        }
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }

      // check file size         
      var size = this.selectedImage.size / (1024 * 1024);
      if (size > 25) {
        
        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = 'Taille du fichier supérieure à 25 Mo';
        }else{
          this.statusMessage = 'File is bigger than 25MB';
        }
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }

    }
  }

  clickAddDocuments() {
    this.toggelCommentsSection();
  }

  clickCancelButton() {
    this.attachmentsForm.reset();
    this.imageName = "";
    this.toggelCommentsSection();
  }

  toggelCommentsSection() {
    this.showAddFiles = !this.showAddFiles;
  }

  FadeOutMessage() {
    setTimeout(() => {      
      this.showMessage = false;

      let requestId = this.serviceRequest.id?.toString()!;
      //load service requests
      this.getServiceRequestById(requestId);
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

}
