import { BuildingDialogComponent } from './../building-dialog/building-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { vCompany } from 'src/app/models/company';
import { VersapayService } from 'src/app/services/versapay.service';
import { CompanyDialogComponent } from '../company-dialog/company-dialog.component';

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements OnInit {

 
  selected!: vCompany[];
  selectedCompany !: any;

  get companies(): any {
    return this.versapayService.properties;
  }
  set companies(properties: any) {
    this.versapayService.properties = properties;
  }

  get defaultCompany(): vCompany {
    return this.versapayService.defaultCompany;
  }
  set defaultCompany(company: vCompany) {
    this.versapayService.defaultCompany = company;
  }

  constructor(public dialog: MatDialog,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private versapayService: VersapayService
  ) {    
    this.versapayService.vcompany.subscribe((data: vCompany) => {      
      this.selectedCompany = data.name+"("+data.externalID+")";
    });   
  } 

  // this method is called in drop down click
  getCompanies() {    
    let userEmail:any = "";
    let ImpEmail = this.localStorage.getStorage(this.constants.impersonateAs);
    if(ImpEmail){
      userEmail= ImpEmail;
    }else{
       userEmail = this.localStorage.getStorage(this.constants.userEmail);
    }
    let companyCount = this.localStorage.getStorage(this.constants.companyCnt);

    //if there are more than 1 company give a popup
    if (companyCount != "1") {

      //get users companies
      if (this.companies?.length>0) {        
        this.companies = this.companies;
        this.openVDialog();
      }
      else {
        this.versapayService.getCompanyByUser(userEmail)
          .subscribe((data) => {               
            const result:any = data;       
            this.companies = result;
            this.openVDialog();
          });
      }
    }

  }

  openVDialog(): void { 
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      companies: this.companies
    };

    const dialogRef = this.dialog.open(CompanyDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //set default company
      this.selectedCompany = result[0].name+" ("+result[0].externalID+")";
      this.defaultCompany = result[0];
      this.localStorage.setStorage(this.constants.defaultCompany, JSON.stringify(result[0]));
      this.versapayService.changeCompany(result[0]);     
    });
  }

  ngOnInit(): void {
    let company = this.localStorage.getStorage(this.constants.defaultCompany);
    if (company && company!="undefined") {
      var result = JSON.parse(company || '{}');
      this.selectedCompany = result.name+" ("+result.externalID+")";
    }
  }
}
