import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  isAdmin: boolean = false;
  isImpersonated: boolean = false;

  VersapayView: boolean = false;
  TenantView:boolean = false;
  AdminView:boolean = false;
  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  constructor(private route: Router,
    private constants: ConstantsService,
    private usersService: UsersService,
    private localStorage: ManageStorageService
  ) {
    //check for impersonated
    this.isImpersonated = !!this.localStorage.getStorage(this.constants.isImpersonate);

    if (this.usersService.loggedInUser.email) {
      this.isAdmin = this.usersService.isAdmin(this.usersService.loggedInUser);
    }
    else {
      let email = this.localStorage.getStorage(this.constants.userEmail)
      this.usersService.getLoggedInUser(email).subscribe(
        (data) => {
          this.isAdmin = this.usersService.isAdmin(data);
        }
      );
    }

    this.usersService.isImpersonated.subscribe((data: boolean) => {
      this.isImpersonated = data;
    });
  }

  ngOnInit(): void {
    let userEmail:any = "";
    let ImpEmail = this.localStorage.getStorage(this.constants.impersonateAs);
    if(ImpEmail){
      userEmail= ImpEmail;
      this.isImpersonated = true;
    }else{
       userEmail = this.localStorage.getStorage(this.constants.userEmail);
       this.isImpersonated = false;
    }

    if(userEmail){
      this.getLoggedInUser(userEmail);
    }
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.rework(data);
      });

  }

  rework(data:any){

    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;

  }
  navigateToDashboard() {
    this.route.navigate([this.constants.dashboardUrl])
  }

  navigateToServiceRequest() {
    this.route.navigate([this.constants.serviceRequestUrl])
  }

  navigateToMyProfile() {
    this.route.navigate([this.constants.myProfileUrl])
  }

}
