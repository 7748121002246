export const environment = {
  production: false,
  api:  'https://riocan-dev-apim.azure-api.net/m8dev',
  riocanApi: 'https://pmapi.buildingengines.com/datavail',

  b2cTenantDomain: 'https://riocandev.onmicrosoft.com',
  b2cSignInUserFlowName: 'B2C_1_riocan_ten_dev_signin',
  b2cSignInUserFlowAuthority: 'https://riocandev.b2clogin.com/riocandev.onmicrosoft.com/B2C_1_riocan_ten_dev_signin',
  b2cAuthorityDomain: 'riocandev.b2clogin.com',
  b2cWebAppClientID: 'ecb28bec-9c85-4b32-a25d-1ee213c5f928',

  be_tenx_auth_key: 'ck5GTmlIZzVVN0diSjc1eTNyTmphd1VkeElCVkY0WUc6WEdlMFE1WTlaOUs3NDYzclM2NDhNblo4Qzh6MU1DTFFVNHA1dmIzWDZsUWR4WUpGRWRNU1NVUFlNb09vc0NrdQ==',
  idleTimeInSeconds: 5,
  timeOutTimeInSeconds: 900,

  paymentUrl: "https://uat.versapay.com/auth/saml_sp?partner=riocan",
  blobImagePath: "https://riodevstorage.blob.core.windows.net/newsimages/",
  passwordResetUrl: "https://riocandev.b2clogin.com/riocandev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_riocan_ten_dev_password_reset&client_id=ecb28bec-9c85-4b32-a25d-1ee213c5f928&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fbeta4rctp.ca&scope=openid&response_type=id_token&prompt=login",
  instrumentationKey: '168f3db0-f2d0-423a-b51a-4dfbed3ec6ab'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
