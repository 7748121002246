<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->


  <div class="title-wrapper">

    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="h1">{{'newsarchive' | transloco}}</h1>
    </div>
    <!-- Title -->

    <!-- Search -->    
    <div class="mb-3 filter-wrapper">
      <form class="service-search">
        <input matInput #filter (keyup)="applyFilter(filter.value)" placeholder="Search" class="search-filter">
        
      </form>
    </div>
    <!-- Search -->

  </div>


  <!-- News section -->
  <div class="row">

    <table mat-table [dataSource]="dataSource" matSort class="">
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">
          <mat-card class="news-item p-0 rounded-0 shadow-sm">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <a *ngIf="element.imageFileName" class="news-imag-link" (click)="navigateToNewsArticlePage(element.id!)">
                <img [src]="blobImagePath + element.imageFileName" alt="element.imageFileName"
                  class="img-fluid news-image" />
              </a>
              <span *ngIf="element.featured" [class]="star"></span>
            </div>
            <div class="col-sm-12 col-md-8 p-3">
              <p class="news-date mb-0 pl-3 pl-md-0">{{element.dateCreated | date: 'd MMM y'}}</p>
              <h3 class="news-title pl-3 pl-md-0">{{element.title}}</h3>
              <p class="news-description pr-3 pl-3 pl-md-0"
                [innerHTML]="(element.description && element.description.length>300)? (element.description | slice:0:300)+'..':(element.description)">
              </p>
              <button mat-button class="view-all-news override-view-btn"
                (click)="navigateToNewsArticlePage(element.id!)">{{'readmore' | transloco}}</button>
            </div>
          </div>
        </mat-card>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <!-- News Item -->
    <!--<div class="col-md-12 mt-4" *ngFor="let card of newsItems">
    <mat-card class="news-item p-0 rounded-0 shadow-sm">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <a *ngIf="card.imageFileName" class="news-imag-link" (click)="navigateToNewsArticlePage(card.id!)">
            <img [src]="blobImagePath + card.imageFileName" alt="card.imageFileName" class="img-fluid news-image" />
          </a>
          <span *ngIf="card.featured" [class]="star"></span>
        </div>
        <div class="col-sm-12 col-md-8 p-3">
          <p class="news-date mb-0 pl-3 pl-md-0">{{card.dateCreated | date: 'd MMM y'}}</p>
          <h3 class="news-title pl-3 pl-md-0">{{card.title}}</h3>
          <p class="news-description pr-3 pl-3 pl-md-0" [innerHTML]= "(card.description && card.description.length>300)? (card.description | slice:0:300)+'..':(card.description)">
            
            <button mat-button class="view-all-news override-view-btn" (click)="navigateToNewsArticlePage(card.id!)">{{buttonText}}</button>
          </p>
        </div>
      </div>
    </mat-card>
  </div>-->
    <!-- News Item -->

    <!-- Paginator -->
    <mat-paginator aria-label="" [pageSizeOptions]="[10]" class="mt-3"></mat-paginator>
    <!-- Paginator -->

  </div>
  <!-- News section -->

</div>