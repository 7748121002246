<div class="footer">
    <div class="container footer-content-justify">
      <!-- <div class="footer-logo">
          <img src="../../../../assets/images/footer-logo.svg" alt="logo" class="appbar-logo" />
      </div> -->
      <span class="appbar-spacer"></span>
      <div class="footer-copyrights">
          <p class="mb-0">{{'all_rights_reserved' | transloco}}</p>
      </div>
    </div>
</div>
