import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { News } from 'src/app/models/news';
import { Status } from 'src/app/models/status';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { ConfirmationComponent } from 'src/app/components/shared/confirmation-dialog/confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-news',
  templateUrl: './admin-news.component.html',
  styleUrls: ['./admin-news.component.scss']
})
export class AdminNewsComponent implements OnInit {

  displayedColumns: string[] = ['title', 'type', 'dateCreated', 'status', 'Action'];
  //dataSource = ELEMENT_DATA;
  private dsData: any;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource;

  get allNews(): News[] {
    return this.newsService.allNews;
  }
  set allNews(newsList: News[]) {
    this.newsService.allNews = newsList;
  }

  constructor(private route: Router,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private newsService: NewsService,
    public dialog: MatDialog
  ) {
    this.newsService.allNewsSubject.subscribe((data: any) => {
      this.allNews = data;
      this.LoadData();
    });
  }

  ngOnInit() {
    this.LoadData();
  }

  LoadData() {
    if (this.newsService.allNews) {
      //get get All News
      this.dataSource.data = this.newsService.allNews.filter(a => a.status != Status.delete);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else {
      //get All News with service call
      this.getAllNews();
    }
  }

  getAllNews(): void {
    this.newsService.getAllNews()
      .subscribe(data => {
        this.allNews = data.filter(a => a.status != Status.delete);
        this.dataSource.data = data.filter(a => a.status != Status.delete);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  deleteNews(id: number) {

    const confirmation = this.dialog.open(ConfirmationComponent, {
      data: { text: 'DELETE' }
    });

    confirmation.afterClosed().subscribe(result => {
      if (result === true) {


        this.newsService.deleteNews(id)
          .subscribe(data => {

            // Refresh DataTable to remove row.
            this.deleteRowDataTable(id);


            if(this.localStorage.getStorage('userLang')=='fr'){
              this.statusMessage = "Article de nouvelles supprimé avec succès!!";
            }else{
              this.statusMessage = "News Deleted Successfully!!";
            }
            this.isError = false;
            this.showMessage = true;
            this.FadeOutMessage();

          },
            (err) => {
              this.statusMessage = err;
              this.isError = true;
              this.showMessage = true;
              this.FadeOutMessage();

              console.log(err.error);
              console.log(err.message);
            });

      }
    });




  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private deleteRowDataTable(recordId: number) {
    this.dsData = this.dataSource.data;
    const itemIndex = this.dsData.findIndex((obj: any) => obj["id"] === recordId);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;

    //update news list
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  navigateToNewsArticle() {
    this.route.navigate([this.constants.addNewsUrl])
  }

  previewNews(newsId: string) {
    this.route.navigate([this.constants.previewsUrl, newsId])
  }

  editNews(newsId: string) {
    this.route.navigate([this.constants.editNewsUrl, newsId]);
  }
}
