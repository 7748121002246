import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { RouterModule} from '@angular/router';

import { HomeServiceRequestComponent } from './components/home-service-request/home-service-request.component';
import { NewServiceRequestComponent } from './components/new-service-request/new-service-request.component';
import { ServiceRequestComponent } from './components/service-request/service-request.component';
import { DaysOldPipe } from 'src/app/pipes/days-old.pipe';
import { ServiceRequestStatusPipe } from 'src/app/pipes/service-request-status.pipe';
import { AddFilesComponent } from './components/add-files/add-files.component';
import { FileHistoryComponent } from './components/file-history/file-history.component';
import { SharedModule } from '../shared/shared.module';
import { TranslocoRootModule } from '../../transloco-root.module';


@NgModule({
  declarations: [
    HomeServiceRequestComponent,
    NewServiceRequestComponent,
    ServiceRequestComponent ,
    DaysOldPipe,
    ServiceRequestStatusPipe, AddFilesComponent, FileHistoryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    TranslocoRootModule
  ],
  exports:[
    HomeServiceRequestComponent,
    NewServiceRequestComponent,
    ServiceRequestComponent,
    ReactiveFormsModule,
    DaysOldPipe,
    ServiceRequestStatusPipe,
    AddFilesComponent, FileHistoryComponent
  ]
})
export class ServiceRequestsModule { }
