import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import {RouterModule} from '@angular/router';
import { BreadcrumbComponent } from 'src/app/components/shared/breadcrumb/breadcrumb.component';
import { SideNavComponent } from 'src/app/components/shared/side-nav/side-nav.component';

//import { DaysOldPipe } from 'src/app/pipes/days-old.pipe';
import { OpenInvoiceStatusPipe } from 'src/app/pipes/open-invoices-status.pipe';
import { SharedModule } from '../shared/shared.module';
import { TranslocoRootModule } from '../../transloco-root.module';
import { HomeOpenInvoicesComponent } from './home-open-invoices/home-open-invoices.component';
import { OutstandingInvoicesComponent } from './outstanding-invoices/outstanding-invoices.component';
import { CompanyDialogComponent } from 'src/app/components/shared/company-dialog/company-dialog.component';
import { CompanySelectComponent } from 'src/app/components/shared/company-select/company-select.component';
import { VersapayUsersComponent } from './versapay-users/versapay-users.component';
import {CvDollar} from './../../pipes/vp-dollar-cv.pipe';
import {CvDollarSummary} from './../../pipes/vp-dollarSummary-cv.pipe';
import { SumVal } from "./../../pipes/vp-sum.pipe";
import {StatsComponent} from "./stats/stats.component";

@NgModule({
  declarations: [
    //DaysOldPipe,
    OpenInvoiceStatusPipe,
    HomeOpenInvoicesComponent,
    OutstandingInvoicesComponent,
    CompanyDialogComponent,
    CompanySelectComponent,
    VersapayUsersComponent,
    CvDollar,
    CvDollarSummary,
    SumVal,
    StatsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    TranslocoRootModule
  ],
  exports:[
    ReactiveFormsModule,
    //DaysOldPipe,
    OpenInvoiceStatusPipe,
    HomeOpenInvoicesComponent
  ]
})
export class VersapayModule { }
