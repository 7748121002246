import { Injectable } from '@angular/core';

@Injectable()
export class ConstantsService {

    //local storage keys
    readonly isAuth: string = "isAuth";
    readonly idleTimeInSeconds: string = "idleTimeInSeconds";
    readonly timeOutTimeInSeconds: string = "timeOutTimeInSeconds";
    readonly userEmail: string = "email";
    readonly tenx_token: string = "tenx_token";
    readonly tenx_access_token: string = "tenx_access_token";
    readonly defaultBuilding: string = "defaultBuilding";
    readonly defaultCompany: string = "defaultCompany";
    readonly buildingCnt: string = "buildingCnt";
    readonly companyCnt: string = "companyCnt";
    readonly adminUser: string = "admin";
    readonly tenantUser: string = "tenant";
    readonly isImpersonate: string = "isImpersonate";
    readonly impersonateAs: string = "impersonateAs";
    readonly impersonateUserOrgId: string = "impersonateUserOrgId";
    readonly userOrgId: string = "userOrgId";
    readonly userLang: string = "userLang";

    //service call urls
    readonly getBuildingsByUser: string = "/GetBuildingsByUser";

    //get regions froom Building engine
    readonly getAllBuildingRegions: string = "/GetAllBuildingRegions";

    //get regions froom Database
    readonly getAllRegions: string = "/GetAllRegions";

    readonly login: string = "/login";
    readonly logout: string = "/logout";

    //----------------prism methods---------------------
    readonly getAllBuildings: string = "/GetAllBuildings";
    readonly GetWorkOrderByOrgAndBuilding: string = "/GetWorkOrderByOrgAndBuilding";
    readonly getBuildingsById: string = "/GetUserBuildingWithDetails";
    readonly getIssueTypesByBuilding: string = "/GetIssueTypesByBuilding";
    readonly getCustomerInvoices: string = "/GetCustomerInvoices";
    readonly getSummaryInvoiceById: string = "/GetSummaryInvoiceById";

    readonly createServiceRequest: string = "/PostWorkOrder";
    readonly postComment: string = "/PostWorkOrderComment"
    readonly postAttachment: string = "/PostWorkOrderAttachement"

    //-----------------prism methods-----------------------
    readonly getServiceRequestById: string = "/GetWorkOrderById";


    readonly getServiceRequests: string = "/service-requests/v1/buildings";

    readonly getUserByEmail: string = "/GetUserByEmail";
    readonly updateUserProfile: string = "/UpdateUser";
    readonly updateUserLang: string = "/UpdateUserLang";
    readonly getUsersByAdminEmail: string = "/GetUsersByAdminEmail";
    readonly getVersapayUsersByAdminEmail: string = "/GetVersapayUsersByAdminEmail";
    readonly getAllUsersToImpersonate: string = "/GetAllUsersToImpersonate";
    readonly getUserCompaniesByEmail:string = "/GetUserCompaniesByEmail";

    readonly getNewsById: string = "/GetNewsById";
    readonly addNews: string = "/AddNews";
    readonly updateNews: string = "/UpdateNews";
    readonly getAllNews: string = "/GetAllNews";
    readonly deleteNews: string = "/DeleteNews";
    readonly getAllTenantNewsByTenatuserBEBuldingId: string = "/GetAllTenantNewsByTenatuserBEBuldingId";
    readonly getAllNationalAndRegionalNews:string = "/GetAllNationalAndRegionalNews";
    readonly getNationalAndRegionalNewsById:string = "/GetNationalAndRegionalNewsById";


    readonly updateNewsStatus: string = "/UpdateNewsStatus";

    // navigation urls
    readonly homeUrl: string = "Home";
    readonly dashboardUrl: string = "Home/Dashboard";

    readonly serviceRequestUrl: string = "Home/Dashboard/ServiceRequest";
    readonly invoicesUrl: string = "Home/Dashboard/Invoices";
    readonly myProfileUrl: string = "Home/Dashboard/MyProfile";
    readonly newServiceRequestUrl: string = "Home/Dashboard/ServiceRequest/NewServiceRequest";
    readonly editServiceRequestUrl: string = "Home/Dashboard/ServiceRequest/Edit";
    readonly changePasswordUrl: string = "Home/Dashboard/ChangePassword";
    readonly usersUrl: string = "Home/Dashboard/allusers";
    readonly versapayUsersUrl: string = "Home/Dashboard/VersapayUsers";
    readonly allusers: string = "Home/Dashboard/allusers";

    readonly newsUrl: string = "Home/Dashboard/News";
    readonly addNewsUrl: string = "Home/Dashboard/News/CreateNews";
    readonly previewsUrl: string = "Home/Dashboard/News/PreviewNews";
    readonly editNewsUrl: string = "Home/Dashboard/News/Edit";

    readonly tenantnewsUrl: string = "Home/Dashboard/NewsArchive";
    readonly tenantNewsPreviewsUrl: string = "Home/Dashboard/NewsArchive/PreviewNews";

    // components
    readonly propertyText_Select: string = "Select Unit";
    readonly propertyText_Update: string = "Update Property";
    readonly submitBtnText_Select: string = "Submit";
    readonly submitBtnText_Update: string = "Update";
    readonly uploadImageBtnText_New: string = "Upload Image";
    readonly uploadImageBtnText_Update: string = "Change Image";
    readonly welcomeText: string = "Welcome";
    readonly impersonateAsText: string = "Impersonate AS";

    //versapay
    readonly getCompaniesById: string = "/GetUserCompaniesWithDetails";
    readonly roleTenant = "tenant";
    readonly roleVersapay = "versapay";
    readonly roleAdmin = "admin";

}
