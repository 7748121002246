import { Region } from "./region";
import { Status } from "./status";

export class News {
    id ?:  number;
    buildingId:  number[]| undefined;
    title: string| undefined;    
    description: string| undefined;
    type: string| undefined;
    status: Status| undefined;
    startDate: Date| undefined;
    endDate: Date| undefined;
    featured: boolean| undefined;
    userCreated: string| undefined;
    userModified: number| undefined;
    dateCreated: Date| undefined;
    dateModified: Date| undefined;
    
    regionId: number[]| undefined;
    NewsRegions : Region[]| undefined;    
    imageFileName: string| undefined;
    videoLink: string| undefined;
  }  