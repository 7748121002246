import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { ServiceRequestStatus } from 'src/app/models/service-request-status';
import { Building } from 'src/app/models/building';

@Component({
  selector: 'app-home-service-request',
  templateUrl: './home-service-request.component.html',
  styleUrls: ['./home-service-request.component.scss']
})
export class HomeServiceRequestComponent implements OnInit {

  showServiceRequest: boolean = false;
  serviceRequestsStatus!: ServiceRequestStatus;
  displayedColumns: string[] = ['description', 'id', 'status', 'daysOld', 'name'];
  dataSource!: any;
  //retrieveDataResolver: any;
  defaultBuilding!: any;

  get allServiceRequests(): any {
    return this.buildingEngineService.serviceRequests;
  }
  set allServiceRequests(serviceRequests: any) {
    this.buildingEngineService.serviceRequests = serviceRequests;
  }

  get selectedBuilding(): any {
    return this.buildingEngineService.defaultBuilding;
  }
  set selectedBuilding(building: any) {
    this.buildingEngineService.defaultBuilding = building;
  }

  constructor(private route: Router,
    private buildingEngineService: BuildingEngineService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService
  ) {

    this.serviceRequestsStatus = { newCount: 0, inProgressCount: 0, completedCount: 0 };
    //subscribe to default building
    this.buildingEngineService.building.subscribe((data: Building) => {
      this.defaultBuilding = data.name;
      this.getServiceRequests();
    })
    //subscribe to service Requests
    this.buildingEngineService.allServiceRequests.subscribe((data: any) => {
      this.allServiceRequests = data;
      this.LoadData();
    })

    if (this.selectedBuilding) {
      this.defaultBuilding = this.selectedBuilding.name;
    }
    else {
      let building = this.localStorage.getStorage(this.constants.defaultBuilding);
      if (building != null || building != undefined) {
        var result = JSON.parse(building || '{}');
        if (this.defaultBuilding == null) {
          this.defaultBuilding = result.name;
        }
      }
    }
  }

  ngOnInit(): void {
    this.LoadData();
  }

  LoadData() {
    if (this.buildingEngineService.serviceRequests) {
      //get service requests
      this.allServiceRequests = this.buildingEngineService.serviceRequests;

      // get counts
      this.getServiceRequestsCounts(this.buildingEngineService.serviceRequests);

      //get latest 3 requests
      if (this.allServiceRequests) {
        this.getLatestServiceRequests(this.allServiceRequests);
      }
    }
    else {
      //get service requests with service call
      this.getServiceRequests();
    }
  }

  getServiceRequests(): void {
    let defaultBuilding = "";
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);

    if (building) {
      var result = JSON.parse(building || '{}');
      defaultBuilding = result.id;
      if (this.defaultBuilding == null) {
        this.defaultBuilding = result.name;
      }

      this.buildingEngineService.getServiceRequests(defaultBuilding)
        .subscribe(data => {
          const result = Object.entries(data);

          //let serviceRequests = result[3][1].filter((req:any) => req.id == defaultBuilding);
          if (result[3][1].length > 0) {

            // get counts
            this.getServiceRequestsCounts(result[3][1]);
            //set to building engine property
            this.allServiceRequests = result[3][1];
            //get latest 3 service requests
            this.getLatestServiceRequests(result[3][1]);
          }
        });
    }
  }

  getLatestServiceRequests(requests: any) {
    //sort the list
    let sortedList = requests.sort(
      (a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime()
    )
    //get latest 3
    this.dataSource = sortedList.slice(0, 3);
    this.showServiceRequest = true;
    this.buildingEngineService.changeShowServiceRequests(true);
  }

  getServiceRequestsCounts(serviceRequests: any[]) {
    let count = new ServiceRequestStatus;
    count.newCount = 0;
    count.inProgressCount = 0;
    count.completedCount = 0;

    if (serviceRequests.filter(req => req.status == "new")?.length > 0) {
      count.newCount = count.newCount! + serviceRequests.filter(req => req.status == "new")?.length!;
    }
    if (serviceRequests.filter(req => req.status == "open")?.length > 0) {
      count.inProgressCount = count.inProgressCount! + serviceRequests.filter(req => req.status == "open")?.length;
    }
    if (serviceRequests.filter(req => req.status == "onhold")?.length > 0) {
      count.inProgressCount = count.inProgressCount! + serviceRequests.filter(req => req.status == "onhold")?.length;
    }

    if (serviceRequests.filter(req => req.status == "completed")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "completed")?.length;
    }
    if (serviceRequests.filter(req => req.status == "closed")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "closed")?.length;
    }
    if (serviceRequests.filter(req => req.status == "cancelled")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "cancelled")?.length;
    }

    //count.newCount = serviceRequests.filter(req => req.status == "new")?.length;
    //count.inProgressCount = serviceRequests.filter(req => req.status == "open" && req.status == "onhold")?.length;
    //count.completedCount = serviceRequests.filter(req => req.status == "completed" && req.status == "closed" && req.status == "canceled")?.length;
    this.buildingEngineService.serviceRequestsStatus = count;
    this.serviceRequestsStatus = count;
  }

  navigateToNewServiceRequest() {
    this.route.navigate([this.constants.newServiceRequestUrl])
  }

  navigateToServiceRequest() {
    this.route.navigate([this.constants.serviceRequestUrl])
  }

  editServiceRequest(requestId: string) {
    this.route.navigate([this.constants.editServiceRequestUrl, requestId])
  }
}
