<mat-card class="rounded-0 pl-4 pr-4 shadow-sm mt-5">
  
  <!-- Alerts -->
  <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
    {{statusMessage}}
    <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Alerts -->

  <!-- Title and button wrapper -->
  <div class="row justify-content-between align-items-center mb-4">
    <div class="col-md-4">
      <!-- Title -->
      <h3 class="mb-0">{{'files' | transloco}}</h3>
      <!-- Title -->
    </div>
    <div class="col-md-4 text-right">
      <!-- Add document button -->
      <button mat-raised-button color="accent" class="Add-button" [hidden]="showAddFiles"
        (click)="clickAddDocuments()" [disabled]="isImpersonated">{{'add_documents' | transloco}}</button>
      <!-- Add document button -->
    </div>
  </div>
  <!-- Title and button wrapper -->

  <!-- Upload section -->
  <div class="upload-document-wrapper mb-5 p-3" [hidden]="!showAddFiles">
    <form [formGroup]="attachmentsForm" #form="ngForm">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <!-- Title -->
              <mat-form-field appearance="fill" class="w-100 mb-2">
                <mat-label>{{'title' | transloco}}</mat-label>
                <input matInput formControlName="title">
                <mat-error class="form-error" *ngIf="attachmentsForm.get('title')?.errors && attachmentsForm.get('title')?.hasError('required') || attachmentsForm.get('title')?.touched">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Title -->
              
              <!-- Browse Filesbutton -->
              <button mat-raised-button color="accent" class="Add-button" (click)="fileUpload.click()">{{'browse_file' | transloco}}</button>
              <span class="upload-img-link col-md-9">{{imageName}}</span>
              <input formControlName="attachment" type="file" #fileUpload (change)="onFileSelected($event)"
                style="display:none" accept="image/x-png,image/gif,image/jpeg, .pdf, .mp4 " />
              <!-- Browse Filesbutton -->
            </div>
          </div>
        </div>

        <!-- Submit/Cancel buttons -->
        <div class="col-md-12 mt-3">
          <button mat-raised-button class="submit-small-button btn-w-100 shadow-none mr-2 {{frbutton}}" [disabled]="!attachmentsForm.dirty"  (click)="onSubmit()">{{'submit' | transloco}}</button>
          <button mat-raised-button class="cancel-small-button btn-w-100 shadow-none"
            (click)="clickCancelButton()">{{'cancel' | transloco}}</button>
        </div>
        <!-- Submit/Cancel buttons -->
      </div>
    </form>
  </div>
  <!-- Upload section -->

  <!-- Upload image link -->
  <div class="upload-image-wrapper mb-3" *ngFor="let image of dataSource">
    <a href="{{image.url}}" target="_blank" class="upload-img-link col-md-4">{{image.filename}}</a>
  </div>
  <!-- Upload image link -->
</mat-card>