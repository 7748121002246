<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'myprofile' | transloco}}</h1>
    </div>
    <!-- Title -->
  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-4 col-lg-3">

      <!-- Side Nav -->
      <app-side-nav></app-side-nav>
      <!-- Side Nav -->

    </div>
    <!-- Left Column -->

    <!-- Right Column -->

    <div class="col-md-8 col-lg-9 mt-3 mt-md-0">
      <!-- Alerts -->
      <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
        {{statusMessage}}
        <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Alerts -->

      <form [formGroup]="UserProfileForm" (submit)="onSubmit()">
        <mat-card class="rounded-0 pl-4 pr-4 shadow-sm">

          <div class="row mt-4 mb-5">

            <!-- Change Password -->
            <div class="col-md-12 mb-3 text-right" *ngIf="!isImpersonated">
              <button mat-button type="button" class="change-pwd-btn" (click)="navigateToChangePassword()">{{'changepassword' | transloco}}</button>
            </div>
            <!-- Change Password -->

            <!-- Email -->
            <div class="col-md-12">
              <mat-form-field class="example-full-width" appearance="fill" class="w-100">
                <mat-label>{{'email' | transloco}}</mat-label>
                <input type="email" [attr.disabled]="null" [readonly]="true" matInput formControlName="email"
                  placeholder="Ex. pat@example.com">
              </mat-form-field>
            </div>
            <!-- Email -->

            <!-- First Name -->
            <div class="col-md-6">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'firstname' | transloco}}</mat-label>
                <input matInput formControlName="firstName">
                <mat-error *ngIf="UserProfileForm.controls.firstName.errors?.required">
                  {{'firstnameis' | transloco}} <strong>{{'required' | transloco}}</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- First Name -->

            <!-- Last Name -->
            <div class="col-md-6">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'lastname' | transloco}}</mat-label>
                <input matInput formControlName="lastName">
                <mat-error *ngIf="UserProfileForm.controls.lastName.errors?.required">
                  {{'lastnameis' | transloco}} <strong>{{'required' | transloco}}</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Last Name -->

            <!-- Phone -->
            <div class="col-md-6">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'phone' | transloco}}</mat-label>
                <input matInput formControlName="phone">
                <mat-error *ngIf="UserProfileForm.controls.phone.errors?.pattern">
                  {{'please_enter_valid_phone_number' | transloco}}
                </mat-error>
                <mat-error *ngIf="UserProfileForm.controls.phone.errors?.required">
                  {{'phoneis' | transloco}} <strong>{{'required' | transloco}}</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Phone -->

            <!-- My Properties -->
            <div class="col-md-12 mt-5" *ngIf="!isAdmin && hasVersapayAccess">
              <h3>{{'myleases' | transloco}}</h3>
              <div *ngFor="let company of userCompanies">
                <div class="row mt-2 p-3 ml-1 mr-1 select-property-wrapper" *ngIf="company.externalSystem == 'versapay'">
                  <div class="col-md-9">
                    <h3 class="property-location">{{company.name}} ({{company.externalID}})</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5" *ngIf="!isAdmin && hasPrismAccess">
              <h3>{{'myproperties' | transloco}}</h3>
              <div *ngFor="let property of propertySuite">
                <div class="row mt-2 p-3 ml-1 mr-1 select-property-wrapper">
                  <div class="col-md-9">
                    <h3 class="property-location">{{property.buildingName}}</h3>
                    <p class="property-name">{{property.company}}</p>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <p class="property-floor">{{property.floorName}}</p>
                      <div>
                        <p class="property-suite">{{'suite' | transloco}} {{property.suiteName}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- My Properties -->

            <!-- My Buildings -->
            <div class="col-md-12 mt-5" *ngIf="isAdmin">
              <h3>{{'mybuildings' | transloco}}</h3>
              <div *ngFor="let property of properties">
                <div class="row mt-2 p-3 ml-1 mr-1 select-property-wrapper">
                  <div class="col-md-9">
                    <h3 class="property-location">{{property.name}}</h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- My Buildings -->

            <!-- Submit/Cancel buttons -->
            <div class="col-md-12 mt-5" *ngIf="!isImpersonated">
              <button mat-raised-button class="cancel-button shadow-none" (click)="CancelButtonClick()">{{'cancel' | transloco}}</button>
              <button type="submit" [disabled]="UserProfileForm.invalid" mat-raised-button
                class="submit-button shadow-none">{{'submit' | transloco}}</button>
            </div>
            <!-- Submit/Cancel buttons -->


          </div>
        </mat-card>
      </form>
    </div>
    <!-- Right Column -->
  </div>
</div>
