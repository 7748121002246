import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

import { AdminNewsArticleComponent } from './admin-news-article/admin-news-article.component';
import { AdminNewsPreviewComponent } from './admin-news-preview/admin-news-preview.component';
import { AdminNewsComponent } from './admin-news/admin-news.component';
import { NewsArchiveComponent } from './news-archive/news-archive.component';
import { QuillModule } from 'ngx-quill';
import { BlobserviceService } from 'src/app/blobservice.service';
import { VideoSafePipe } from 'src/app/pipes/video-safe-url.pipe';
import { TranslocoRootModule } from '../../transloco-root.module';

@NgModule({
  declarations: [
    AdminNewsArticleComponent,
    AdminNewsPreviewComponent,
    AdminNewsComponent,
    NewsArchiveComponent,
    VideoSafePipe
  ],
  imports: [
    MaterialModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    QuillModule,
    TranslocoRootModule
  ],
  providers: [
    BlobserviceService
  ]
})
export class NewsModule { }
