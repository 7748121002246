export class User {
  id: number | undefined;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  role?: string;
  isFirstLogin: boolean | undefined;
  isActive: boolean | undefined;
  prismUserId?: string;
  prismCompanyId?: string;
  companyName?: string;
  defaultLang?:string;
  roles?:string[];
}

export class VersapayUser {
  id: number | undefined;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  role?: string;
  isFirstLogin: boolean | undefined;
  isActive: boolean | undefined;
  prismUserId?: string;
  prismCompanyId?: string;
  companyName?: string;
  defaultLang?:string;
  roles?:string[];
}