import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BreadcrumbModule } from 'angular-crumbs';
import { MsalGuardConfiguration, MsalModule, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';

import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { ConstantsService } from './services/constants.service';
import { ManageStorageService } from './services/manage-storage.service';
import { MaterialModule } from './modules/material/material.module';
import { AppRoutingModule } from './app-routing.module';

import { ServiceRequestsModule } from './modules/service-requests/service-requests.module';
import { NewsModule } from './modules/news/news.module';

import { AppComponent } from './app.component';
import { LayoutComponent } from './components/shared/layout/layout/layout.component';
import { HeaderComponent } from './components/shared/header/header/header.component';
import { FooterComponent } from './components/shared/footer/footer/footer.component';
import { HomeComponent } from './components/pages/home/home/home.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found/page-not-found.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PropertyDialogComponent } from './components/shared/property-dialog/property-dialog.component';
import { UsersService } from './services/users.service';
import { BuildingEngineService } from './services/building-engine.service';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorLoaderService } from './services/interceptor-loader.service';
import { SharedModule } from './modules/shared/shared.module';
import { UserAuthGuard } from './services/auth-guard.service';
import { InterceptorGlobalErrorService } from './services/interceptor-GlobalError.service';
import { QuillModule } from 'ngx-quill';
import { ConfirmationComponent } from './components/shared/confirmation-dialog/confirmation.component';
import { TranslocoRootModule } from './transloco-root.module';
import { VersapayModule } from "./modules/versapay/versapay.module";
import { AllusersComponent } from './components/pages/dashboard/allusers/allusers.component';
import {ErrorHandlerService} from "./services/error-handler.service";


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.serviceRequestApi.endpoint, protectedResources.serviceRequestApi.scopes);
  protectedResourceMap.set(protectedResources.usersApi.endpoint, protectedResources.usersApi.scopes);
  protectedResourceMap.set(protectedResources.buildingEngineApi.endpoint, protectedResources.buildingEngineApi.scopes);
  protectedResourceMap.set(protectedResources.newsApi.endpoint, protectedResources.newsApi.scopes);
  protectedResourceMap.set(protectedResources.buildingApi.endpoint, protectedResources.buildingApi.scopes);
  protectedResourceMap.set(protectedResources.regionsApi.endpoint, protectedResources.regionsApi.scopes);
  protectedResourceMap.set(protectedResources.prismApi.endpoint, protectedResources.prismApi.scopes);
  protectedResourceMap.set(protectedResources.versapayApi.endpoint, protectedResources.versapayApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    HomeComponent,
    DashboardComponent,
    MyProfileComponent,
    PageNotFoundComponent,
    PropertyDialogComponent,
    ChangePasswordComponent,
    ConfirmationComponent,
    AllusersComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    BreadcrumbModule,
    BrowserModule,
    NewsModule,
    SharedModule,
    MsalModule,
    BrowserAnimationsModule,
    ServiceRequestsModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    QuillModule.forRoot(),
    HttpClientModule,
    TranslocoRootModule,
    VersapayModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorGlobalErrorService,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConstantsService,
    ManageStorageService,
    UsersService,
    BuildingEngineService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorLoaderService,
      multi: true
    },
    UserAuthGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
