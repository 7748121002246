import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from 'src/app/components/shared/side-nav/side-nav.component';
import { BreadcrumbComponent } from 'src/app/components/shared/breadcrumb/breadcrumb.component';
import { HomeNewsComponent } from '../news/home-news/home-news.component';
import { TranslocoRootModule } from '../../transloco-root.module';
import {BuildingDialogComponent} from "../../components/shared/building-dialog/building-dialog.component";
import {BuildingSelectComponent} from "../../components/shared/building-select/building-select.component";

@NgModule({
  declarations: [
    SideNavComponent,
    BreadcrumbComponent,
    BuildingDialogComponent,
    BuildingSelectComponent,
    HomeNewsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslocoRootModule
  ],
  exports:[
    BreadcrumbComponent,
    SideNavComponent,
    BuildingDialogComponent,
    BuildingSelectComponent,
    HomeNewsComponent
  ]
})
export class SharedModule { }
