import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ManageStorageService {

    constructor() { }

    setStorage(key: any, value : any) {
        try {
            localStorage.setItem(key, value);            
        } catch(e) {
            console.log('setStorage: Error setting key ['+ key + '] in localStorage: ' + JSON.stringify(e) );
            return false;
        }
        return true;
    }

    removeStorage(name : any) {
        try {
            localStorage.removeItem(name);           
        } catch(e) {
            console.log('removeStorage: Error removing key ['+ name + '] from localStorage: ' + JSON.stringify(e) );
            return false;
        }
        return true;
    }

    getStorage(key : any) {
        try {
            var value = localStorage.getItem(key);
            return value;
        } catch(e) {
            console.log('getStorage: Error reading key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
            return null;
        }
      }
}