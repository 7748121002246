import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BuildingEngineService } from './building-engine.service';
import { ConstantsService } from './constants.service';
import { ManageStorageService } from './manage-storage.service';
import { AuthService } from './rc-auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorGlobalErrorService implements HttpInterceptor {

  constructor(private buildingEngineService: BuildingEngineService,
    private constants: ConstantsService,
    private localStorage: ManageStorageService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            if(error.status == 401){
              //login back to building engin API
              let email = this.localStorage.getStorage(this.constants.userEmail) as string;;
              this.buildingEngineService.loginToBuildingEngine(email).subscribe(res => {
                //set the access token in local storage
                this.localStorage.removeStorage(this.constants.tenx_token);
                this.localStorage.setStorage(this.constants.tenx_access_token, res.accessToken);

              });
            }
          }
          return throwError(error);
        })
      )
  }
}
