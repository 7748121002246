import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Building } from 'src/app/models/building';
import { ServiceRequest } from 'src/app/models/service-request';
import { ServiceRequestStatus } from 'src/app/models/service-request-status';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss']
})

export class ServiceRequestComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  isImpersonate: boolean = false;

  displayedColumns: string[] = ['description', 'id', 'status', 'daysOld', 'space.name' ];
  dataSource !: MatTableDataSource<any>;

  get allServiceRequests(): any {
    return this.buildingEngineService.serviceRequests;
  }
  set allServiceRequests(serviceRequests: any) {
    this.buildingEngineService.serviceRequests = serviceRequests;
  }

  serviceRequestsStatus!: ServiceRequestStatus;

  constructor(private route: Router,
    private constants: ConstantsService,
    private buildingEngineService: BuildingEngineService,
    private localStorage: ManageStorageService
  ) {
    this.buildingEngineService.building.subscribe((data: Building) => {
      this.getServiceRequests();
    });
  }

  ngOnInit() {
    this.isImpersonate = !!this.localStorage.getStorage(this.constants.isImpersonate);
    this.LoadData();
  }

  LoadData() {
    let data: any;
    if (this.allServiceRequests) {
      //get service requests
      data = this.allServiceRequests;
      this.dataSource = new MatTableDataSource(data)
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'space.name': return item.space.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      // get service request count
      if (this.buildingEngineService.serviceRequestsStatus) {
        this.serviceRequestsStatus = this.buildingEngineService.serviceRequestsStatus;
      }
      else {
        this.getServiceRequestsCounts(this.buildingEngineService.serviceRequests);
      }
    }
    else {
      this.getServiceRequests();
    }
  }

  getServiceRequests(): void {
    let defaultBuilding = "";
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      var result = JSON.parse(building || '{}');
      defaultBuilding = result.id;
    }

    this.buildingEngineService.getServiceRequests(defaultBuilding)
      .subscribe(data => {
        const result = Object.entries(data);

        this.allServiceRequests = result[3][1];
        this.dataSource = new MatTableDataSource(this.allServiceRequests)

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'space.name': return item.space.name;
            default: return item[property];
          }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        // get service request count
        this.getServiceRequestsCounts(this.allServiceRequests);
      });
  }

  getServiceRequestsCounts(serviceRequests: any[]) {
    let count = new ServiceRequestStatus;
    count.newCount = 0;
    count.inProgressCount = 0;
    count.completedCount = 0;

    if (serviceRequests.filter(req => req.status == "new")?.length > 0) {
      count.newCount = count.newCount! + serviceRequests.filter(req => req.status == "new")?.length!;
    }
    if (serviceRequests.filter(req => req.status == "open")?.length > 0) {
      count.inProgressCount = count.inProgressCount! + serviceRequests.filter(req => req.status == "open")?.length;
    }
    if (serviceRequests.filter(req => req.status == "onhold")?.length > 0) {
      count.inProgressCount = count.inProgressCount! + serviceRequests.filter(req => req.status == "onhold")?.length;
    }

    if (serviceRequests.filter(req => req.status == "completed")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "completed")?.length;
    }
    if (serviceRequests.filter(req => req.status == "closed")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "closed")?.length;
    }
    if (serviceRequests.filter(req => req.status == "cancelled")?.length > 0) {
      count.completedCount = count.completedCount! + serviceRequests.filter(req => req.status == "cancelled")?.length;
    }

    this.buildingEngineService.serviceRequestsStatus = count;
    this.serviceRequestsStatus = count;
  }

  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  navigateToNewServiceRequest() {
    this.route.navigate([this.constants.newServiceRequestUrl])
  }

  editServiceRequest(requestId: string) {
    this.route.navigate([this.constants.editServiceRequestUrl, requestId])
  }

}
