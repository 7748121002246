import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sumval' })

export class SumVal implements PipeTransform {


    transform(value: string, value2:string, value3:string, value4:string): string {
        let SumResult: number;
        let numValue:number = 0.0;
        let numValue2:number = 0.0;
        let numValue3:number = 0.0;
        let numValue4:number = 0.0;
        if(typeof(value)==='string'){
           numValue = Number(value.replace(/\$|,/g, ''));
        }
        else{
            numValue = value;
        }
        if(typeof(value2)==='string'){
            numValue2 = Number(value2.replace(/\$|,/g, ''));
         }
         else{
             numValue2 = value2;
         }
         if(typeof(value3)==='string'){
            numValue3 = Number(value3.replace(/\$|,/g, ''));
         }
         else{
             numValue3 = value3;
         }
         if(typeof(value4)==='string'){
            numValue4 = Number(value4.replace(/\$|,/g, ''));
         }
         else{
             numValue4 = value4;
         }

         console.log(numValue + " " + numValue2 + " " + numValue3 + " " + numValue4);
        return '$'+(this.GetValidNumber(numValue) +
                    this.GetValidNumber(numValue2) +
                    this.GetValidNumber(numValue3) +
                    this.GetValidNumber(numValue4)).toLocaleString('en-US', {
            minimumFractionDigits:2,
            maximumFractionDigits:2
        });
    }

    GetValidNumber(value:any):number{
        if(isNaN(Number(value))){
           return 0
        }
        return value;
    }
}
