import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'daysOld' })

export class DaysOldPipe implements PipeTransform {

  transform(value: Date): number {    
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    let currentday: Date = new Date();
    let createdDate: Date = new Date(value);
    let timeInMilisec: number = currentday.getTime() - createdDate.getTime();
    return Math.ceil(timeInMilisec / _MS_PER_DAY);
  }
}