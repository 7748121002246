import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { VersapayService } from 'src/app/services/versapay.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { vCompany } from 'src/app/models/company';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outstanding-invoices',
  templateUrl: './outstanding-invoices.component.html',
  styleUrls: ['./outstanding-invoices.component.scss']
})
export class OutstandingInvoicesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['display_number', 'currency', 'amount_cents', 'owing_cents' ];
  dataSource !: MatTableDataSource<any>;
  openInvoicesData:boolean = false;

  defaultCompany!: any;
  companyData:boolean = false;
  selectedCompanyName:string ="";

  get allOpenInvoiceRequests(): any {
    return this.versapayService.openInvoiceRequests;
  }
  set allOpenInvoiceRequests(serviceRequests: any) {
    this.versapayService.openInvoiceRequests = serviceRequests;
  }

  //companies
  get companies(): any {
    return this.versapayService.properties;
  }
  set companies(properties: any) {
    this.versapayService.properties = properties;
  }
  //default company
  get defaultCompanies(): vCompany {
    return this.versapayService.defaultCompany;
  }
  set defaultCompanies(company: vCompany) {
    this.versapayService.defaultCompany = company;
  }

  get allSummaryInvoiceRequests(): any {
    return this.versapayService.allSummaryInvoiceRequests;
  }
  set allSummaryInvoiceRequests(openInvoiceRequests: any) {
    this.versapayService.allSummaryInvoiceRequests = openInvoiceRequests;
  }

  //serviceRequestsStatus!: ServiceRequestStatus;

  constructor(private route: Router,
    private constants: ConstantsService,
    private versapayService: VersapayService,
    private localStorage: ManageStorageService,
    public dialog2: MatDialog,
  ) {
    this.versapayService.vcompany.subscribe((data: vCompany) => {
      this.getopenInvoiceRequestsList();
      this.LoadSummary();
    });

  }

  ngOnInit() {
    this.LoadData();
    this.LoadSummary();
  }

  LoadSummary(){
    if (this.versapayService.summaryInvoices) {
      this.companyData = true;
      this.allSummaryInvoiceRequests = this.versapayService.summaryInvoices;
    }
    else {
      this.getOpenInvoiceRequests();
    }
  }
  LoadData() {
    let data: any;
    if (this.allOpenInvoiceRequests) {
      //get service requests
      if(this.allOpenInvoiceRequests.length>0){
        this.openInvoicesData = true;
      }else{
        this.openInvoicesData = false;
      }
      data = this.allOpenInvoiceRequests;
      this.dataSource = new MatTableDataSource(data)
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'space.name': return item.space.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.getopenInvoiceRequestsList();
    }
  }

  makePayment(){
    var paymentLink = environment.paymentUrl + "&RelayState=https";
    window.open(paymentLink);
  }

  getopenInvoiceRequestsList(): void {
    // get default building
    let defaultCompany = "";
    let company = this.localStorage.getStorage(this.constants.defaultCompany);
    if (company) {
      var result = JSON.parse(company || '{}');
      defaultCompany = result.externalID;
      this.selectedCompanyName = result.name;

    }

    this.versapayService.getAllInvoices(defaultCompany)
      .subscribe(data => {
        //const result = Object.entries(data);
        let result:any = data;
        this.companyData = true;
        this.allOpenInvoiceRequests = result.open_invoices;
        if(this.allOpenInvoiceRequests.length>0){
          this.openInvoicesData = true;
        }
        else{
          this.openInvoicesData = false;
        }
        this.dataSource = new MatTableDataSource(this.allOpenInvoiceRequests)

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'space.name': return item.space.name;
            default: return item[property];
          }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  getOpenInvoiceRequests(): void {
    let defaultCompany = "";
    let company = this.localStorage.getStorage(this.constants.defaultCompany);

    if (company) {

      var result = JSON.parse(company || '{}');
      defaultCompany = result.externalID;
      this.selectedCompanyName = result.name;
      if (this.defaultCompany == null) {
        this.defaultCompany = result.name;
      }

      this.versapayService.getSummaryInvoices(defaultCompany)
        .subscribe(data => {
          const result:any = data;

          if (result) {
            this.companyData = true;
            this.allSummaryInvoiceRequests = result;
          }
        });
    }else{
      this.allSummaryInvoiceRequests = {};
    }
  }
  ngAfterViewInit() {
    if(this.dataSource){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  navigateToNewServiceRequest() {
    this.route.navigate([this.constants.newServiceRequestUrl])
  }

  editServiceRequest(requestId: string) {
    this.route.navigate([this.constants.editServiceRequestUrl, requestId])
  }

}
