<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Dashboard title -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0" *ngIf="isAdmin">{{'admindashboard' | transloco}}</h1>
      <h1 class="mat-headline mat-display-1 mb-0" *ngIf="!isAdmin">{{'dashboard' | transloco}}</h1>
    </div>
    <!-- Title -->
  </div>
  <!-- Dashboard titles -->

  <!-- Content card -->
  <mat-card class="mt-3 dshboard-content-card shadow-sm">

    <div class="card-content mb-1">
      <h2 class="mb-0 text-uppercase">{{greeting | transloco}} &nbsp; {{loggedInUser?.firstName}} {{loggedInUser?.lastName}} ! </h2>

      <button mat-raised-button class="edit-button mat-elevation-z0">
        <mat-icon (click)="navigateToMyProfile()">edit</mat-icon>
      </button>
    </div>

    <div class="card-desciption">
      <p class="mb-2"><span class="extension font-weight-bold">E. </span><span> {{loggedInUser?.email}}</span></p>
      <div class="mobile-description-wrapper">
        <p class="mb-0 d-inline-block mr-4"><span class="extension font-weight-bold">T. </span><span>{{loggedInUser?.phone}}</span></p>
        <!--<p class="mb-0 d-inline-block"><span class="extension font-weight-bold">Ex. </span><span>567</span></p>-->
      </div>
    </div>

  </mat-card>
  <!-- Content card -->

  <!-- Dashbord pages -->
  <div class="dashboard-pages-wrapper mt-5">
    <div class="row">
      <div class="col-sm-4 col-md-3 col-lg-2 mt-3 mt-md-0">
        <button mat-button class="dashboard-page-button p-0" (click)="navigateToServiceRequest('myprofile')">
          <mat-card class="dashboard-page-card shadow-sm">
            <span class="dashboard-page-icon">
              <mat-icon class="material-icons-outlined dashboard-icons"> account_box </mat-icon>
            </span>
            <p class="mb-0 dashboard-page-text">  {{'My Profile' | transloco}} </p>
          </mat-card>
        </button>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-2 mt-3 mt-md-0" *ngFor="let card of dashboardItems">
        <button mat-button class="dashboard-page-button p-0" (click)="navigateToServiceRequest(card.link)">
          <mat-card class="dashboard-page-card shadow-sm">
            <span class="dashboard-page-icon">
              <mat-icon class="material-icons-outlined dashboard-icons"> {{card.icon}} </mat-icon>
            </span>
            <p class="mb-0 dashboard-page-text">  {{card.title | transloco}} </p>
          </mat-card>
        </button>
      </div>
    </div>
  </div>
  <!-- Dashbord pages -->

</div>


