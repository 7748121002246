import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlobserviceService {

  thumbnailFetchUrl: string = "https://riodevstorage.blob.core.windows.net";

  constructor(private httpClient: HttpClient) { }

  getBlobImage(url: string): Observable<Blob> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'Ocp-Apim-Subscription-Key': 'change this'
    });

    return this.httpClient.post<Blob>(this.thumbnailFetchUrl,
      {
        "url": url
      }, { headers: headers, responseType: 'blob' as 'json' });
  }

  getImageBlob(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: 'blob'
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body])
        })
      );
  }

}
