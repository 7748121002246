import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: {text: string}) {}

	ngOnInit(): void {
		this.dialogRef.disableClose = true;
	}
}
