import { PropertySuite } from './../../../models/propertySuite';
import { Component, Inject, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  propertySuite: PropertySuite[];
  defaultBuildingName: string;
  selectedProperty : PropertySuite;
}

@Component({
  selector: 'app-property-dialog',
  templateUrl: './property-dialog.component.html',
  styleUrls: ['./property-dialog.component.scss']
})
export class PropertyDialogComponent implements OnInit {

  propertySuite!: PropertySuite[];
  defaultPropertySuite !: string;
  seletedItem!: string|undefined;
  defaultBuildingName!: string;

  constructor(public dialogRef: MatDialogRef<PropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,) {     
    this.propertySuite = data.propertySuite;
    this.defaultBuildingName = data.defaultBuildingName;
    if(data.selectedProperty){
      this.seletedItem = data.selectedProperty.suiteId;
    }    
  }
  

  ngOnInit(): void { }

  radioChange(event: string) {    
    this.defaultPropertySuite = event;
    this.seletedItem=this.defaultPropertySuite;    
  }

  save() {
    this.dialogRef.close(this.propertySuite.filter(a => a.suiteId == this.defaultPropertySuite));
  }

}
