import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { protectedResources } from "../auth-config";
import { News } from "../models/news";
import { ConstantsService } from "./constants.service";
import { ManageStorageService } from "./manage-storage.service";

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  allNews !: News[];
  allNewsSubject: Subject<News[]> = new Subject<News[]>();
  buildingsForCurrentUser !: any[];

  readonly newsApiURL = protectedResources.newsApi.endpoint;
  readonly buildingApiURL = protectedResources.buildingApi.endpoint;
  readonly regionaApiURL = protectedResources.regionsApi.endpoint;


  constructor(private http: HttpClient, private constants: ConstantsService,
    private localStorage: ManageStorageService) {
  }

  private handleError(errorResponce: HttpErrorResponse) {
    if (errorResponce.error instanceof ErrorEvent) {
      console.error("Client side error: ", errorResponce.error.message);
    }
    else {
      console.error("News API: Server side error: ", errorResponce);
    }
    return throwError("There is an error in the News API service");
  }

  createNewsArticle(form: any): Observable<News> {
    //const headers = { 'consumes': 'multipart/form-data'};
    const httpOptions = {
      headers: new HttpHeaders({ 'consumes': 'multipart/form-data' })
    }

    let addNews = this.newsApiURL + this.constants.addNews;
    return this.http.post<News>(addNews, form, httpOptions)
      .pipe(catchError(err => this.handleError(err)));
  }

  updateNewsArticle(form: any): Observable<News> {
    const httpOptions = {
      headers: new HttpHeaders({ 'consumes': 'multipart/form-data' })
    }
    let updateNews = this.newsApiURL + this.constants.updateNews;
    return this.http.put<News>(updateNews, form, httpOptions)
      .pipe(catchError(err => this.handleError(err)));
  }

  // get news for Admin
  getAllNews(): Observable<News[]> {
    let allNews = this.newsApiURL + this.constants.getAllNews;
    return this.http.get<News[]>(allNews)
      .pipe(catchError(err => this.handleError(err)));
  }

  //Get news to tenent news archive
  GetAllTenantNewsByTenatuserBEBuldingId(): Observable<News[]> {    
    let allNews = this.newsApiURL + this.constants.getAllTenantNewsByTenatuserBEBuldingId;
    let buildingId;    
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      var result = JSON.parse(building || '{}');
      buildingId = result.id;
    }

    return this.http.get<News[]>(`${allNews}?beBuildingId=${buildingId}`)
    .pipe(catchError(err => this.handleError(err)));
  }

  getNationalAndRegionalNewsById(newsId:string): Observable<News[]> {    
    let newsById = this.newsApiURL + this.constants.getNationalAndRegionalNewsById;
    return this.http.get<News[]>(newsById+"/"+newsId)
    .pipe(catchError(err => this.handleError(err)));
  }
  //Get news to tenent news archive
  getAllNationalAndRegionalNews(): Observable<News[]> {    
    let homeNews = this.newsApiURL + this.constants.getAllNationalAndRegionalNews;
    return this.http.get<News[]>(`${homeNews}`)
    .pipe(catchError(err => this.handleError(err)));
  }

  //ToDo:not using
  getAllFeaturedNews(): Observable<News[]> {
    let allNews = this.newsApiURL + this.constants.getAllNews;
    return this.http.get<News[]>(allNews)
      .pipe(catchError(err => this.handleError(err)));
  }

  getRegions() {
    let allBuildingsRegions = this.regionaApiURL + this.constants.getAllRegions;
    return this.http.get(allBuildingsRegions)
      .pipe(catchError(err => this.handleError(err)));
  }

  getAllBuildings() {    
    let allBuildings = this.buildingApiURL + this.constants.getAllBuildings;
    return this.http.get(allBuildings)
      .pipe(catchError(err => this.handleError(err)));
  }

  //ToDo
  getAllBuildingsByUser(email: string) {
    let allBuildings = this.buildingApiURL + this.constants.getAllBuildings;
    return this.http.get(allBuildings)
      .pipe(catchError(err => this.handleError(err)));
  }

  getNewsById(newsId: string | null): Observable<News> {
    let news = this.newsApiURL + this.constants.getNewsById;
    return this.http.get<News>(news + "/" + newsId)
      .pipe(catchError(this.handleError));
  }

  deleteNews(newsId: number | null): Observable<News> {
    let news = this.newsApiURL + this.constants.deleteNews;
    return this.http.delete<News>(news + "/" + newsId)
      .pipe(catchError(this.handleError));
  }

  updateNewsStatus(newsId: any, newsStatus: string): Observable<News> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    let updateNewsStatus = this.newsApiURL + this.constants.updateNewsStatus;
    return this.http.put<News>(`${updateNewsStatus}?newsId=${newsId}&newsStatus=${newsStatus}`, JSON.stringify(News), httpOptions)
      .pipe(catchError(err => this.handleError(err)));
  }
}