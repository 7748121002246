import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cvdollarsummary' })

export class CvDollarSummary implements PipeTransform {

    // transform(value: number): string {
    //     let cvdollar: any = "";
    //     if (value < 0) {
         
    //       cvdollar = "("+Math.abs(value/100)+")";
    //     }else{
    //         cvdollar = value/100;
    //     }
    //     return cvdollar;
    // }
    
    transform(value: string): string {
        let cvdollar: any = "";
        let numValue:number = 0.0;
        if(typeof(value)==='string'){
           numValue = Number(value.replace(/\$|,/g, ''));
        }
        else{
            numValue = value;
        }
        if (numValue < 0) {
            cvdollar = "("+Math.abs(numValue).toLocaleString('en-US', {
                minimumFractionDigits:2,
                maximumFractionDigits:2
            })+")";
        }else{
            let num:number = Math.abs(numValue);
             cvdollar = num.toLocaleString('en-US', {
                minimumFractionDigits:2,
                maximumFractionDigits:2
            });
        }
        return cvdollar;
    }
}