import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { ConstantsService } from './constants.service';
import { protectedResources } from 'src/app/auth-config';
import { catchError, share } from 'rxjs/operators';
import { ManageStorageService } from './manage-storage.service';
import { ServiceRequest } from '../models/service-request';
import { ServiceRequestStatus } from '../models/service-request-status';
import { Building } from '../models/building';
import { IssueType } from '../models/issueType';

@Injectable({
  providedIn: 'root'
})

export class BuildingEngineService {

  //service requests counts
  serviceRequestsStatus!: ServiceRequestStatus;

  //show Service Request component
  showServiceRequest: Subject<boolean> = new Subject<boolean>();

  // service requests
  serviceRequests!: any;
  allServiceRequests: Subject<any[]> = new Subject<any[]>();

  //Buildings
  properties !: any[];
  defaultBuilding!: Building;
  building: Subject<Building> = new Subject<Building>();

  //ToDo:Remove
  readonly buildingEngineURL = protectedResources.buildingEngineApi.endpoint;
  //ToDo:End Remove

  readonly prismURL = protectedResources.prismApi.endpoint;

  p_email: string = "";
  accessToken !: string;

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private localStorage: ManageStorageService
  ) {
  }

  changeBuilding(data: Building) {
    this.building.next(data);
  }

  changeServiceRequests(data: any) {
    this.allServiceRequests.next(data);
  }

  changeShowServiceRequests(data: boolean) {
    this.showServiceRequest.next(data);
  }

  private handleError(errorResponce: HttpErrorResponse) {
    if (errorResponce.error instanceof ErrorEvent) {
      console.error("Client side error: ", errorResponce.error.message);
    }
    else {
      console.error("BE API : Server side error: ", errorResponce);
    }
    return throwError(errorResponce);
  }

  loginToBuildingEngine(email: string): Observable<any> {
    let loginUrl = this.buildingEngineURL + this.constants.login;
    return this.http.get(`${loginUrl}?email=${email}`)
      .pipe(catchError(err => this.handleError(err)));
  }

  getBuildingID() {
    // get default building
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      var result = JSON.parse(building || '{}');
      return result.id;
    }
  }

  //============================Prism=================================
  createServiceRequests(body: any): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    let requests = this.prismURL + this.constants.createServiceRequest;
    return this.http.post<any>(`${requests}`, body, { 'headers': headers })
      .pipe(catchError(this.handleError));
  }

  getBuildingByUser() {
    let email = this.localStorage.getStorage(this.constants.userEmail);
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      email = this.localStorage.getStorage(this.constants.impersonateAs);
    }
    let serviceRequestsUrl = this.prismURL + this.constants.getBuildingsById;
    return this.http.get(`${serviceRequestsUrl}?email=${email}`)
      .pipe(catchError(this.handleError));
  }

  getBuildingByImpersonateUser(email: string) {
    let serviceRequestsUrl = this.prismURL + this.constants.getBuildingsById;
    return this.http.get(`${serviceRequestsUrl}?email=${email}`)
      .pipe(catchError(this.handleError));
  }

  getServiceRequests(buildingId: string) {
    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      return this.getServiceRequestsForImpersonatedUser(buildingId);
    }
    let organizationId: string = this.localStorage.getStorage(this.constants.userOrgId)!;
    let serviceRequestsUrl = this.prismURL + this.constants.GetWorkOrderByOrgAndBuilding;
    return this.http.get<any[]>(`${serviceRequestsUrl}?organizationId=${organizationId}&buildingId=${buildingId}`)
      .pipe(catchError(this.handleError));
  }

  getServiceRequestsForImpersonatedUser(buildingId: string) {
    let organizationId: string = this.localStorage.getStorage(this.constants.impersonateUserOrgId)!;
    let serviceRequestsUrl = this.prismURL + this.constants.GetWorkOrderByOrgAndBuilding;
    return this.http.get<any[]>(`${serviceRequestsUrl}?organizationId=${organizationId}&buildingId=${buildingId}`)
      .pipe(catchError(this.handleError));
  }

  getIssueTypes(buildingId: string): Observable<IssueType[]> {
    let requests = this.prismURL + this.constants.getIssueTypesByBuilding;
    return this.http.get<IssueType[]>(`${requests}?buildingId=${buildingId}`)
      .pipe(catchError(this.handleError));
  }

  getServiceRequestByID(requestId: string): Observable<ServiceRequest> {
    let serviceRequestsUrl = this.prismURL + this.constants.getServiceRequestById;
    return this.http.get<ServiceRequest>(`${serviceRequestsUrl}?id=${requestId}`)
      .pipe(catchError(this.handleError));
  }

  postComment(ServiceRequestId: number, comment: string): Observable<any> {
    let buildingId = this.getBuildingID();
    let accessToken = this.localStorage.getStorage(this.constants.tenx_access_token);
    const headers = { 'content-type': 'application/json' }
    let requests = this.prismURL + this.constants.postComment;
    return this.http.post<any>(`${requests}?id=${ServiceRequestId}`,comment, { 'headers': headers })
      .pipe(catchError(this.handleError));
  }

  postAttachment(form: FormData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'consumes': 'multipart/form-data',
        'accept': 'text/plain'
      })
    }
    let requests = this.prismURL + this.constants.postAttachment;
    return this.http.post<any>(requests, form, httpOptions)
      .pipe(catchError(err => this.handleError(err)));
  }

}
