
  <app-company-select></app-company-select>

  <!-- Invoice Details Title -->
  <div class="container">
  <div class="invoice-details row">
      <div class="col-md-6">
          <h2>{{'invoicedetails' | transloco}}- <span >{{selectedCompanyName}}</span></h2>
      </div>
      <div class="col-md-8">
          <div>
              <div class="inovice-details-left"><p class="details-left-text">{{'balance' | transloco}}</p></div>
              <div class="inovice-details-right"><p class="details-right-text">CAD <span *ngIf="companyData">{{allSummaryInvoiceRequests.balance | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></p></div>
          </div>

          <div>
              <div class="inovice-details-left"><p class="details-left-text">{{'lastpaymentdate' | transloco}}</p></div>
              <div class="inovice-details-right"><p class="details-right-text"><span *ngIf="companyData">{{allSummaryInvoiceRequests.last_payment_date}}</span></p></div>
          </div>

          <div>
              <div class="inovice-details-left"><p class="details-left-text">{{'lastpaymentamount' | transloco}}</p></div>
              <div class="inovice-details-right"><p class="details-right-text"><span *ngIf="companyData">{{allSummaryInvoiceRequests.last_payment_amount | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></p></div>
          </div>

      </div>
      <div class="col-md-4">
        <button mat-button class="payment-button mb-3 mb-sm-0" (click)="makePayment()">{{'accountbalancemakeapayment' | transloco}}</button>
      </div>
  </div>
  </div>


  <div class="service-request-section shadow-sm invoice-stat-margin">
      <div class="container">
          <app-stats [companyData]="companyData" [allSummaryInvoiceRequests]="allSummaryInvoiceRequests"></app-stats>

              <div class="">

                  <div class="text-right">
                      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" matTooltip="Click it would just re-check for outstanding invoices.">
                          <mat-icon style="color: #a5a5a5;" (click)="ngOnInit()">refresh</mat-icon>
                        </button>
                  </div>
              <div class="invoice-details-wrapper">
                  <!-- Table -->
                  <div class="table-wrapper table-scroll-800">
                      <table mat-table [dataSource]="dataSource" class="">


                      <ng-container matColumnDef="display_number">
                          <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 400px;"> {{'invoice' | transloco}}</th>
                          <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                              {{element.display_number}}
                          </td>
                      </ng-container>


                      <ng-container matColumnDef="currency">
                          <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 150px;"> {{'currency' | transloco}}</th>
                          <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">{{element.currency  | uppercase}}</td>
                      </ng-container>



                      <ng-container matColumnDef="amount_cents">
                          <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 110px;"> {{'amount' | transloco}}</th>
                          <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                              {{element.amount_cents | cvdollar}}
                          </td>
                      </ng-container>


                      <ng-container matColumnDef="owing_cents">
                          <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 100px; text-align: center;"> {{'owing' | transloco}}</th>
                          <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2 text-center">{{element.owing_cents | cvdollar}}</td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                  </div>
                  <!-- Table -->

                  <!-- Outstanding detials message -->
                  <div class="text-center" *ngIf="!openInvoicesData">
                      <p class="invoice-see-details-text mb-3">
                          <img src="../../../../assets/images/warning.svg" class="warning-icon mr-2" />
                          {{'therearenooutstandinginvoice' | transloco}}
                      </p>
                  </div>
                  <!-- Outstanding detials message -->
              </div>

            </div>

      </div>
    </div>

