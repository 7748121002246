import { DatePipe } from '@angular/common';
import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news';
import { NewsTypes } from 'src/app/models/newsTypes';
import { Region } from 'src/app/models/region';
import { Status } from 'src/app/models/status';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-admin-news-preview',
  templateUrl: './admin-news-preview.component.html',
  styleUrls: ['./admin-news-preview.component.scss']
})
export class AdminNewsPreviewComponent implements OnInit {

  isAdmin: boolean = false;
  VersapayView: boolean = false;
  TenantView:boolean = false;
  AdminView:boolean = false;

  showServiceRequest: boolean = false;

  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }
  newsId: string = "";
  content: any;
  imageURL!: string;
  videoURL!: any;
  currentNews !: any;
  star: string = 'favourite-news';
  newsTypeText: string = "";
  regionsList !: any;
  buildingList !: any;

  isNextdisplay: boolean = true;
  isNextClicked: boolean = false;
  isBackClicked: boolean = false;

  isNextDisabled: boolean = false;
  isBackDisabled: boolean = false;

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  isSubmit: boolean = false;

  get allNews(): News[] {
    return this.newsService.allNews;
  }
  set allNews(newsList: News[]) {
    this.newsService.allNews = newsList;
  }

  constructor(
    private route: Router,
    private constants: ConstantsService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private newsService: NewsService,
    private localStorage: ManageStorageService,
    private sanitizer: DomSanitizer,
  ) {

    let userRoles:any = this.usersService.loggedInUser.roles;

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        this.rework(this.usersService.loggedInUser);
        this.loadNews();
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);
        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }

    // if (this.usersService.loggedInUser?.role) {
    //   this.isAdmin = this.usersService.isAdmin(this.usersService.loggedInUser);
    // }
    // else {
    //   let email = this.localStorage.getStorage(this.constants.userEmail)
    //   this.usersService.getLoggedInUser(email).subscribe(
    //     (data) => {
    //       this.isAdmin = this.usersService.isAdmin(data);
    //     }
    //   );
    // }

    if (!this.isAdmin) {
      if(this.hasPrismAccess){
        this.getAllTenantNews();
      }else if(this.hasVersapayAccess){
        this.activatedRoute.paramMap.subscribe(param => {
          const id = param.get('id');
          if (id) {
            this.newsId = id;
            this.getNationalAndRegionalNewsById(this.newsId);
          }
        });
      }

    }else{
      this.loadNews();
    }

  }

  ngOnInit(): void {

    let userRoles:any = this.usersService.loggedInUser.roles;

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        this.rework(this.usersService.loggedInUser);
        this.loadNews();
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);
        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }

  // if(this.hasVersapayAccess){
  //   this.activatedRoute.paramMap.subscribe(param => {
  //     const id = param.get('id');
  //     if (id) {
  //       this.newsId = id;
  //       this.getHomeNewsById(this.newsId);
  //     }
  //   });
  // }else{
  //   this.loadNews();
  // }
  }

  loadNews(){

    if(this.isAdmin || this.hasPrismAccess){
      this.getRegions();
      this.getAllBuildings();

      this.activatedRoute.paramMap.subscribe(param => {
        const id = param.get('id');
        if (id) {
          this.newsId = id;
          this.getNewsById(this.newsId);
        }
      });
    }else{

        this.activatedRoute.paramMap.subscribe(param => {
          const id = param.get('id');
          if (id) {
            this.newsId = id;
            this.getNationalAndRegionalNewsById(this.newsId);
          }
        });

    }
    // if(this.hasVersapayAccess){
    //   this.activatedRoute.paramMap.subscribe(param => {
    //     const id = param.get('id');
    //     if (id) {
    //       this.newsId = id;
    //       this.getNationalAndRegionalNewsById(this.newsId);
    //     }
    //   });
    // }else{
    //   this.getRegions();
    //   this.getAllBuildings();

    //   this.activatedRoute.paramMap.subscribe(param => {
    //     const id = param.get('id');
    //     if (id) {
    //       this.newsId = id;
    //       this.getNewsById(this.newsId);
    //     }
    //   });
    // }
  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.rework(data);
        this.loadNews();
      });

  }
  getNewsById(newsId: string) {
    this.newsService.getNewsById(newsId).subscribe(
      (news: News) => {
        if (news) {
          this.currentNews = news;
          if (news.imageFileName) {
            let url = environment.blobImagePath + news.imageFileName;
            this.imageURL = url;
          }

          if (news.videoLink) {
            this.videoURL = news.videoLink;
            //this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(news.videoLink);
          }

          this.content = news.description

          if (news.type == NewsTypes.national) {
            this.newsTypeText = news.type?.toString()!;
          }
          else {
            if (news.type == NewsTypes.regional) {
              if (this.regionsList) {
                let region = [];
                for (let i = 0; i < news.regionId?.length!; i++) {
                  region.push(this.regionsList?.find((r: any) => (r.id == news.regionId![i]))?.name);
                }
                this.newsTypeText = news.type?.toString()! + " | " + region.join(", ");
              }
              else {

                this.getRegions();
                this.newsTypeText = news.type?.toString()!;
              }

            }
            else {
              let building = [];

              if (this.buildingList) {
                for (let i = 0; i < news.buildingId?.length!; i++) {
                  building.push(this.buildingList?.find((b: any) => (b.id == news.buildingId![i]))?.name);
                }

                this.newsTypeText = news.type?.toString()! + " | " + building.join(", ");
              }
              else {
                this.newsTypeText = news.type?.toString()!;
              }

            }
          }

          if (news.status === Status.published) {
            this.isSubmit = true;
          }
          else {
            this.isSubmit = false;
          }
          this.disableBackNextButtons(+newsId);

          if (!this.isAdmin) {
            this.disableBackNextButtons(+newsId);
          }
        }
      },
      (err: any) => {
        //If the preview article is null and if tenant, needs to disable next button
        if (!this.isAdmin && this.isNextClicked) {
          this.isNextdisplay = false;
        }
        console.log(err)
      }
    );
  }

  getRegions() {
    this.newsService.getRegions().subscribe(
      (res) => {
        this.regionsList = res;
      },
      (err: any) => console.log(err)
    );
  }

  getAllBuildings() {
    this.newsService.getAllBuildings().subscribe(
      (res) => {
        this.buildingList = res;
      },
      (err: any) => console.log(err)
    );
  }

  disableBackNextButtons(curNewsId: Number) {
    if (this.allNews) {
      //check next button
      var curNewsIndex = this.allNews.findIndex(x => x.id === curNewsId);
      var nextNewsIndex = curNewsIndex + 1;
      if ((this.allNews.length - 1) < nextNewsIndex) {
        this.isNextDisabled = true;
      }

      //check back button
      var prevNewsIndex = curNewsIndex - 1;
      if (prevNewsIndex < 0) {
        this.isBackDisabled = true;
      }
    }

  }

  submitNews() {
    this.newsService.updateNewsStatus(this.currentNews.id, Status.published).subscribe(
      (res) => {
        if (res) {
          //update news list with new created news article
          if (this.newsService.allNews) {

            var result = this.newsService.allNews.find(obj => {
              return obj.id === res.id
            });

            let index = this.newsService.allNews.indexOf(result!);
            this.newsService.allNews[index] = res;

          }

          this.isSubmit = true;


          if(this.localStorage.getStorage('userLang')=='fr'){
            this.statusMessage = "Article de nouvelles publié avec succès!!";
          }else{
            this.statusMessage = "News published Successfully!!";
          }
          this.isError = false;
          this.showMessage = true;
          this.FadeOutMessage();
        }
      },
      (error: any) => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    )
  }

  backButtonClick() {
    this.isNextClicked = false;
    this.isNextDisabled = false;

    let curNewsId: number = +this.newsId;
    var curNewsIndex = this.allNews.findIndex(x => x.id === curNewsId);
    var prevNewsIndex = curNewsIndex - 1;
    if (prevNewsIndex < 0) {
      prevNewsIndex = curNewsIndex;
      this.isBackDisabled = true;
    }
    else {
      var prevNewsId = this.allNews[prevNewsIndex].id;
      this.route.navigate([this.constants.previewsUrl, prevNewsId]);
    }

  }

  getNationalAndRegionalNewsById(newsid:string): void {
    this.newsService.getNationalAndRegionalNewsById(newsid)
      .subscribe(data => {
          this.currentNews = data;
      });
  }

  getAllTenantNews(): void {
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building) {
      this.newsService.GetAllTenantNewsByTenatuserBEBuldingId()
        .subscribe(data => {
          this.allNews = data;
        });
    }
  }

  nextButtonClick() {
    this.isNextClicked = true;
    this.isBackDisabled = false;
    let curNewsId: number = +this.newsId;

    var curNewsIndex = this.allNews.findIndex(x => x.id === curNewsId);
    var nextNewsIndex = curNewsIndex + 1;
    if (this.allNews.length < nextNewsIndex) {
      nextNewsIndex = curNewsIndex;
      this.isNextDisabled = true;
    }
    else {
      var nextNewsId = this.allNews[nextNewsIndex].id;
      this.route.navigate([this.constants.previewsUrl, nextNewsId]);
    }
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  editNews() {
    this.route.navigate([this.constants.editNewsUrl, this.newsId])
  }

  navigateToNews() {

    if (this.isAdmin) {
      this.route.navigate([this.constants.newsUrl])
    }
    else {
      this.route.navigate([this.constants.tenantnewsUrl])
    }

  }

}
