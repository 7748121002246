import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'status' })

export class ServiceRequestStatusPipe implements PipeTransform {

  transform(value: number): string {
    let status: string = "";
    if (value == 0) {
      status = "New"
    }
    if (value == 1) {
      status = "In Progress"
    }
    if (value == 2) {
      status = "Completed"
    }
    if (value == 3) {
      status = "Rejected"
    }
    if (value == 4) {
      status = "Cancelled"
    }
    return status;
  }
}