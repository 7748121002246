<mat-card class="rounded-0 pl-4 pr-4 shadow-sm mt-5">

<!-- Alerts -->
<div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
  {{statusMessage}}
  <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Alerts -->

  <!-- Title and button wrapper -->
  <div class="row justify-content-between align-items-center mb-4">
    <div class="col-md-4">
      <!-- Title -->
      <h3 class="mb-0">{{'history' | transloco}}</h3>
      <!-- Title -->
    </div>
    <div class="col-md-4 text-right">
      <!-- Add document button -->
      <button mat-raised-button color="accent" class="Add-button" [hidden]="showAddComments"
        (click)="clickAddComments()" [disabled]="isImpersonated">{{'add_comment' | transloco}}</button>
      <!-- Add document button -->
    </div>
  </div>
  <!-- Title and button wrapper -->

  <!-- Upload section -->
  <div class="upload-document-wrapper mb-5 p-3" [hidden]="!showAddComments">
    <form [formGroup]="commentForm">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <!-- Comment -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'comment' | transloco}}</mat-label>
                <textarea matInput formControlName="comment"></textarea>
                <mat-error  *ngIf="commentForm.get('comment')?.errors?.required && commentForm.get('comment')?.touched" class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Comment -->
            </div>
          </div>

        </div>

        <!-- Submit/Cancel buttons -->
        <div class="col-md-12">
          <button mat-raised-button class="submit-small-button btn-w-100 shadow-none mr-2 {{frbutton}}" [disabled]="commentForm.invalid" (click)="onSubmit()">{{'submit' | transloco}}</button>
          <button mat-raised-button class="cancel-small-button btn-w-100 shadow-none"
            (click)="clickCancelButton()">{{'cancel' | transloco}}</button>
        </div>
        <!-- Submit/Cancel buttons -->
      </div>
    </form>
  </div>
  <!-- Upload section -->

  <!-- Table -->
  <div class="table-wrapper table-scroll-600 table-scroll-y">
    <table mat-table [dataSource]="dataSource" class="">

      <!-- Description Column -->
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef style="width: 20%;" class="sr-bottom-border-remove"> {{'date' | transloco}} </th>
        <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.created_at| date: 'd MMM y'}}</td>
      </ng-container>

      <!-- RequestId Column -->
      <ng-container matColumnDef="Details">
        <th mat-header-cell *matHeaderCellDef style="width: 50%;" class="sr-bottom-border-remove"> {{'details' | transloco}} </th>
        <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove"> {{element.text}} </td>
      </ng-container>


      <!-- Status Column -->
      <ng-container matColumnDef="AddedBy">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;" class="sr-bottom-border-remove"> {{'addedby' | transloco}} </th>
        <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.created_by.first_name}} {{element.created_by.last_name}}</td>
      </ng-container>

      <!-- Days Old Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;" class="sr-bottom-border-remove"> {{'status' | transloco}} </th>
        <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.status | status}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <!-- Table -->

</mat-card>