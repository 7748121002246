<app-company-select></app-company-select>

<!-- Invoice Details Title -->
<div class="container">
<div class="invoice-details row">
    <div class="col-md-6">
        <h2>{{'invoicedetails' | transloco}} - <span >{{selectedCompanyName}}</span></h2>
    </div>
    <div class="col-md-8">
        <div>
            <div class="inovice-details-left"><p class="details-left-text">{{'balance' | transloco}}</p></div>
            <div class="inovice-details-right"><p class="details-right-text">CAD <span *ngIf="companyData">{{allSummaryInvoiceRequests.balance | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></p></div>
        </div>

        <div>
            <div class="inovice-details-left"><p class="details-left-text">{{'lastpaymentdate' | transloco}}</p></div>
            <div class="inovice-details-right"><p class="details-right-text"><span *ngIf="companyData">{{allSummaryInvoiceRequests.last_payment_date}}</span></p></div>
        </div>

        <div>
            <div class="inovice-details-left"><p class="details-left-text">{{'lastpaymentamount' | transloco}}</p></div>
            <div class="inovice-details-right"><p class="details-right-text"><span *ngIf="companyData">{{allSummaryInvoiceRequests.last_payment_amount | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></p></div>
        </div>

    </div>
    <div class="col-md-4">
        <button mat-raised-button class="payment-button mb-3 mb-sm-0" (click)="makePayment()">{{'accountbalancemakeapayment' | transloco}}</button>
    </div>
</div>
</div>

<!-- Invoice Details -->
<div class="service-request-section shadow-sm invoice-stat-margin">
    <div class="container">
            <app-stats [companyData]="companyData" [allSummaryInvoiceRequests]="allSummaryInvoiceRequests"></app-stats>
            <div class="lift-content">

                <!-- See details message -->
                <div class="text-center">
                    <p class="invoice-see-details-text mb-3">{{'clickontheoutstandingbutton' | transloco}}</p>
                    <button mat-raised-button class="invoice-details-button" style="color: #D6001C;" [routerLink]="['/Home/Dashboard/OutStandingInvoices']">{{'outstandinginvoices' | transloco}}</button>
                </div>
                <!-- See details message -->

                <!-- Outstanding detials message -->
                <!-- <div class="text-center" *ngIf="!companyData">
                    <p class="invoice-see-details-text mb-3">
                        <img src="../../../../assets/images/warning.svg" class="warning-icon mr-2" />
                        {{'therearenooutstandinginvoice' | transloco}}
                    </p>
                </div> -->
                <!-- Outstanding detials message -->

            <div class="invoice-details-wrapper">
                <!-- Table -->
                <div class="table-wrapper table-scroll-800">

                </div>
                <!-- Table -->

            </div>
          </div>

    </div>
  </div>
