import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { ConstantsService } from './services/constants.service';
import { ManageStorageService } from './services/manage-storage.service';
import { AuthService } from './services/rc-auth.service';
import { UsersService } from 'src/app/services/users.service';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import {LoggingService} from "./services/logging.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  readonly idleTime = environment.idleTimeInSeconds;
  readonly timeOutTime = environment.timeOutTimeInSeconds;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null as any;
  isIframe = false;
  isAuthenticated = false;
  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;

  isAdmin: boolean = false;

  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  constructor(private localStorage: ManageStorageService,
    private constants: ConstantsService,
    private idle: Idle, private keepalive: Keepalive,
    private rcAuthService: AuthService,
    public authService: MsalService,
    private usersService: UsersService,
    private langService: TranslocoService,
    private loggingService: LoggingService,
    private route: Router
  ) { }

  ngOnDestroy(): void {
    localStorage.clear();
    this.rcAuthService.logout();
  }

 /* @HostListener('window:beforeunload')
  setAuthToken() {
    this.localStorage.setStorage(this.constants.isAuth, "false");
    this.rcAuthService.logout();
  }*/

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    // set local storage to false for current user
    if (this.localStorage.getStorage(this.constants.isAuth) == null || this.localStorage.getStorage(this.constants.isAuth) == "") {
      this.localStorage.setStorage(this.constants.isAuth, "false");
    }

    //if(this.usersService.getUserRoles())
    let userRoles:any = this.usersService.loggedInUser.roles;

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        var lang = this.usersService.getDefaultLang(this.loggedInUser);
        if(lang==null || lang==''){
          lang='en';

          this.usersService.putUserDefaultLang(this.loggedInUser,lang).subscribe(
            res=>{
              if(res){
                this.langService.setActiveLang(lang);
                this.langService.setDefaultLang(lang);
                this.localStorage.setStorage("userLang",lang);
                //window.location.reload();
              }
            },
            (error) => {
              this.statusMessage = error;
              this.isError = true;
              this.showMessage = true;
              this.FadeOutMessage();

            }
          );

          //this.langService.setActiveLang(lang);
          //this.langService.setDefaultLang(lang);
          //this.localStorage.setStorage("userLang",lang);
        }else{
          this.langService.setActiveLang(lang);
          this.langService.setDefaultLang(lang);
          this.localStorage.setStorage("userLang",lang);
        }
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);

        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);

      }
    }

    this.setIdelTime();
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 5000);
  }
  CloseMessage() {
    this.showMessage = false;
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.isAdmin = this.usersService.isAdmin(data);
        var lang = this.usersService.getDefaultLang(data);
        if(lang==null || lang==''){
          lang='en';
          this.usersService.putUserDefaultLang(this.loggedInUser,lang).subscribe(
            res=>{
              if(res){
                this.langService.setActiveLang(lang);
                this.langService.setDefaultLang(lang);
                this.localStorage.setStorage("userLang",lang);
                //window.location.reload();
              }
            },
            (error) => {
              this.statusMessage = error;
              this.isError = true;
              this.showMessage = true;
              this.FadeOutMessage();

            }
          );
          //this.langService.setActiveLang(lang);
          //this.langService.setDefaultLang(lang);
          //this.localStorage.setStorage("userLang",lang);
        }else{
          this.langService.setActiveLang(lang);
          this.langService.setDefaultLang(lang);
          this.localStorage.setStorage("userLang",lang);
        }
      });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  setIdelTime() {
    // set idel time
    this.idle.setIdle(environment.idleTimeInSeconds);
    this.idle.setTimeout(environment.timeOutTimeInSeconds);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.rcAuthService.logout();
    });

    this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

}
