<!-- Side Navigation -->
<!-- <mat-selection-list [multiple]="false" class="pt-0">
  <mat-list-option aria-selected="true" (click)="navigateToDashboard()">Dashboard</mat-list-option>
  <mat-list-option aria-selected="true" (click)="navigateToServiceRequest()">Service Request</mat-list-option>
  <mat-list-option (click)="navigateToMyProfile()">My Profile</mat-list-option>
</mat-selection-list> -->
<!-- Side Navigation -->

<mat-sidenav-container class="side-nav-container" autosize>
	<mat-sidenav #sidenav class="sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
		(mouseleave)="mouseleave()">
		<mat-nav-list *ngIf="!isImpersonated" class="side-nav-list">
			<mat-list-item routerLink="/Home/Dashboard">{{'dashboard' | transloco}}</mat-list-item>
			<mat-list-item *ngIf="!isAdmin && hasPrismAccess" routerLink="/Home/Dashboard/ServiceRequest"
				routerLinkActive="sidenav-active">
				<span class="full-width">{{'servicerequest' | transloco}}</span>
			</mat-list-item>
			<div *ngIf="!isAdmin && hasPrismAccess" class="submenu mb-3 expanded d-none" #link="routerLinkActive" routerLinkActive=""
				[class.d-none]="!link.isActive" [routerLinkActiveOptions]="{exact: true}">
				<a mat-list-item routerLink="/Home/Dashboard/ServiceRequest/NewServiceRequest"
					routerLinkActive="subnav-active">{{'submit_service_request' | transloco}}</a>
			</div>
			<mat-list-item *ngIf="!isAdmin && hasVersapayAccess" routerLink="/Home/Dashboard/Invoices"
				routerLinkActive="sidenav-active">
				<span class="full-width">{{'invoices' | transloco}}</span>
			</mat-list-item>
			<mat-list-item *ngIf="isAdmin" routerLink="/Home/Dashboard/News" routerLinkActive="sidenav-active">
				<span class="full-width">{{'news' | transloco}}</span>
			</mat-list-item>
			<div *ngIf="isAdmin" class="submenu mb-3 expanded d-none" #link="routerLinkActive" routerLinkActive=""
				[class.d-none]="!link.isActive" [routerLinkActiveOptions]="{exact: true}">
				<a mat-list-item routerLink="/Home/Dashboard/News/CreateNews" routerLinkActive="subnav-active">{{'add_news_article' | transloco}}</a>
			</div>
			<mat-list-item *ngIf="isAdmin" routerLink="/Home/Dashboard/allusers" routerLinkActive="sidenav-active">
				<span class="full-width">{{'users' | transloco}}</span>
			</mat-list-item>
			<!-- <mat-list-item *ngIf="isAdmin" routerLink="/Home/Dashboard/VersapayUsers" routerLinkActive="sidenav-active">
				<span class="full-width">{{'versapayusers' | transloco}}</span>
			</mat-list-item> -->
			<mat-list-item routerLink="/Home/Dashboard/MyProfile" routerLinkActive="sidenav-active">{{'myprofile' | transloco}}
			</mat-list-item>
		</mat-nav-list>
		<mat-nav-list *ngIf="isImpersonated" class="side-nav-list">
			<mat-list-item routerLink="/Home/Dashboard">{{'dashboard' | transloco}}</mat-list-item>
			<mat-list-item *ngIf="hasPrismAccess"  routerLink="/Home/Dashboard/ServiceRequest"
				routerLinkActive="sidenav-active">
				<span class="full-width">{{'servicerequest' | transloco}}</span>
			</mat-list-item>
      <mat-list-item *ngIf="hasVersapayAccess" routerLink="/Home/Dashboard/Invoices"
                     routerLinkActive="sidenav-active">
        <span class="full-width">{{'invoices' | transloco}}</span>
      </mat-list-item>
			<mat-list-item routerLink="/Home/Dashboard/MyProfile" routerLinkActive="sidenav-active">{{'myprofile' | transloco}}
			</mat-list-item>
		</mat-nav-list>
	</mat-sidenav>
</mat-sidenav-container>
