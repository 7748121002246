<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div *ngIf="!isUpdate" class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'new_service_request' | transloco}}</h1>
    </div>
    <!-- Title -->

    <!-- Edit Service Request Title -->
    <div *ngIf="isUpdate" class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'servicerequest' | transloco}} - {{identifier}}</h1>
      <!--<p class="job-description mb-1 mt-2">{{requestDescrip}} </p>-->
      <ul class="job-result">
        <li class="job-staus">{{requestStatus}}</li>
        <li class="job-days">{{requestTS}} {{'days' | transloco}}</li>
      </ul>
    </div>
    <!-- Edit Service Request Title -->

  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-4 col-lg-3">

      <!-- Side Nav -->
      <app-side-nav></app-side-nav>
      <!-- Side Nav -->

    </div>
    <!-- Left Column -->


    <!-- Right Column -->
    <div class="col-md-8 col-lg-9 mt-3 mt-md-0">

      <!-- Alerts -->
      <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
        {{statusMessage}}
        <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Alerts -->

      <form [formGroup]="serviceRequestForm">
        <mat-card class="rounded-0 pl-4 pr-4 shadow-sm">
          <!-- Property Button -->
          <div class="row mb-5 mt-3" *ngIf="!isUpdate && showPropertyBtn">
            <div class="col-md-12">
              <button mat-raised-button class="submit-small-button shadow-none"
                (click)="openDialog()">{{propertyText | transloco}}</button>
            </div>
          </div>
          <!-- Property Button -->


          <p>{{'requestedby' | transloco}}*</p>
          <!-- User Information -->
          <div class="user-information">
            <img src="../../../../assets/images/user-info-icon.svg" alt="user image"
              class="user-info-avatar d-inline-block mr-3" />
            <p class="mb-0 d-inline-block">{{ email }} </p>
          </div>
          <!-- User Information -->

          <div class="row mt-4 mb-5">

            <div class="col-md-6">

              <!-- Building -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'building' | transloco}}*</mat-label>
                <input matInput formControlName="Building" required readonly>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Building -->

              <!-- Floor -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'floor' | transloco}}</mat-label>
                <input matInput formControlName="Floor" readonly>
              </mat-form-field>
              <!-- Floor -->

              <!-- Issue Type -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'issuetype' | transloco}}</mat-label>
                <mat-select [disabled]=disableSelect formControlName="IssueType" #clientValue (selectionChange)="changeClient($event.value)"
                  required>
                  <mat-optgroup *ngFor="let issue of issueTypes" [label]="issue.name"
                  [disabled]="disableSelect">
                    <mat-option *ngFor="let child of issue.children" [value]="child.id">
                      {{child.name}}
                    </mat-option>
                  </mat-optgroup>

                  <!--<mat-option [value]="issue.id" *ngFor="let issue of issueTypes">{{ issue.name }}</mat-option>-->
                </mat-select>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Issue Type -->

              <!-- Keep Me Informed -->
              <!-- <section class="example-section">
                <mat-checkbox class="example-margin" formControlName="KeepMeInformed">Keep Me Informed</mat-checkbox>
              </section> -->
              <!-- Keep Me Informed -->

            </div>

            <div class="col-md-6">

              <!-- Company Name -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'companyname' | transloco}}</mat-label>
                <input matInput formControlName="CompanyName" readonly>
              </mat-form-field>
              <!-- Company Name -->

              <!-- Suite -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'suite' | transloco}}</mat-label>
                <input matInput formControlName="Suite" readonly>
              </mat-form-field>
              <!-- Suite -->

              <!-- Phone -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'phone' | transloco}}</mat-label>
                <input matInput formControlName="Phone" readonly>
              </mat-form-field>
              <!-- Phone -->

            </div>

            <!-- seperator -->
            <!-- <div class="col-md-12">
              <div class="mt-4 mb-4 seperator"></div>
            </div> -->
            <!-- seperator -->

            <!-- Text Editor -->
            <div class="col-md-12">
              <p class="">{{'details' | transloco}}*</p>

              <!-- Details-->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label></mat-label>
                <textarea matInput formControlName="Details" rows="5"></textarea>
                <!--<textarea matInput *ngIf="isUpdate" formControlName="Details"></textarea>-->
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Details-->
            </div>
            <!-- Text Editor -->

            <!-- Submit/Cancel buttons -->            
            <div class="col-md-12 mt-3" *ngIf="!isUpdate">
              <button mat-raised-button class="cancel-button shadow-none" (click)="CancelButtonClick()">{{'cancel' | transloco}}</button>
              <button mat-raised-button class="submit-button shadow-none" type="submit"
                [disabled]="serviceRequestForm.invalid || disableSubmit" (click)="onSubmit()">{{submitBtnText | transloco}}</button>
            </div>
            <!-- Submit/Cancel buttons -->


          </div>

        </mat-card>

        <!-- Files history section -->
        <div *ngIf="isUpdate && currentServiceRequest">
          <app-file-history [serviceRequest]="currentServiceRequest"></app-file-history>
        </div>
        <!-- Files history section -->

        <!-- Files section -->
        <div *ngIf="isUpdate && currentServiceRequest">
          <app-add-files [serviceRequest]="currentServiceRequest"></app-add-files>
        </div>

        <!-- Files section -->


      </form>
    </div>

    <!-- Right Column -->

  </div>



</div>