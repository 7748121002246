import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  @Input() companyData: boolean = false;
  @Input() allSummaryInvoiceRequests: any;

  constructor() { }

  ngOnInit(): void {
  }

}
