<div class="row ml-1 mr-1">
    <h2 mat-dialog-title>{{'selectcompany' | transloco}}</h2>
  </div>
  
  <mat-dialog-content class="mat-typography dialog-content">
  
  <div>
      <mat-radio-group aria-label="Select an option" class="row">
        <mat-radio-button class="col-md-12 mt-2 p-2 p-md-3 ml-1 mr-1 select-property-wrapper"
        *ngFor="let company of companies" #event [value]=company.externalID [checked]="company.externalID == chosenItem" (change)="radioChange(event.value)">
            <h3 class="property-location">{{company.name}} ({{company.externalID}})</h3>
        </mat-radio-button>
      </mat-radio-group>
  
    <!--<div class="col-md-11">
      <h3 class="property-location">{{building.name}}</h3>
    </div>-->
  </div>
  
  </mat-dialog-content>
  <mat-dialog-actions align="start" class="mt-3 mb-3 ml-1 mr-1">
  <!--<button mat-raised-button class="cancel-button shadow-none" mat-dialog-close>Cancel</button>-->
  <button mat-raised-button class="submit-button shadow-none" [disabled]="isCompanySelected" (click)="save()">{{'select' | transloco}}</button>
  </mat-dialog-actions>
  