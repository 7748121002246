<!-- Bredcrumb -->
<div class="breadcrumb-wrapper">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb mb-0">     
        <li  class="breadcrumb-item" *ngFor="let route of breadcrumbs">
          <a *ngIf="!route.terminal" href="" [routerLink]="[route.url]"> {{route.displayName | transloco}}</a>
          <span *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">  {{route.displayName | transloco}} </span>
        </li> 
    </ol>
  </nav>
</div>
<!-- Bredcrumb -->