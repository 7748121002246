import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { HomeComponent } from './components/pages/home/home/home.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found/page-not-found.component';
import { AdminNewsArticleComponent } from './modules/news/admin-news-article/admin-news-article.component';
import { AdminNewsPreviewComponent } from './modules/news/admin-news-preview/admin-news-preview.component';
import { AdminNewsComponent } from './modules/news/admin-news/admin-news.component';
import { NewsArchiveComponent } from './modules/news/news-archive/news-archive.component';
import { NewServiceRequestComponent } from './modules/service-requests/components/new-service-request/new-service-request.component';
import { ServiceRequestComponent } from './modules/service-requests/components/service-request/service-request.component';
import { UserAuthGuard } from './services/auth-guard.service';
import { OutstandingInvoicesComponent } from './modules/versapay/outstanding-invoices/outstanding-invoices.component';
import { HomeOpenInvoicesComponent } from './modules/versapay/home-open-invoices/home-open-invoices.component';
import { VersapayUsersComponent } from './modules/versapay/versapay-users/versapay-users.component';
import { AllusersComponent } from './components/pages/dashboard/allusers/allusers.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }
  ,
  {
    path: 'Home',
    data: { breadcrumb: 'Home' },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'Dashboard',
        data: { breadcrumb: 'Dashboard' }
        ,
        children: [
          {
            path: '',
            component: DashboardComponent,
          },
          {
            path: 'ServiceRequest',
            data: { breadcrumb: 'Service Request' },
            children: [
              {
                path: '',
                component: ServiceRequestComponent,
              },
              {
                path: 'NewServiceRequest',
                component: NewServiceRequestComponent,
                data: { breadcrumb: 'New' }
              },
              {
                path: 'Edit/:id',
                component: NewServiceRequestComponent,
                data: { breadcrumb: 'Edit' }
              }
            ]
          },
          {
            path: 'Invoices',
            data: {breadcrumb: 'Invoices'},
            children:[
              {
                path: '',
                component: HomeOpenInvoicesComponent
              }
            ]
          },
          {
            path: 'OutStandingInvoices',
            data: {breadcrumb: 'OutStandingInvoices'},
            children:[
              {
                path: '',
                component: OutstandingInvoicesComponent
              }
            ]
          },
          {
            path: 'News',
            data: { breadcrumb: 'News', roles: "admin" },
            children: [
              {
                path: '',
                component: AdminNewsComponent,
                canActivate: [UserAuthGuard]
              }
              ,
              {
                path: 'CreateNews',
                component: AdminNewsArticleComponent,
                data: { breadcrumb: 'Add News Article', roles: "admin" },
                canActivate: [UserAuthGuard]
              }
              ,
              {
                path: 'Edit/:id',
                component: AdminNewsArticleComponent,
                data: { breadcrumb: 'Edit', roles: "admin" },
                canActivate: [UserAuthGuard],
              }
              ,
              {
                path: 'PreviewNews/:id',
                component: AdminNewsPreviewComponent,
                data: { breadcrumb: 'News Preview' }
              }
            ]
          },
          {
            path: 'NewsArchive',
            data: { breadcrumb: 'News' },
            children: [
              {
                path: '',
                component: NewsArchiveComponent,
              },
              {
                path: 'PreviewNews/:id',
                component: AdminNewsPreviewComponent,
                data: { breadcrumb: 'News Article' }
              }
            ]
          },
          {
            path: 'Users',
            component: AllusersComponent,
            data: { breadcrumb: 'Users' }
          },
          {
            path: 'VersapayUsers',
            component: VersapayUsersComponent,
            data: { breadcrumb: 'VersapayUsers' }
          },
          {
            path: 'allusers',
            component: AllusersComponent,
            data: { breadcrumb: 'All Users', roles: "admin" },
            canActivate: [UserAuthGuard]
          },
          {
            path: 'MyProfile',
            component: MyProfileComponent,
            data: { breadcrumb: 'My Profile' }
          },
          {
            path: 'ChangePassword',
            component: ChangePasswordComponent,
            data: { breadcrumb: 'Change Password' }
          }
        ]
      }
    ]
  }
  ,
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
