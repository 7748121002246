<div class="sr-stats">
  <div class="row justify-content-md-center">
    <div class="col col-sm-4 col-md-3 sr-stats-box sr-grey">
      <div class="sr-stats-amount-invoice"> <span *ngIf="companyData">${{allSummaryInvoiceRequests.aging_30 | sumval:allSummaryInvoiceRequests.current:"0":"0" | cvdollarsummary}} </span><span *ngIf="!companyData">$0.00</span></div>
      <div class="sr-stats-text-invoice"><span>{{'over30days' | transloco}}</span> </div>
    </div>
    <div class="col col-sm-4 col-md-3 sr-stats-box sr-black">
      <div class="sr-stats-amount-invoice"><span *ngIf="companyData">${{allSummaryInvoiceRequests.aging_60 | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></div>
      <div class="sr-stats-text-invoice"><span>{{'over60days' | transloco}}</span></div>
    </div>
    <div class="col col-sm-4 col-md-3 sr-stats-box sr-red">
      <div class="sr-stats-amount-invoice"><span *ngIf="companyData">${{(allSummaryInvoiceRequests.aging_90 | sumval:allSummaryInvoiceRequests.aging_120:allSummaryInvoiceRequests.aging_180:allSummaryInvoiceRequests.aging_older) | cvdollarsummary}}</span><span *ngIf="!companyData">$0.00</span></div>
      <div class="sr-stats-text-invoice"><span>{{'over90days' | transloco}}</span></div>
    </div>
  </div>
</div>
