import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs!: Breadcrumb[];
  public deliminator: string = " / ";

  constructor(private breadcrumbService: BreadcrumbService,
    private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbService.breadcrumbChanged.subscribe(
      (crumbs: Breadcrumb[]) => {
        this.onBreadcrumbChange(crumbs);
      });
  }

  ngOnInit(): void {
  }
  
  private onBreadcrumbChange(crumbs: Breadcrumb[]) {    
    this.breadcrumbs = crumbs;
  }

}
