import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { User } from "../models/user";
import { ConstantsService } from "./constants.service";
import { ManageStorageService } from "./manage-storage.service";
import { UsersService } from "./users.service";

@Injectable({ providedIn: 'root' })
export class UserAuthGuard implements CanActivate {
    constructor(
        public authService: MsalService,
        private router: Router,
        private usersService: UsersService,
        private localStorage: ManageStorageService,
        private constants: ConstantsService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const currentUser = this.usersService.loggedInUser;

        if (!currentUser.role) {
            if (this.localStorage.getStorage(this.constants.userEmail)) {
                let email = this.localStorage.getStorage(this.constants.userEmail);

                if (email) {
                    return new Observable<boolean>((observer) => {
                        this.usersService.getLoggedInUser(email)
                            .subscribe((data: User) => {
                                this.usersService.loggedInUser = data;
                                let userRoles:any = data.roles;
                                let admin = userRoles[0].roleName;

                                if (route.data.roles && route.data.roles.indexOf(admin) === -1) {
                                    // role not authorised so redirect to home page
                                    // if(this.usersService.loggedInUser.role == this.constants.tenantUser)
                                    // {
                                    //     this.router.navigate([this.constants.tenantnewsUrl])
                                    //     observer.next(false);
                                    //     observer.complete();
                                    //     return false;
                                    // }
                                    // this.router.navigate(['/']);
                                    // observer.next(false);
                                    // observer.complete();
                                    // return false;

                                    this.router.navigate([this.constants.tenantnewsUrl])
                                    observer.next(false);
                                    observer.complete();
                                    return false;
                                }
                                // authorised so return true
                                observer.next(true);
                                observer.complete();
                                return true;
                            });
                    });

                }
                else {
                    //if no email in local storage then not loggedin
                    this.authService.loginRedirect();
                    return false;
                }

            }
        }
        else {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page
        //this.authService.loginRedirect();
        return false;
    }
}
