import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Building } from 'src/app/models/building';
import { Company } from 'src/app/models/company';
import { PropertySuite } from 'src/app/models/propertySuite';
import { User } from 'src/app/models/user';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  isImpersonated: boolean = false;
  //properties !: any;
  //isAdmin: boolean = false;
  propertySuite: PropertySuite[] = [];
  phoneNumber = "^((\\+91-?)|0)?[0-9]{10}$";

  VersapayView: boolean = false;
  TenantView:boolean = false;
  AdminView:boolean = false;
  userRole:any;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;
  userCompanies:any="";

  //logged in user
  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }

  // buildings
  get properties(): any {
    return this.buildingEngineService.properties;
  }
  set properties(properties: any) {
    this.buildingEngineService.properties = properties;
  }

  //Check is Admin
  isAdmin: boolean = false;

  UserProfileForm: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required])
    //phone: new FormControl('', [Validators.required, Validators.pattern(this.phoneNumber)])
  })

  constructor(private constants: ConstantsService,
    private usersService: UsersService,
    private localStorage: ManageStorageService,
    private buildingEngineService: BuildingEngineService,
    private newsService: NewsService,
    private route: Router) {
  }

  ngOnInit(): void {
    let userRoles:any = this.usersService.loggedInUser.roles;
    this.isImpersonated = !!this.localStorage.getStorage(this.constants.isImpersonate);

    if(userRoles){
      if(userRoles.length>0){
        this.loggedInUser = this.usersService.loggedInUser;
        this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
        this.FillUserProfile(this.usersService.loggedInUser);
        this.getCompanies(this.usersService.loggedInUser.email);
        this.usersService.changeUserDetails(this.usersService.loggedInUser);
        this.rework(this.usersService.loggedInUser);
      }else{
        if (this.localStorage.getStorage(this.constants.userEmail)) {
          let email = this.localStorage.getStorage(this.constants.userEmail);
          this.getLoggedInUser(email);
        }
      }
    }else{
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.getLoggedInUser(email);
      }
    }
  }
  FillUserProfile(user: User) {
    if (user) {
      this.UserProfileForm.patchValue({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone?.replace(/\s/g, '')
      });
    }
    //if the user is first loggedin user then need to update the field
    if (user.isFirstLogin) {
      this.updateUserProfileForFirstLogin(user)
    }
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.usersService.loggedInUser = data;
        this.loggedInUser = data;
        this.userRole = data.roles;
        this.isAdmin = this.usersService.isAdmin(data);
        this.FillUserProfile(this.loggedInUser);
        this.getCompanies(email);
        this.usersService.changeUserDetails(data);
        this.rework(data);
      });

  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
    if(this.hasPrismAccess || this.isAdmin){
      this.getBuildings(data.email);
    }
  }

  getCompanies(email:any){
    this.usersService.getUserCompaniesByEmail(email)
    .subscribe((data)=>{
      this.userCompanies = data;
    })
  }

  getBuildings(email: string) {
    //get properties for admin from Database
    this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
    if (this.isAdmin) {
      this.newsService.getAllBuildingsByUser(email)
        .subscribe((data) => {
          this.properties = data;
        });
    }
    else {
        this.buildingEngineService.getBuildingByUser()
          .subscribe((data) => {
            const result = Object.entries(data);
            var properties = result[3][1]
            this.propertySuite.length = 0;
            for (var a in properties) {
              for (var i in properties[a].spaces) {
                for (var j in properties[a].spaces[i].suites) {
                  this.propertySuite.push({
                    buildingName: properties[a].name,
                    floorId: properties[a].spaces[i].id,
                    floorName: properties[a].spaces[i].name,
                    suiteId: properties[a].spaces[i].suites[j].id,
                    suiteName: properties[a].spaces[i].suites[j].name,
                    company: properties[a].spaces[i].suites[j].tenant.name
                  })
                }
              }
            }
          });
      //}
    }
  }

  updateUserProfileForFirstLogin(user: User) {
    if (user.isFirstLogin) {
      user.isFirstLogin = false;
    }

    this.usersService.putUserProfile(user).subscribe(res => {
      if (res) {
        //after submitting user data need to assign the new values to loggedin user
        this.usersService.loggedInUser.isFirstLogin = false;
      }
    }
    )
  }


  updateUserProfile(user: User) {
    user.id = this.usersService.loggedInUser.id;
    user.role = this.usersService.loggedInUser.role;
    user.email = this.usersService.loggedInUser.email;
    user.prismUserId = this.usersService.loggedInUser.prismUserId;
    user.isActive = this.usersService.loggedInUser.isActive;

    if (this.usersService.loggedInUser.isFirstLogin) {
      user.isFirstLogin = false;
    }

    this.usersService.putUserProfile(user).subscribe(res => {
      if (res) {

        if(this.localStorage.getStorage('userLang')=='fr'){
          this.statusMessage = "Utilisateur mis à jour avec succès!!";
        }else{
          this.statusMessage = "User Updated Successfully!!";
        }
        this.isError = false;
        this.showMessage = true;
        this.FadeOutMessage();
        //after submitting user data need to assign the new values to loggedin user
        this.getLoggedInUser(user.email);
      }
    },
      error => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    )
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 3000);
  }

  CloseMessage() {
    this.showMessage = false;
  }

  onSubmit() {
    if (this.UserProfileForm.valid) {
      this.updateUserProfile(this.UserProfileForm.value);
    }
  }

  CancelButtonClick() {
    this.route.navigate([this.constants.dashboardUrl]);
  }

  navigateToChangePassword() {
    window.location.href = environment.passwordResetUrl;
  }

}
