<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">Change Password</h1>
    </div>
    <!-- Title -->
  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-4">

      <!-- Side Nav -->
      <app-side-nav></app-side-nav>
      <!-- Side Nav -->

    </div>
    <!-- Left Column -->



    <!-- Right Column -->
    <div class="col-md-8 mt-3 mt-md-0">
      <mat-card class="rounded-0 pl-4 pr-4 shadow-sm">

        <div class="row">
          <!-- Current Password -->
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Current Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- Current Password -->
        </div>

        <div class="row">

          <!-- New Password -->
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>New Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- New Password -->

          <!-- Confirm Password -->
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Confirm Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- Confirm Password -->

          <!-- Submit/Cancel buttons -->
          <div class="col-md-12 mt-5">
            <button mat-raised-button class="cancel-button shadow-none">cancel</button>
            <button mat-raised-button class="submit-button shadow-none">update</button>
          </div>
          <!-- Submit/Cancel buttons -->

        </div>

      </mat-card>
    </div>
    <!-- Right Column -->

  </div>



</div>
