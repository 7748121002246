<div class="container">

    <!-- Breadcrumb -->
    <app-breadcrumb></app-breadcrumb>
    <!-- Breadcrumb -->
  
    <!-- Users titles -->
    <div class="sr-title-section mt-3">
      <!-- Title -->
      <div class="sr-title-wrapper">
        <h1 class="mat-headline mat-display-1 mb-0">{{'users' | transloco}}</h1>
      </div>
      <!-- Title -->
  
    </div>
    <!-- Users titles -->
  
    <div class="row mt-5">
  
      <!-- Left Column -->
      <div class="col-md-4 col-lg-3">
  
        <!-- Side Navigation -->
        <app-side-nav></app-side-nav>
        <!-- Side Navigation -->
  
      </div>
      <!-- Left Column -->
  
  
      <!-- Right Column -->
      <div class="col-md-8 col-lg-9 mt-3 mt-md-0">
        
        <!-- Alerts -->
        <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
          {{statusMessage}}
          <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- Alerts -->
  
        <div class="service-request-content shadow-sm">
  
          <!-- Button row -->
          <div class="mb-3 filter-wrapper">
            <form class="service-search">
              <input matInput #filter (keyup)="applyFilter(filter.value)" placeholder="{{'search' | transloco}} " class="search-filter">
              
            </form>
          </div>
          <!-- Button row -->
  
  
          <!-- Table -->
          <div class="table-wrapper table-scroll-600">
            <table mat-table [dataSource]="dataSource" matSort class="">
  
              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'email' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">
                  <button mat-button class="table-link" (click)="previewNews(element.id)">{{element.email}} </button>
                </td>
              </ng-container>
  
              <!-- User Type Column -->
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'type' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove"> {{element.role}} </td>
              </ng-container>
  
              <!-- building Column -->
              <!-- 
              <ng-container matColumnDef="building">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> Building </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.buildingName}} </td>
              </ng-container> -->
  
              <!-- region Column -->
              <!-- <ng-container matColumnDef="regionName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'region' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.regionName}}</td>
              </ng-container> -->
  
              <!-- Company Column -->
               <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="sr-bottom-border-remove"> {{'company' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove">{{element.companyName}}</td>
              </ng-container>
  
              <!-- Action Column -->
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove"> </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pr-0 text-center">
                  <button mat-raised-button class="table-red-btn mr-2" (click)="clickImpersonateUser(element.email)">
                    {{'impersonate' | transloco}}
                  </button>
                  <!-- <button mat-raised-button class="table-red-btn mr-2">
                    <mat-icon class="material-icons-outlined" (click)="deleteUser(element.user.id)"> delete </mat-icon>
                  </button>
                  <button mat-raised-button class="table-red-btn" (click)="editNews(element.user.id)">
                    <mat-icon class="material-icons-outlined"> mode_edit </mat-icon>
                  </button> -->
                </td>
              </ng-container>
  
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <!-- Table -->
  
        </div>
  
        <!-- Paginator -->
        <mat-paginator aria-label="" [pageSizeOptions]="[10]" class="mt-3"></mat-paginator>
        <!-- Paginator -->
  
      </div>
      <!-- Right Column -->
  
  
    </div>
  
  </div>