<div class="row ml-1 mr-1">
    <h2 mat-dialog-title>{{'selectunit' | transloco}}</h2>
</div>

<mat-dialog-content class="mat-typography">
  <mat-radio-group aria-label="Select an option">

  <div class="row mt-2 p-3 ml-1 mr-1 select-property-wrapper" *ngFor="let suite of propertySuite">
    <div class="col-md-1">
        <mat-radio-button #event [value]=suite.suiteId [checked]="suite.suiteId == seletedItem" (change)="radioChange(event.value)"></mat-radio-button>
    </div>    
    <div class="col-md-8">
      <h3 class="property-location">{{defaultBuildingName}}</h3>
      <p class="property-name">{{suite.company}}</p>
    </div>
    <div class="col-md-3">
      <p class="property-floor">{{suite.floorName}}</p>
      <p class="property-suite">{{suite.suiteName}}</p>
    </div>
  </div>
</mat-radio-group>

</mat-dialog-content>
<mat-dialog-actions align="start" class="mt-3 mb-3 ml-1 mr-1">
  <button mat-raised-button class="cancel-button shadow-none" mat-dialog-close>{{'cancel' | transloco}}</button>
  <button mat-raised-button class="submit-button shadow-none" [mat-dialog-close]="true" (click)="save()">{{'select' | transloco}}</button>
</mat-dialog-actions>
