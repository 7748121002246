<app-building-select></app-building-select>

<!-- Title -->
<div class="container mt-3">
  <div class="sr-title-wrapper">
    <h1 class="h1">{{'servicerequest' | transloco}} </h1>
  </div>
</div>
<!-- Title -->

<!-- Spacer -->
<div class="sr-spacer"></div>
<!-- Spacer -->

<div class="service-request-section shadow-sm">
  <div class="container">

         <!-- Stat grid -->
          <div class="sr-stats">
            <div class="row justify-content-md-center">
              <div class="col col-sm-4 col-md-3 sr-stats-box sr-red">
                <div class="sr-stats-amount"> <span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.newCount || 0) < 10">0</span>{{serviceRequestsStatus.newCount}}</div>
                <div class="sr-stats-text">{{'newrequests' | transloco}}<span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.newCount || 0) > 1"></span> </div>
              </div>
              <div class="col col-sm-4 col-md-3 sr-stats-box sr-grey">
                <div class="sr-stats-amount"><span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.inProgressCount || 0) < 10">0</span>{{serviceRequestsStatus?.inProgressCount}}</div>
                <div class="sr-stats-text">{{'inprogress' | transloco}}</div>
              </div>
              <div class="col col-sm-4 col-md-3 sr-stats-box sr-black">
                <div class="sr-stats-amount"><span *ngIf="serviceRequestsStatus && (serviceRequestsStatus.completedCount || 0) < 10">0</span>{{serviceRequestsStatus?.completedCount}}</div>
                <div class="sr-stats-text">{{'completed' | transloco}} </div>
              </div>
            </div>
          </div>
          <!-- Stat grid -->

          <div class="lift-content">

          <!-- Button row -->
          <div class="mb-3 mt-3 mt-lg-0">
            <button mat-button (click)="exporter.exportTable('xlsx')" class="overirde-export-button">
              <mat-icon>exit_to_app</mat-icon>
              <span class="sr-export-button pl-2">{{'exporttoexcel' | transloco}}</span>
            </button>
          </div>
          <!-- Button row -->


          <!-- Table -->
          <div class="table-wrapper table-scroll-800">
            <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" class="">

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 400px;"> {{'description' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                  <a class="table-link" matTooltip={{element.description}} (click)="editServiceRequest(element.id)">{{(element.description.length>50)? (element.description | slice:0:50)+'..':(element.description)}}</a>
                  <!-- <button mat-button class="table-link" (click)="editServiceRequest(element.id)">{{element.description}} </button> -->
                </td>
              </ng-container>

              <!-- RequestId Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 150px;"> {{'requestid' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2"> {{element.identifier}} </td>
              </ng-container>


              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 110px;"> {{'status' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2">
                  <span class="status-label" [ngClass]="(element.status=='completed')?'complete-button':'inprogress-button'">{{element.status}}</span>
                  <!-- <button mat-flat-button [ngClass]="(element.status=='0')?'inprogress-button':(element.status=='1')?'inprogress-button':'complete-button'">{{element.status | status}}</button>  -->
                 </td>
              </ng-container>

              <!-- DaysOld Column -->
               <ng-container matColumnDef="daysOld">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 100px; text-align: center;"> {{'daysold' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2 text-center"> {{element.daysOld}} </td>
              </ng-container>

              <!-- Location Column -->
             <!-- <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2 text-center" style="width: 100px;"> Building </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2 text-center">

                    <mat-icon
                      matTooltip={{defaultBuilding}}
                      aria-label="Button that displays a tooltip when focused or hovered over"
                      class="building-gray-icon"
                    >
                      business
                    </mat-icon>
                >
                </td>
              </ng-container>-->

              <!-- Suite Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="sr-bottom-border-remove pl-2 pr-2" style="width: 100px;"> {{'suite' | transloco}} </th>
                <td mat-cell *matCellDef="let element" class="sr-bottom-border-remove pl-2 pr-2"> {{element.space.name}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="sr-table-header"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <!-- Table -->

          <!-- Add new and view all button -->
          <div class="sr-button-row mt-5">
            <button mat-button class="add-new-button" (click)="navigateToNewServiceRequest()">{{'addnewrequest' | transloco}}</button>
            <button mat-button class="view-all-link" (click)="navigateToServiceRequest()">{{'viewmore' | transloco}}</button>
          </div>
          <!-- Add new and view all button -->

        </div>

  </div>
</div>
