import { Building } from "./building";
import { Floor } from "./floor";
import { IssueType } from "./issueType";
import { Suite } from "./suite";

export class ServiceRequest {
  id:number | undefined;
  userId: number | undefined;
  requestId: string | undefined;
  daysOld: string | undefined;  
  description: string | undefined;
  status: string | undefined;
  
  building: Building | undefined;
  companyName: string | undefined;
  floors: string| undefined;
  suite: string | undefined;
  phone: string | undefined;  
  issueType: IssueType[]| undefined;
  keepMeInformed: string | undefined;
  comments?: any[];
  attachments?: any[];
}