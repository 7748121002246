import { BuildingDialogComponent } from './../building-dialog/building-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { UsersService } from 'src/app/services/users.service';
import { Building } from 'src/app/models/building';
import { BuildingEngineService } from 'src/app/services/building-engine.service';

@Component({
  selector: 'app-building-select',
  templateUrl: './building-select.component.html',
  styleUrls: ['./building-select.component.scss']
})
export class BuildingSelectComponent implements OnInit {

  selected!: Building[];
  selectedBuilding !: any;

  get buildings(): any {
    return this.buildingEngineService.properties;
  }
  set buildings(properties: any) {
    this.buildingEngineService.properties = properties;
  }

  get defaultBuilding(): Building {
    return this.buildingEngineService.defaultBuilding;
  }
  set defaultBuilding(building: Building) {
    this.buildingEngineService.defaultBuilding = building;
  }

  constructor(public dialog: MatDialog,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,
    private buildingEngineService: BuildingEngineService
  ) {
    this.buildingEngineService.building.subscribe((data: Building) => {
      this.selectedBuilding = data.name;
    });
  }

  // this method is called in drop down click
  getBuildings() {
    let buildingCount = this.localStorage.getStorage(this.constants.buildingCnt);

    //if there are more than 1 building give a popup
    if (buildingCount != "1") {

      //get users properties
      if (this.buildings?.length>0) {
        this.buildings = this.buildings;
        this.openDialog();
      }
      else {
        this.buildingEngineService.getBuildingByUser()
          .subscribe((data) => {
            const result = Object.entries(data);
            this.buildings = result[3][1];
            this.openDialog();
          });
      }
    }

  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      buildings: this.buildings
    };

    const dialogRef = this.dialog.open(BuildingDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //set default building
      this.selectedBuilding = result[0].name;
      this.defaultBuilding = result[0];
      this.localStorage.setStorage(this.constants.defaultBuilding, JSON.stringify(result[0]));
      this.buildingEngineService.changeBuilding(result[0]);
    });
  }

  ngOnInit(): void {
    let building = this.localStorage.getStorage(this.constants.defaultBuilding);
    if (building && building!="undefined") {
      var result = JSON.parse(building || '{}');
      this.selectedBuilding = result.name;
    } else {
      this.getBuildings();
    }
  }
}
