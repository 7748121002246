import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { UserRequest } from 'src/app/models/user-request';
import { BuildingEngineService } from 'src/app/services/building-engine.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';
import { AuthService } from 'src/app/services/rc-auth.service';
import { UsersService } from 'src/app/services/users.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: string = "";
  welcomeText!: string
  welcomeClasses: string[] = []
  selectedBtnColorEn:string = "basic";
  selectedBtnColorFr:string = "basic";
  statusMessage!: string;
  isError: boolean = false;
  showMessage: boolean = false;
  dataSource = new MatTableDataSource;

  users!: UserRequest[];
  usersSubject: Subject<UserRequest[]> = new Subject<UserRequest[]>();
  filteredUsers!: Observable<UserRequest[]>;
  control = new FormControl();

  isImpersonated: boolean = false;
  disableden: boolean = true;
  disabledfr: boolean = true;

  AdminView:boolean = false;
  hasPrismAccess:boolean = false;
  hasVersapayAccess:boolean = false;

  isAdmin: boolean = false;
  get loggedInUser(): User {
    return this.usersService.loggedInUser;
  }
  set loggedInUser(user: User) {
    this.usersService.loggedInUser = user;
  }
  set properties(properties: any) {
    this.buildingEngineService.properties = properties;
  }

  constructor(private route: Router,
    private rcAuthService: AuthService,
    private localStorage: ManageStorageService,
    private constants: ConstantsService,
    private usersService: UsersService,
    private buildingEngineService: BuildingEngineService,
    private langService: TranslocoService
  ) {
    //check for impersonated

    this.usersService.updatedUser.subscribe((data: User) => {
      this.userName = data.firstName ? data.firstName : "";
      this.isAdmin = this.usersService.isAdmin(this.loggedInUser);
      if (this.isAdmin) {
        this.getAllUsersToImpersonate();
      }
    });

    this.usersService.isImpersonated.subscribe((data: boolean) => {
      if (data) {
        this.isImpersonated = true;
        this.welcomeText = this.constants.impersonateAsText;
        this.welcomeClasses.push('impersoante-bg')
      }
      else {
        this.isImpersonated = false;
        this.welcomeText = this.constants.welcomeText;
        this.welcomeClasses = [];
      }
    });

    this.usersService.onImpersonate.subscribe((userEmail: string) => {
      this.control.setValue(userEmail);
      this.clickImpersonate();
    });

  }

  ngOnInit() {
    this.usersSubject.subscribe((data: UserRequest[]) => {
      this.users = data;

      this.filteredUsers = this.control.valueChanges.pipe(
        startWith(''),
        map(value => value ? this._filter(value) : this.users.slice()),
      );
    });

    if (this.localStorage.getStorage(this.constants.isImpersonate)) {
      this.isImpersonated = true;
      this.welcomeText = this.constants.impersonateAsText;
      this.welcomeClasses.push('impersoante-bg')
      this.getImpersonateUser(this.localStorage.getStorage(this.constants.impersonateAs));
      this.getAllUsersToImpersonate();
    }
    else {
      this.isImpersonated = false;
      this.welcomeText = this.constants.welcomeText;
      this.welcomeClasses.splice(this.welcomeClasses.length - 1, 1)
      let email = this.localStorage.getStorage(this.constants.userEmail);
      this.getLoggedInUser(email);
    }
  }



  change(lang: string) {
    this.loggedInUser = this.usersService.loggedInUser;

    this.usersService.putUserDefaultLang(this.loggedInUser,lang).subscribe(
      res=>{
        if(res){
          this.langService.setActiveLang(lang);
          this.langService.setDefaultLang(lang);
          this.localStorage.setStorage("userLang",lang);
        }
      },
      (error) => {
        this.statusMessage = error;
        this.isError = true;
        this.showMessage = true;
        this.FadeOutMessage();
      }
    );

    this.updateLangButton(lang);
  }

  rework(data:any){
    let tenant:boolean = false;
    let versapay:boolean = false;
    let admin:boolean = false;
    //this.dashboardItems = this.loadDashboardItems();
    data.roles.forEach(function(value:any){
      if(value.roleName=='tenant'){
        tenant=true;
      }else if(value.roleName=='versapay'){
        versapay = true;
      }else if (value.roleName=='admin'){
        admin = true;
      }
    });
    this.hasPrismAccess = tenant;
    this.hasVersapayAccess = versapay;
    this.AdminView = admin;
  }

  FadeOutMessage() {
    setTimeout(() => {
      this.showMessage = false;
    }, 5000);
  }
  CloseMessage() {
    this.showMessage = false;
  }

  getLoggedInUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.loggedInUser = data;
        if (!this.localStorage.getStorage(this.constants.isImpersonate)) {
          this.rework(data);
          this.userName = data.firstName ? data.firstName : "";
          this.localStorage.setStorage(this.constants.userOrgId, data.prismCompanyId);
        }
        let lang = this.localStorage.getStorage("userLang") as string;
        this.updateLangButton(lang);
        this.isAdmin = this.usersService.isAdmin(data);
        if (this.isAdmin) {
          this.getAllUsersToImpersonate();
        }
        this.loggedInUser = data;
      });
  }

  getAllUsersToImpersonate() {
    this.usersService.getAllUsersToImpersonate()
      .subscribe((data: any[]) => {
        this.users = data;
        this.usersSubject.next(data);
      },
      (err: any) => {
      });
  }

  getImpersonateUser(email: any): void {
    this.usersService.getLoggedInUser(email)
      .subscribe((data: User) => {
        this.userName = data.firstName ? data.firstName : "";
        this.localStorage.setStorage(this.constants.impersonateUserOrgId, data.prismCompanyId);
        this.rework(data);
        let lang = this.localStorage.getStorage("userLang") as string;
        this.updateLangButton(lang);
        this.loggedInUser = data;
        this.usersService.changeIsImpersonated(true);
      }, () => this.clickImpersonateEnd());
  }

  clickImpersonate() {
    if (this.control.value == null) {
      return;
    }
    this.localStorage.setStorage(this.constants.isImpersonate, true);
    this.localStorage.setStorage(this.constants.impersonateAs, this.control.value);
    this.localStorage.removeStorage(this.constants.defaultCompany);

    this.welcomeText = this.constants.impersonateAsText;
    this.welcomeClasses.push('impersoante-bg')
    this.getImpersonateUser(this.control.value);
    this.route.navigate([this.constants.dashboardUrl]);
    this.control.reset();
  }

  clickImpersonateEnd() {
    this.localStorage.removeStorage(this.constants.isImpersonate);
    this.localStorage.removeStorage(this.constants.impersonateAs);
    this.localStorage.removeStorage(this.constants.defaultCompany);
    this.localStorage.removeStorage(this.constants.defaultBuilding);

    this.welcomeText = this.constants.welcomeText;
    this.welcomeClasses.splice(this.welcomeClasses.length - 1, 1)

    this.properties = null;
    //change color and text
    this.usersService.changeIsImpersonated(false);

    //get admin user
    let email = this.localStorage.getStorage(this.constants.userEmail);
    this.control.reset();
    this.getLoggedInUser(email);
    this.route.navigate([this.constants.usersUrl])
    window.location.reload();
  }

  searchUser($event: any) {
    $event.stopPropagation();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  navigateToHome() {
    this.route.navigate([this.constants.homeUrl])
  }
  navigateToDashboard() {
    this.route.navigate([this.constants.dashboardUrl])
  }
  navigateToNews() {
    if (!this.usersService.loggedInUser.role) {
      if (this.localStorage.getStorage(this.constants.userEmail)) {
        let email = this.localStorage.getStorage(this.constants.userEmail);
        this.usersService.getLoggedInUser(email)
          .subscribe((data: User) => {
            this.usersService.loggedInUser = data;
            if (this.usersService.loggedInUser.role == this.constants.adminUser) {
              this.route.navigate([this.constants.newsUrl])
            }
            else {
              this.route.navigate([this.constants.tenantnewsUrl])
            }
          });
      }
    }
    else {
      if (this.usersService.loggedInUser.role == this.constants.adminUser) {
        this.route.navigate([this.constants.newsUrl])
      }
      else {
        this.route.navigate([this.constants.tenantnewsUrl])
      }
    }
  }
  navigateToMyProfile() {
    this.route.navigate([this.constants.myProfileUrl])
  }
  logOut() {
    this.rcAuthService.logout();
  }

  private updateLangButton(lang: string) {
    if(lang=='fr'){
      this.selectedBtnColorEn = "basic";
      this.selectedBtnColorFr = "primary";
      this.disableden = false;
      this.disabledfr=true;

    }else{
      this.selectedBtnColorEn = "primary";
      this.selectedBtnColorFr = "basic";
      this.disableden = true;
      this.disabledfr=false;
    }
  }

  private _filter(name: string): UserRequest[] {
    const filterValue = name.toLowerCase();
    return this.users.filter(option => option.email?.toLowerCase().includes(filterValue));
  }
}
