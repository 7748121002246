<mat-toolbar class="shadow-sm">
  <mat-toolbar-row class="p-0 container">
    <div class="col-sm-2 col-md-1">
      <span class="appbar-logo-wrapper">
        <a href="#" aria-label="RioCan Logo link">
          <img src="../../../../../assets/images/L_RioCan_Logo_Icon_rgb.svg" alt="logo" class="appbar-logo" />
        </a>
      </span>
    </div>

    <!-- Menu items -->
    <div class="col-md-4 ml-3 ml-lg-0">
      <button mat-button aria-label="Home menu button" color="accent" (click)="navigateToHome()"ng serve
      class="nav-btn">{{'home' | transloco}}</button>
      <button mat-button aria-label="News menu button" color="accent"  (click)="navigateToNews()"
        class="nav-btn">{{'news' | transloco}}</button>
      <button mat-button aria-label="My account menu button" color="accent"  (click)="navigateToDashboard()"
        class="nav-btn">{{'myaccount' | transloco}}
      </button>
      <button mat-button aria-label="Module menu button" color="accent" class="nav-btn module-btn" [matMenuTriggerFor]="module"  *ngIf="!isAdmin || isImpersonated">{{'module' | transloco}}
      </button>
      <mat-menu #module="matMenu" class="module-mat-menu-override" >
        <button mat-menu-item [routerLink]="['/Home/Dashboard/ServiceRequest']" *ngIf="hasPrismAccess">{{'servicerequest' | transloco}}</button>
        <button mat-menu-item [routerLink]="['/Home/Dashboard/Invoices']" *ngIf="hasVersapayAccess">{{'invoices' | transloco}}</button>
      </mat-menu>
    </div>

    <!-- Menu items -->

    <!-- Spacer -->
    <span class="appbar-spacer"></span>
    <!-- Spacer -->



    <!-- Spacer -->
    <span class="appbar-spacer"></span>
    <!-- Spacer -->

    <!-- Switch wrapper -->
    <div class="switch-view-wrapper">

      <!-- Tenant user welcome note -->
      <mat-label *ngIf="!isAdmin && !isImpersonated" class="welcome-user mr-4">
        <span class="switch-note mr-1">{{welcomeText | transloco}} </span>
        <span class="switch-user">{{userName}} !</span>
      </mat-label>
      <!-- Tenant user welcome note -->

      <!-- Admin user welcome note -->
      <button *ngIf="isAdmin || isImpersonated" [ngClass]="welcomeClasses" mat-button [matMenuTriggerFor]="menu" class="text-uppercase switch-menu-btn">

        <span class="switch-note mr-1">{{welcomeText | transloco}} </span>
        <span class="switch-user">{{userName}} !</span>
        <mat-icon class="material-icons ml-2">expand_more</mat-icon>
      </button>
      <!-- Admin user welcome note -->

      <!-- Impersonate section -->
      <button *ngIf="isImpersonated" mat-button [matMenuTriggerFor]="menu"
        class="text-uppercase switch-menu-btn impersoante-bg d-none ">
        <span class="switch-note mr-1">{{'impersonate_as' | transloco}} </span>
        <span class="switch-user">JOHN !</span>
        <mat-icon class="material-icons ml-2" >expand_more</mat-icon>
      </button>

      <mat-menu #menu="matMenu" xPosition="before" class="switch-view">
        <mat-label class="impersonate-text">{{'impersonate_user' | transloco}}</mat-label>
        <form class="example-form">
          <input type="text" #impersonateSearch [formControl]="control" (click)="searchUser($event)" placeholder="{{'search' | transloco}}" class="search-filter" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let user of filteredUsers | async" [value]="user.email">
              {{user.email}}
            </mat-option>
          </mat-autocomplete>
        </form>
        <div class="mt-2">
          <button mat-button class="impersonate-btn mr-2" (click)="clickImpersonate()">{{'impersonate' | transloco}}</button>
          <button mat-button class="end-btn" [disabled]="!isImpersonated" (click)="clickImpersonateEnd()">{{'end' | transloco}}</button>
        </div>
      </mat-menu>
      <!-- Impersonate section -->
    </div>
    <!-- Switch wrapper -->

    <!-- Side hamburger menu -->
    <button mat-icon-button class="hamburger-button" aria-label="Hambueger menu" [matMenuTriggerFor]="beforeMenu"
      color="">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before" class="mat-menu-override">
      <button mat-menu-item (click)="navigateToMyProfile()">{{'myprofile' | transloco}}</button>
      <button mat-menu-item (click)="navigateToDashboard()">{{'myaccount' | transloco}}</button>
      <button mat-menu-item (click)="logOut()">{{'signout' | transloco}}</button>
    </mat-menu>
    <!-- Side hamburger menu -->
    <!-- Contact Us -->
    <mat-label class="welcome-user ml-3">
      <span class="contact-note mr-1 d-block" >{{'contactus' | transloco}}</span>
      <span class="contact-number d-block" >1-888-293-9158</span>
    </mat-label>
    <!-- Contact Us -->
  </mat-toolbar-row>
</mat-toolbar>

<div style="background: #ECECEC;">
  <div class="container">
    <div class="row">
      <div class="col text-right">
        <div class="switch-lang-btn-wrapper">
          <button mat-mini-fab color="{{selectedBtnColorEn}}" [disabled]="disableden" [ngStyle]="{transform: 'scale(0.8)'}"  (click)="change('en')">EN</button>
          <span>|</span>
          <button mat-mini-fab color="{{selectedBtnColorFr}}" [disabled]="disabledfr" [ngStyle]="{transform: 'scale(0.8)'}" (click)="change('fr')">FR</button>
        </div>
      </div>
    </div>

  </div>
</div>


  <!-- Alerts -->
  <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
    {{statusMessage}}
    <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Alerts -->
