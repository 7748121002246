import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { vCompany } from 'src/app/models/company';
import { ConstantsService } from 'src/app/services/constants.service';
import { ManageStorageService } from 'src/app/services/manage-storage.service';

export interface DialogData {
  companies: vCompany[];
}
@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnInit {

  companies !: vCompany[];
  defaultCompanyId !: string;
  chosenItem!:string;
  isCompanySelected : boolean = true;

  constructor(public dialogRef: MatDialogRef<CompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private constants: ConstantsService,
    private localStorage: ManageStorageService,) {   
    this.companies = data.companies;
 
    //get default company    
    let company:any = this.localStorage.getStorage(this.constants.defaultCompany);
    if (company) {
      var result = JSON.parse(company || '{}');           
      this.chosenItem = result.externalID;
      this.isCompanySelected = false;
    }
    
  }

  ngOnInit(): void {
  }

  radioChange(event: string) {    
    this.defaultCompanyId = event;
    this.isCompanySelected = false;
  }

  save() {    
    this.dialogRef.close(this.companies.filter(a => a.externalID == this.defaultCompanyId));
  }

}
