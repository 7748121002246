<div class="container">

  <!-- Breadcrumb -->
  <app-breadcrumb></app-breadcrumb>
  <!-- Breadcrumb -->

  <!-- Service Request titles -->
  <div class="sr-title-section mt-3">
    <!-- Title -->
    <div *ngIf="!isUpdate" class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'addnewsarticle' | transloco}}</h1>
    </div>
    <!-- Title -->

    <!-- Title -->
    <div *ngIf="isUpdate" class="sr-title-wrapper">
      <h1 class="mat-headline mat-display-1 mb-0">{{'editnewsarticle' | transloco}}</h1>
    </div>
    <!-- Title -->
  </div>
  <!-- Service Request titles -->

  <div class="row mt-5">

    <!-- Left Column -->
    <div class="col-md-3">

      <!-- Side Navigation -->
      <app-side-nav></app-side-nav>
      <!-- Side Navigation -->

    </div>
    <!-- Left Column -->


    <!-- Right Column -->

    <div class="col-md-9 mt-3 mt-md-0">

      <!-- Alerts -->
      <div *ngIf="showMessage" class="alert" [ngClass]="isError? 'alert-danger' : 'alert-success'" role="alert">
        {{statusMessage}}
        <button type="button" class="close" data-dismiss="alert" (click)="CloseMessage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Alerts -->

      <form [formGroup]="newsForm">
        <mat-card class="rounded-0 pl-4 pr-4 shadow-sm">

          <div class="row mt-4 mb-5">

            <div class="col-md-12">
              <!-- Title -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'title' | transloco}}</mat-label>
                <input matInput maxlength="50" formControlName="title">
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Title -->
            </div>

            <div class="col-md-6">

              <!-- Start Date -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'startdate' | transloco}}</mat-label>
                <input matInput [matDatepicker]="startpicker" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker></mat-datepicker>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Start Date -->

              <!-- News Type -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'newstype' | transloco}}</mat-label>
                <mat-select formControlName="type" #event (selectionChange)="changeNewsType(event.value)">
                  <mat-option value="National">National</mat-option>
                  <mat-option value="Regional">Regional</mat-option>
                  <mat-option value="Property">Property</mat-option>
                </mat-select>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- News Type -->

            </div>
            <div class="col-md-6">

              <!-- End Date -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'enddate' | transloco}}</mat-label>
                <input matInput [matDatepicker]="endpicker" [min]="newsForm.controls.startDate.value"
                  formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                <mat-datepicker #endpicker></mat-datepicker>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- End Date -->

              <!-- Region -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'region' | transloco}}</mat-label>
                <mat-select #matRegion [disabled]="isNational || isProperty" formControlName="region"
                  [(value)]="currentNews.regionId" [compareWith]="compareObjects" [multiple]="true">
                  <mat-option [value]="region.id" *ngFor="let region of regionsList">{{ region.name }}</mat-option>
                </mat-select>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Region -->
            </div>

            <div class="col-md-12">
              <!-- Property -->
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'property' | transloco}}</mat-label>
                <mat-select #matProperty [disabled]="isNational || isRegional" formControlName="property"
                  [(value)]="currentNews.buildingId" [compareWith]="compareObjects" [multiple]="true">
                  <mat-option [value]="building.id" *ngFor="let building of buildingList">{{ building.name }}
                  </mat-option>
                </mat-select>
                <mat-error class="form-error">{{'this_field_is_required' | transloco}}</mat-error>
              </mat-form-field>
              <!-- Property -->
            </div>

            <div class="col-md-12">
              <!-- Featured -->
              <section class="example-section">
                <mat-checkbox class="example-margin" formControlName="featured">{{'featured' | transloco}}</mat-checkbox>
              </section>
              <!-- Featured -->
            </div>

            <!--<div>
              <textarea class="mt-2 text-editor" matInput formControlName="summery"></textarea>
            </div>-->

            <!-- seperator -->
            <div class="col-md-12">
              <div class="mt-4 mb-4 seperator"></div>
            </div>
            <!-- seperator -->

            <!-- Text Editor -->
            <div class="col-md-12">
              <p class="mb-0">{{'details' | transloco}}*</p>
              <mat-error class="form-error" style="font-size: 75%;"
                *ngIf="newsForm.get('details')?.hasError('required') && newsForm.get('details')?.touched">{{'this_field_is_required' | transloco}}</mat-error>
              <div class="mt-2 text-editor">
                <quill-editor [styles]="{height: '200px'}" [modules]="editorConfig" formControlName="details"
                  [minLength]="1"></quill-editor>
              </div>
            </div>
            <!-- Text Editor -->

            <!-- Upload button -->
            <div class="col-md-12 mt-3">

              <!-- Upload image link -->
              <div class="upload-image-wrapper mb-3">
                <button mat-raised-button color="accent" class="upload-button" (click)="fileUpload.click()">{{uploadImageBtnText | transloco}}
                
                </button>
                <span class="upload-img-link col-md-4">{{imageName}}</span>

                <input formControlName="image" type="file" #fileUpload (change)="onFileSelected($event)"
                  (click)="OnImageClick()" style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                <mat-error class="form-error" style="font-size: 75%;"
                  *ngIf=" !isUpdate && newsForm.get('image')?.hasError('required') && newsForm.get('image')?.touched">
                  {{'this_field_is_required' | transloco}}</mat-error>

              </div>
              <!-- Upload image link -->

              <!-- Image Preview -->
              <mat-card class="mt-3 rounded-0 shadow-none">
                <div *ngIf="isUpdate && !selectedImage">
                  <img mat-card-image *ngIf="imageURL" [src]="imageURL" alt="Photo of a Shiba Inu">
                </div>
              </mat-card>
              <!-- Image Preview -->

            </div>
            <!-- Upload button -->

            <!-- Youtube Url -->
            <div class="col-md-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{'youtubeurl' | transloco}}</mat-label>                                
                <input matInput formControlName="videoLink"/>
                <mat-error class="form-error">{{'wrongurl' | transloco}}</mat-error>
              </mat-form-field>
            </div>
            <!-- Youtube Url -->

            <!-- Video Preview -->
            <mat-card *ngIf="isUpdate && videoUrl" class="mt-3 rounded-0 shadow-none">
              <div>
                 <iframe width="100%" height="400" [src]='videoUrl | videoSafe' frameborder="0" allowfullscreen></iframe>
                <!--<object type="text/html" [data]="videoUrl" ngDraggable></object>-->
              </div>
            </mat-card>
            <!-- Video Preview -->

           

            <!-- Submit/Cancel buttons -->
            <div class="col-md-12 mt-5">
              <button mat-raised-button class="cancel-button shadow-none" (click)="navigateToNews()">{{'cancel' | transloco}}</button>
              <button mat-raised-button class="submit-button shadow-none {{savedraft_btn}}" (click)="onSubmit()"
                style="margin-right: 20px;" [disabled]="newsForm.invalid || isSaved">{{'saveasdraft' | transloco}}</button>
              <button mat-raised-button class="submit-button shadow-none" [disabled]="!isSaved"
                (click)="navigateToNewsArticlePreview()">{{'preview' | transloco}}</button>
            </div>
            <!-- Submit/Cancel buttons -->


          </div>
        </mat-card>
      </form>
    </div>

    <!-- Right Column -->


  </div>

</div>